import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { tokdecn, useAppContext } from "../App.js";
import { useQueries, useQuery } from "react-query";
import {
  qissuccesss,
  q_recentraces,
  polytxnidlink,
  q_recentpays,
} from "../queries/queries.js";
import { dec, getv, jstr, nils, to_time_mini } from "../utils/utils.js";
import { Card, Img, Tag, TokenIcon } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faMapMarkedAlt,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { Loader01c } from "../components/anims.js";
import { cb_txt, class_text, pos_cn, tablecn } from "../utils/cn_map.js";
import { postxt } from "../utils/raceutils.js";
import _ from "lodash";
import { Link } from "react-router-dom";
import { polychainimg } from "../utils/links";
import {
  faDiscord,
  faTwitch,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import moment from "moment";

export const HomeBannerVideo = () => {
  const ref = useRef(null);

  const handleVideo = async () => {
    if (nils(ref.current)) return;
    let muted = ref.current.muted == true;
    if (muted) ref.current.muted = false;
    else ref.current.muted = true;
  };
  return (
    <>
      <video
        ref={ref}
        autoPlay
        loop
        muted
        onClick={() => handleVideo()}
        // controls={true}
        className="object-cover h-full w-full"
        src="https://cdn.dnaracing.run/videos/dnaracing-trailer.mp4"
      />
    </>
  );
};

const Player3D = () => {
  const ref = useRef(null);
  const rid = "1acba58a";

  const handleVideo = async () => {
    if (nils(ref.current)) return;
    let muted = ref.current.muted == true;
    if (muted) ref.current.muted = false;
    else ref.current.muted = true;
  };

  return (
    <>
      <div className="xs:h-[6rem] md:h-[4rem] lg:h-[0rem] bg-transparent"></div>
      <div className="xs:h-[20vh] md:h-[30vh] lg:h-[40vh] bg-black">
        <HomeBannerVideo />
      </div>
    </>
  );
};

const RecentRacesCarousel = ({ rr }) => {
  const { races = [], hmap = {}, vmap = {}, txns = {} } = rr;
  // console.log("rr", races, hmap);

  const [index, setIndex] = useState(0);
  const buttoncn =
    "fc-cc xs:w-[2rem] xs:h-[2rem] lg:w-[4rem] lg:h-[4rem] xs:rounded-full lg:rounded-full bg-slate-800 hover:bg-acc4/50 xs:text-[1rem] lg:text-[2rem]";

  const prev = () => {
    setIndex(Math.max(0, index - 1));
  };

  const next = () => {
    setIndex(Math.min(races.length - 1, index + 1));
  };

  useEffect(() => {
    const n = races.length;
    let timeout = setTimeout(() => {
      setIndex((index + 1) % n);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [jstr(races)]);

  return (
    <div className="w-full my-4">
      ̉{/* <p className="text-acc4 resp-text-2 font-digi">Recent Races </p> */}
      <div className="fr-cc">
        <Tag onClick={prev} className={twMerge(buttoncn, index == 0 ? "" : "")}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Tag>
        {nils(races[index]) ? (
          <Loader01c />
        ) : (
          <>
            <RecentCard race={races[index]} {...{ hmap, vmap, txns }} />
          </>
        )}
        <Tag
          onClick={next}
          className={twMerge(buttoncn, index == races.length - 1 ? "" : "")}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Tag>
      </div>
    </div>
  );
};

const PodiumView = ({ h, pos }) => {
  const pos_h = useMemo(() => {
    if (pos == 1) return "h-[10rem]";
    if (pos == 2) return "h-[8rem]";
    if (pos == 3) return "h-[6rem]";
    return "h-[4rem]";
  }, [pos]);

  if (nils(h)) {
    return (
      <div className="w-full fc-cc">
        <div className="fc-cc w-full font-digi resp-text--2"></div>
        <div
          className={`w-full ${pos_h} bg-gradient-to-t from-transparent to-red-500/20  text-red-500/20 fc-cc font-digi resp-text-2`}
        >
          <p>{postxt(pos)}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full fc-cc">
      <div className="fc-cc w-full font-digi resp-text--2">
        {/* <p className="text-green-300">
          <FontAwesomeIcon icon={faUsd} />
          <span className="mx-1">{dec(h.prize_usd, 2)}</span>
        </p> */}
        <p className="text-green-300">{dec(h.time, 2)}sec</p>

        <p
          className={twMerge(
            "font-digi",
            h.name.length > 12 ? "resp-text--1" : "resp-text-1",
          )}
        >
          {h.name}
        </p>
      </div>
      <div
        className={`w-full ${pos_h} bg-gradient-to-t from-transparent to-pos${pos}  text-pos${pos} fc-cc font-digi resp-text-2`}
      >
        <p>{postxt(h.pos)}</p>
      </div>
    </div>
  );
};

const RecentCard = ({ race: r, vmap, hmap, txns }) => {
  const [h1, h2, h3] = useMemo(() => {
    let h1 = _.find(r.hs, { pos: 1 });
    let h2 = _.find(r.hs, { pos: 2 });
    let h3 = _.find(r.hs, { pos: 3 });

    if (h1) {
      h1 = { ...h1, ...(hmap[h1.hid] || {}) };
    }
    if (h2) {
      h2 = { ...h2, ...(hmap[h2.hid] || {}) };
    }
    if (h3) {
      h3 = { ...h3, ...(hmap[h3.hid] || {}) };
    }

    // console.log(h1, h2, h3);
    return [h1, h2, h3];
  }, [jstr(r)]);

  const prizes = useMemo(() => {
    let ps =
      _.chain(r.hs)
        .filter((h) => !(nils(h.prize_eth) || h.prize_eth == 0))
        .sortBy((h) => -parseFloat(h.prize_eth))
        .value() || [];
    return ps;
  }, [jstr(r)]);
  return (
    <Card
      className={twMerge(
        "xs:w-[90vw] lg:w-[60rem] max-w-[95vw] mx-[0]  shadow-lg shadow-reg p-[4rem]",
        "bg-r2lig/20 backdrop-blur-md",
      )}
    >
      <div className="fr-sc my-1">
        <p className="font-digi text-white italic resp-text-1">{r.race_name}</p>
      </div>
      <div className="fr-sc my-1 font-digi resp-gap-2">
        <p className={twMerge(`text-c${r.class}`)}>
          {class_text(r.class, "T")}
        </p>
        <p>{cb_txt(r.cb)}</p>
        <div className="flex-1"></div>
        {!nils(txns[r.rid]) && (
          <Link className="" target="_blank" to={polytxnidlink(txns[r.rid])}>
            <div className="w-[3rem]">
              <Img img={polychainimg} />
            </div>
          </Link>
        )}
      </div>
      <div className="grid grid-cols-12">
        <div className="xs:col-span-12 lg:col-span-7">
          <div className="grid grid-cols-3 gap-2 w-full justify-center items-end">
            <PodiumView h={h3} pos={3} />
            <PodiumView h={h1} pos={1} />
            <PodiumView h={h2} pos={2} />
          </div>
        </div>
        <div className="xs:col-span-12 lg:col-span-5">
          <Card className={"w-full bg-transparent"}>
            <table className={twMerge(tablecn.table, "w-full")}>
              <tbody>
                {prizes.map((h) => {
                  let { hid } = h;
                  h = { ...h, ...(hmap[hid] || {}) };
                  let v = h.racing_vault;
                  let vauname = vmap[v];
                  return (
                    <tr className={twMerge("thintdrowp4")}>
                      <td>
                        <p className={`text-pos${h.pos}`}>{postxt(h.pos)}</p>
                      </td>
                      <td>
                        <div className="fr-sc my-1">
                          <p className="font-digi">{h.name}</p>
                        </div>

                        <div className="fr-sc my-1">
                          <p className="text-right w-full">
                            <span className="text-acc4">{vauname}</span>
                            <span>{" got "}</span>
                            <span className="text-acc4">
                              {dec(h.prize_eth, tokdecn(r.paytoken))}
                              {` ${r.paytoken}`}
                            </span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </Card>
  );
};

const RecentPays = ({ pp }) => {
  const appcon = useAppContext();
  const { tok_to_usd_val, usd_to_tok_val } = appcon;
  const { pays = [], vmap = {}, hmap = {} } = pp;
  return (
    <div className="fc-cc w-[50rem] max-w-[95%] mx-auto my-4">
      <p className="text-acc4 resp-text-2 font-digi">Users winning right now</p>
      {pays.map((p) => {
        let v = vmap[p.vault];
        let amtusd = tok_to_usd_val(p.amt, p.token);
        return (
          <div className="fr-sc resp-text--2 font-digi resp-gap-2 my-3">
            <span className="text-acc4">{v}</span>
            <span>won</span>

            <Link target="_blank" to={polytxnidlink(p.id)}>
              <div className="fc-cc">
                <p className="text-green-300 fr-sc resp-gap-1">
                  <FontAwesomeIcon icon={faUsd} />
                  <span className="mx-1">{dec(amtusd, 2)}</span>
                </p>
                <span className="text-purple-300 resp-text--3">
                  {dec(p.amt, tokdecn(p.token))}
                  {` ${p.token}`}
                </span>
              </div>
            </Link>

            <span>racing core:</span>
            <span>{getv(hmap, `${p.hid}.name`)}</span>
            <span>{moment(p.date).fromNow(false)}</span>
          </div>
        );
      })}
    </div>
  );
};

export const ContactTag = ({ icon, txt, link }) => {
  return (
    <Link to={link} target="_blank">
      <div className="resp-p-2 fr-sc resp-gap-2 resp-text--1">
        <Tag className="fc-cc text-[1.5rem] xs:w-[1.5rem] xs:h-[1.5rem] md:w-[2rem] md:h-[2rem] lg:w-[4rem] lg:h-[4rem] rounded-full card-dark-bg shadow-md shadow-acc4 text-white">
          <FontAwesomeIcon className="resp-text-1" icon={icon} />
        </Tag>
        <span className="font-mon italic px-2">{txt}</span>
      </div>
    </Link>
  );
};

export const Footer = ({
  flashwinmsg = false,
  joinmsg = "Join using the links below",
}) => {
  return (
    <div className="bg-gradient-to-b from-r2lig/20 to-transparent w-full">
      <section className="mx-auto w-[60rem] max-w-[95vw] resp-p-2">
        {flashwinmsg && <></>}

        {true && (
          <p className="text-center my-2 font-digi resp-text-1">{joinmsg}</p>
        )}
        <p className="fr-cc">
          {/* <div className="fc-cc rounded-full overflow-hidden max-w-[10rem]">
            <img src="https://i.imgur.com/lYds0vd.png" />
          </div> */}
          <div className="grid xs:grid-cols-2 md:grid-cols-4 justify-center items-start p-2">
            <ContactTag
              link="https://www.twitch.tv/hale_end_ra"
              icon={faTwitch}
              txt="danshan11"
            />
            <ContactTag
              link="https://twitter.com/Spotonparts"
              icon={faTwitter}
              txt="@Spotonparts"
            />
            <ContactTag
              link={"https://discord.gg/j4k3NdY6St"}
              icon={faDiscord}
              txt={<>Discord</>}
            />
            <ContactTag
              link={"https://market.dnaracing.run"}
              icon={faMapMarkedAlt}
              txt={
                <div className="fc-cc">
                  <span>DNA Market</span>
                  <span className="resp-text--2 text-acc4">Buy A Core Now</span>
                </div>
              }
            />
          </div>
        </p>
      </section>
    </div>
  );
};

const ChatHelp = ({ shouldLoadScript }) => {
  function removeChatWidgetIframe() {
    var iframe = document.querySelector(".widget-visible");
    if (iframe) {
      iframe.parentElement.removeChild(iframe);
    }

    var srctag = document.querySelector("#taw-script");
    if (srctag) {
      srctag.parentElement.removeChild(srctag);
    }

    let scrtags = document.querySelectorAll("script");
    scrtags.forEach((tag) => {
      if (tag.src.includes("tawk")) {
        tag.parentElement.removeChild(tag);
      }
    });
  }

  useEffect(() => {
    if (shouldLoadScript) {
      // condition to load script
      const script = document.createElement("script");
      script.src = "https://embed.tawk.to/5e83eb5069e9320caabf0d0a/default";
      script.id = "taw-script";
      script.async = true;
      script.charset = "UTF-8";
      script.setAttribute("crossorigin", "*");
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
        setTimeout(() => {
          removeChatWidgetIframe();
        }, 1500);
        setTimeout(() => {
          removeChatWidgetIframe();
        }, 2500);
      };
    } else {
      removeChatWidgetIframe();
    }
  }, [shouldLoadScript]);

  return <div>{/* Your component content */}</div>;
};

function HomePage() {
  const aucon = useAuthContext();
  const { auth, vault } = aucon;

  useEffect(() => {
    if (auth === true) history(`/vault/${vault}`);
  }, [auth]);

  const { path, history } = useAppContext();
  const [qorecent, qopays] = useQueries([
    q_recentraces({ limit: 10 }),
    q_recentpays({ limit: 3 }),
  ]);

  const rr = useMemo(() => {
    if (!qissuccesss(qorecent)) return { races: [] };
    return getv(qorecent, "data.result") || {};
  }, [qorecent.dataUpdatedAt]);

  const pays = useMemo(() => {
    if (!qissuccesss(qopays)) return {};
    return getv(qopays, "data.result") || {};
  }, [qopays.dataUpdatedAt]);

  const claim_trainer_btn = useRef(null);
  // useEffect(() => {
  //   if (nils(claim_trainer_btn.current)) return;
  //   console.log("claim_trainer_btn", claim_trainer_btn.current);
  //   let persona = window._persona;
  //   console.log("persona", persona);
  //   if (nils(persona)) return;
  //
  //   claim_trainer_btn.current.addEventListener("click", (e) => {
  //     let infoob = {
  //       button_id: "claim_trainer_btn",
  //       description: "The button was clicked",
  //     };
  //     console.log("persona", infoob);
  //     persona.Attribution.track("claim_button_click", infoob);
  //   });
  // }, [claim_trainer_btn.current, window._persona]);

  /* const [showchat, set_showchat] = useState(false);
  const timeout = useRef(null);
  useEffect(() => {
    console.log({ auth });
    if (timeout.current) clearTimeout(timeout.current);
    if (auth !== true) {
      timeout.current = setTimeout(() => {
        set_showchat(true);
      }, 3000);
    } else {
      set_showchat(false);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [auth]); */

  return (
    <div className="h-[100vh] overflow-y-auto">
      {/* <div className="h-[5rem]"></div> */}
      <div class="grid gap-1 bg-black xs:grid-cols-1 lg:grid-cols-2 w-full">
        <div class="col-span-1 bg-r2lig/20 backdrop-blur-md">
          <Player3D />
        </div>
        <div class="fc-cc bg-r2lig/20 backdrop-blur-md">
          <RecentPays pp={pays} />
        </div>
        <div class="fc-cc min-h-[10rem] bg-r2lig/20 backdrop-blur-md">
          <p className="resp-text-0 text-white font-digi w-full text-center">
            Test it out now NO signup
          </p>
          <p className="resp-text-0 text-r2lig font-digi w-full text-center">
            You can be WINNING too
          </p>

          <Link className="w-max mx-auto" to="/arcaderace">
            <p className="pulse-anim cursor-pointer my-1 text-white font-digi text-center resp-text-0 bg-r2lig/50 mx-auto w-max p-1 rounded-md shadow-md shadow-acc4 max-w-[95vw]">
              {"Click here, play now"}
            </p>
          </Link>
        </div>

        <div class="fc-cc min-h-[10rem] bg-r2lig/20 backdrop-blur-md">
          <p className="resp-text-0 text-white font-digi w-full text-center">
            Join Now
          </p>
          <p className="resp-text-0 text-r2lig font-digi w-full text-center">
            get a FREE Trainer Bike
          </p>

          <p className="resp-text-0 text-r2lig font-digi w-full text-center">
            Trainer Bike can race in FREE races for ETH prizes{" "}
          </p>
          <Link
            ref={claim_trainer_btn}
            className="w-max mx-auto"
            to="/claim-trainer"
          >
            <p className="pulse-anim cursor-pointer my-1 text-white font-digi text-center resp-text-0 bg-r2lig/50 mx-auto w-max p-1 rounded-md shadow-md shadow-acc4 max-w-[95vw]">
              {"Claim Trainer now"}
            </p>
          </Link>
        </div>
      </div>
      {/* <RecentRacesCarousel rr={rr} /> */}
      <Footer flashwinmsg={true} />
      {/* <ChatHelp shouldLoadScript={showchat} /> */}
    </div>
  );
}

export default HomePage;
