import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { gen_hash, nano, nils } from "../utils/utils.js";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNowContext } from "../App.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MoVariants } from "../utils/motion_helper.js";
import { polychainimg } from "../utils/links.js";
import { polytxnidlink } from "../queries/queries.js";
import { Link } from "react-router-dom";

export const Tag = (props) => {
  let pointer = props.onClick || props.redirect ? "cursor-pointer" : "";
  return (
    <a
      {...(props.redirect
        ? {
            href: props.redirect,
            target: props.target ?? "_blank",
          }
        : {})}
      {...{
        className: props.acn ?? "",
      }}
    >
      <div
        {...props}
        onClick={(e) => {
          if (props.onClick) props.onClick(e);
        }}
        style={{ ...props.style }}
        className={twMerge(
          "xs:text-[0.45rem] lg:text-[0.8rem]",
          // "resp-text--2",
          "resp-px-2 resp-py-1 font-bold",
          "resp-mx-1 my-0.5 font-bold",
          `nowrap lg:rounded-md xs:rounded-[0.22rem]`,
          pointer,
          props?.active === false ? "bg-opacity-25" : "",
          props.className,
        )}
      >
        {props.children}
      </div>
    </a>
  );
};

export const Img = ({ className, img }) => {
  return (
    <div className={twMerge("img-obey-cont h-auto", className)}>
      <img src={img} />
    </div>
  );
};

export const Card = ({ className, style, ...props }) => {
  return (
    <div
      {...props}
      style={style}
      className={twMerge(
        "rounded-md resp-p-4 mb-2 bg-r2lig/20 w-max mx-auto",
        className,
      )}
    >
      {props.children}
    </div>
  );
};

export const HeadC2L = ({ className, linecn, ...props }) => {
  const Line = (
    <>
      <div
        className={twMerge(
          "h-1 min-w-[5rem] rounded-md resp-px-1 bg-white max-w-[10rem]",
          linecn,
        )}
      ></div>
    </>
  );
  return (
    <div className="fr-cc w-full resp-gap-2 mb-2">
      <div className="flex-1"></div>
      {Line}
      <div className="w-max">
        <span className={twMerge("text-acc0 resp-text-3 font-digi", className)}>
          {props.children}
        </span>
      </div>
      {Line}
      <div className="flex-1"></div>
    </div>
  );
};

export const BImg = ({ hex_code, className }) => {
  return (
    <>
      <div
        style={{ background: `#${hex_code}` }}
        className={twMerge("w-[4rem] h-[4rem] resp-m-1 rounded-md", className)}
      ></div>
    </>
  );
};

const inpkeydel = 300;

export const InpText = (props) => {
  const {
    contprops = {},
    inpprops = {},
    label,
    setter,
    def_val = "",
    type,
    autoComplete,
  } = props;
  const inpref = useRef();
  const lastkey = useRef(null);
  const nc = useNowContext();
  const diff = nc.now - lastkey.current;
  useEffect(() => {
    if (diff > inpkeydel) {
      let value = inpref.current.value;
      if (setter) setter(value);
    }
  }, [diff > inpkeydel]);
  useEffect(() => {
    inpref.current.value = def_val;
  }, [def_val]);
  const [showpass, set_showpass] = useState(false);

  return (
    <motion.div
      {...contprops}
      onClick={() => inpref.current.focus()}
      className={twMerge("inp-container ", contprops.className)}
    >
      {label && <div className="inp-label">{label}</div>}
      {
        <div className="flex flex-row justify-start">
          <input
            id={props.id}
            placeholder={props.placeholder ?? ""}
            onKeyDown={(e) => {
              lastkey.current = nano();
              if (props.onKeyDown) props.onKeyDown(e);
            }}
            onChange={(e) => {
              lastkey.current = nano();
              if (props.onChange) props.onChange(e);
            }}
            onBlur={(e) => {
              lastkey.current = nano();
              if (props.onBlur) props.onBlur(e);
            }}
            ref={inpref}
            type={showpass ? "text" : type || "text"}
            defaultValue={def_val}
            className=""
            autoComplete={autoComplete}
            {...inpprops}
          />

          {contprops.showpass_btn === false ? null : (
            <>
              {type == "password" ? (
                <div
                  className="resp-p-1 w-[1.5rem] cursor-pointer"
                  onClick={() => {
                    if (type == "password") set_showpass(!showpass);
                  }}
                >
                  <FontAwesomeIcon icon={showpass ? faEyeSlash : faEye} />
                </div>
              ) : (
                <div className="w-[1.5rem]"></div>
              )}
            </>
          )}
        </div>
      }
    </motion.div>
  );
};

export const Hori_Divider = () => {
  return <div className="resp-my-1 h-1 bg-acc0/30 rounded-md"></div>;
};

export const Dropdown = ({
  wrapcn,
  trigger,
  triggercn,
  dropcn,
  force_hover = null,
  ...props
}) => {
  const [hover, set_hover] = useState(!nils(force_hover) ? force_hover : false);
  useEffect(() => {
    // console.log("dropdown:force_hover", force_hover);
    if (force_hover !== null) set_hover(force_hover);
  }, [force_hover]);
  // useEffect(() => { console.log("dropdown:hover", hover); }, [hover]);

  return (
    <div className="relative" onMouseLeave={() => set_hover(false)}>
      <div className={wrapcn}>
        <div
          onMouseEnter={() => set_hover(true)}
          className={twMerge(
            "fr-cc resp-mx-4 resp-gap-1 relative cursor-pointer z-10",
            triggercn,
          )}
        >
          {trigger}
        </div>
        <motion.div
          initial={hover ? "visible" : "hidden"}
          variants={MoVariants.show_hide}
          animate={hover ? "visible" : "hidden"}
          className={twMerge("absolute w-full z-20", dropcn)}
        >
          {props.children}
        </motion.div>
      </div>
    </div>
  );
};

export const ToolTip = ({ children, message, msg_cn = "" }) => {
  return (
    <div className="relative flex flex-col items-center group cursor-pointer">
      {children}
      <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
        <span
          className={twMerge(
            "relative z-10 p-2 leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md",
            msg_cn,
          )}
        >
          {message}
        </span>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div>
      </div>
    </div>
  );
};

export const tokenimgmap = {
  WETH: "https://token-icons.s3.amazonaws.com/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",
  weth: "https://token-icons.s3.amazonaws.com/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",

  ETH: "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/512/Ethereum-ETH-icon.png",
  MATIC: polychainimg,
  DEZ: "https://i.imgur.com/sbvrqAU.png",
  USD: "https://i.imgur.com/y6kotUk.png",
  BGC: "https://cdn.dnaracing.run/imgs/bgc.png",
  SGC: "https://cdn.dnaracing.run/imgs/sgc.png",

  "weth-mock01":
    "https://token-icons.s3.amazonaws.com/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",

  "WETH-mock-01":
    "https://token-icons.s3.amazonaws.com/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",

  LC01: "https://imgur.com/YjaFouL.png",
  lc01: "https://imgur.com/YjaFouL.png",

  lc02: "https://imgur.com/YjaFouL.png",

  LC: "https://cdn.dnaracing.run/imgs/lc.png",
  lc: "https://cdn.dnaracing.run/imgs/lc.png",

  trailspoints: "https://cdn.dnaracing.run/imgs/trailspoints.png",
  tp: "https://cdn.dnaracing.run/imgs/trailspoints.png",
  TP: "https://cdn.dnaracing.run/imgs/trailspoints.png",
};

export const TokenIcon = ({ token, className, size = "xs" }) => {
  return (
    <div
      className={twMerge(
        "rounded-full overflow-hidden resp-px-1 fc-cc",
        size == "xxs"
          ? "xs:w-[0.6rem] xs:h-[0.6rem] lg:w-[1.2rem] lg:h-[1.2rem]"
          : size == "xs"
            ? "xs:w-[1rem] xs:h-[1rem] lg:w-[2rem] lg:h-[2rem]"
            : size == "md"
              ? "xs:w-[1.5rem] xs:h-[1.5rem] lg:w-[3rem] lg:h-[3rem]"
              : size == "lg"
                ? "xs:w-[2rem] xs:h-[2rem] lg:w-[4rem] h-[4rem]"
                : "",
        className,
      )}
    >
      <Img img={tokenimgmap[token]} />
    </div>
  );
};

export const PolyLink = ({ hash }) => {
  return (
    <Link target={"_blank"} to={polytxnidlink(hash)}>
      <div class="xs:w-[1.2rem] lg:w-[2rem]">
        <Img img={polychainimg} />
      </div>
    </Link>
  );
};

export function useDebouncedEffect(effect, deps, delay) {
  const prev_timeout = useRef();
  // const prev_time = useRef(nano());

  // const now = nano();
  // const diff = now - prev_time.current;

  useEffect(() => {
    // console.log("diff", gen_hash(deps));
    if (prev_timeout.current) clearTimeout(prev_timeout.current);

    prev_timeout.current = setTimeout(() => {
      effect();
    }, delay);
    return () => {};
  }, [...deps]);
}
