import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Tag } from "../components/utilityComps.js";
import { cdelay } from "../utils/utils.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const ClearCache = () => {
  const tcon = useThirdWebLoginContext();
  const aucon = useAuthContext();
  const [loading, set_loading] = useState(false);

  const whole_logout = async () => {
    await tcon.logout();
    await aucon.logout();
  };
  const clear = async () => {
    set_loading(true);
    await cdelay(2000);

    await whole_logout();
    await cdelay(1000);

    localStorage.clear();

    await cdelay(2000);
    set_loading(false);

    window.location = "/";
  };

  return (
    <div>
      <div className="h-[5rem]"></div>
      <div className="fc-cc resp-gap-2">
        <Tag
          onClick={clear}
          className="fr-sc resp-gap-1 border border-acc0 text-acc0"
        >
          {loading && (
            <FontAwesomeIcon icon={faSpinner} className="spin-anim  " />
          )}
          <span>clear cache & logout</span>
        </Tag>
      </div>
    </div>
  );
};
