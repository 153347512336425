import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../constants.js";
import { get_gasinfo } from "../contract_funcs.js";
import { nils } from "../../utils/utils.js";

class RaceStakeV03_DEZ_Contract {
  constructor({ contractAddress, provider, signer }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, this.abi, signer);
  }

  async enterRace(raceId, hid, ext_info = {}) {
    let gasinfo = await get_gasinfo();
    let info = { ...gasinfo, ...ext_info };
    console.log("gas", info);
    const tx = await this.contract.userEnterRace(raceId, hid, info);
    // const resp = await tx.wait();
    return tx;
  }

  // struct Race {
  //     string raceid;
  //     bool adminEntry;
  //     uint rgates;
  //     uint entryFee;
  //     uint[] hids;
  //     address[] vaults;
  //     address[] winners;
  //     uint[] payouts;
  //     bool isScheduled;
  //     bool isFinished;
  //     bool isCancelled;
  // }
  // function getRace( string memory raceid) public view returns (Race memory _r) {
  async getRace(raceId) {
    console.log("getRace", raceId);
    let r = await this.contract.getRace(raceId);
    console.log(r);
    let i = 0;
    let race = {
      raceId: r[i++],
      adminEntry: r[i++],
      rgate: r[i++],
      entryFee: r[i++].toString(),
      hids: r[i++],
      participants: r[i++],
      winners: r[i++],
      payouts: r[i++],
      isScheduled: r[i++],
      isFinished: r[i++],
      isCancelled: r[i++],
    };
    race.hids = race.hids.map((hid) => Number(hid));
    race.participants = race.participants.map((v) => v.toLowerCase());
    race.winners = race.winners.map((v) => v.toLowerCase());
    race.payouts = race.payouts.map((v) => v.toString());
    console.log("getRace", race);
    return race;
  }
}

const get_RaceStakeV03_DEZ_Contract = async (ext = {}) => {
  // console.log("get_RaceStakeV03_DEZ_Contract", ext);
  const contractAddress = contractAddress_list.racestakev03dez;
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();
  const runner = new RaceStakeV03_DEZ_Contract({
    contractAddress,
    provider,
    signer,
  });
  return runner;
};

const RaceStakeV03_DEZ = {
  class: RaceStakeV03_DEZ_Contract,
  get_contract: get_RaceStakeV03_DEZ_Contract,
};

export default RaceStakeV03_DEZ;
