import {
  useState,
  useMemo,
  useEffect,
  createContext,
  useContext,
  useRef,
} from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Img, Tag } from "./utilityComps";
import {
  faChevronLeft,
  faChevronRight,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { useAppContext } from "../App";
import { PopUp, PopupCloseBtn } from "./popup";
import { getv, jstr, nils } from "../utils/utils";
import _ from "lodash";
import { useQueries } from "react-query";
import { q_faq, qissuccesss } from "../queries/queries";

const ImgsSlideShow = ({ imgs = [] }) => {
  const [idx, set_idx] = useState(0);
  const next = () => {
    if (nils(idx)) {
      set_idx(0);
      return;
    }
    set_idx((idx + imgs.length + 1) % imgs.length);
  };
  const prev = () => {
    if (nils(idx)) {
      set_idx(0);
      return;
    }
    set_idx((idx + imgs.length - 1) % imgs.length);
  };

  const cycle = useRef(null);
  useEffect(() => {
    // console.log(idx, imgs[idx]);
    if (cycle.current) clearTimeout(cycle.current);
    cycle.current = setTimeout(() => {
      next();
    }, 10 * 1e3);
    return () => {
      clearTimeout(cycle.current);
    };
  }, [idx]);

  const btncn = "w-[10%] fc-cc hover:bg-acc0/10 text-white";

  return (
    <div class="select-none">
      <div class="flex flex-row justify-center items-stretch">
        <div onClick={prev} class={btncn}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="text-white resp-text-3"
          />
        </div>
        <div class="w-[80%] aspect-[1694/1080] max-w-[60vw]">
          <Img img={imgs[idx]} />
        </div>
        <div onClick={next} class={btncn}>
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-white resp-text-3"
          />
        </div>
      </div>

      <div class="fr-cc w-full resp-gap-1 my-1">
        {[...Array(imgs.length)].map((e, i) => {
          return (
            <div
              class={twMerge(
                "w-[1rem] aspect-[1/1] rounded-full bg-white ",
                idx >= i ? "bg-white" : "bg-white/20",
              )}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

const FAQ_Row = ({ row }) => {
  const [expand, set_expand] = useState(false);
  return (
    <div
      onClick={() => {
        set_expand(!expand);
      }}
      class="resp-text-0 my-2 p-2 border-b border-acc4 cursor-pointer"
    >
      <div class="fr-ss resp-text-1 resp-gap-2 my-1">
        <p>{row.ordidx + 1}.</p>
        <p className="">{row.ques}</p>
      </div>
      {expand && (
        <div class="fr-ss resp-text-0 resp-gap-2 my-1">
          <div class="w-[1rem]"></div>
          <p className="text-acc0 resp-text-2 ">{"> "}</p>
          <p className="resp-text-0 flex-1">{row.answ}</p>
        </div>
      )}
    </div>
  );
};

const FAQ_Section = ({ path }) => {
  const [qofaq] = useQueries([q_faq({ path }, { enabled: !nils(path) })]);
  const ar = useMemo(() => {
    let o = getv(qofaq, "data.result", []);
    // console.log("faq", o);
    return o;
  }, [qofaq.dataUpdatedAt]);

  if (_.isEmpty(ar)) return null;

  return (
    <>
      <div class="resp-p-4">
        <p class="resp-text-1 font-digi my-1 text-center">
          Frequently Asked Questions
        </p>
        {ar.map((e) => {
          return <FAQ_Row {...{ row: e }} />;
        })}
      </div>
    </>
  );
};

const imgmap = {
  "/races": {
    lg: [
      "help_popup/races_01.png",
      "help_popup/races_02.png",
      "help_popup/races_03.png",
      "help_popup/races_04.png",
      "help_popup/races_05.png",
      "help_popup/races_06.png",
      "help_popup/races_07.png",
      "help_popup/races_08.png",
      "help_popup/races_09.png",
      "help_popup/races_10.png",
    ],
  },
  "/vault": {
    lg: ["help_popup/vault_01.png", "help_popup/vault_02.png"],
  },
  "/trainer-leaderboard-races-races": {
    lg: [
      "help_popup/races_02.png",
      "help_popup/races_03.png",
      "help_popup/races_04.png",
      "help_popup/races_07.png",
      "help_popup/races_08.png",
      "help_popup/races_09.png",
      "help_popup/races_10.png",
    ],
  },
};

const Def_HelpInf = ({ path }) => {
  const size = "lg";
  const imgs = useMemo(() => {
    // console.log("def_helpinf", { path, size });
    return _.chain(getv(imgmap[path], size, []))
      .map((i) => `https://cdn.dnaracing.run/${i}`)
      .value();
  }, [path, size]);

  return (
    <div class="w-full h-full">
      <ImgsSlideShow imgs={imgs} />
      <FAQ_Section {...{ path }} />
    </div>
  );
};

export const HelpPop = ({
  pop,
  set_pop,
  overide_path: override_path = null,
}) => {
  const { path, psearch } = useAppContext();
  // const [pop, set_pop] = useState(psearch?.help == "true" ?? false);

  // const [pop, set_pop] = useState(false);

  // useEffect(() => { console.log("path", path); }, [path]);

  const pathcompob = {
    "/races": Def_HelpInf,
    "/vault": Def_HelpInf,
    "/trainer-leaderboard-races-races": Def_HelpInf,
  };
  const [upath, pathcomp] = useMemo(() => {
    if (!nils(override_path)) return [override_path, pathcomp[override_path]];
    let u = null;
    if (pathcompob[path]) return [path, pathcompob[path]];
    else if (path.startsWith("/vault/")) {
      u = "/vault";
      return [u, pathcompob[u]];
    } else if (
      path.startsWith("/trainer-leaderboard") &&
      psearch.tab == "races" &&
      psearch.subtab == "races"
    ) {
      u = "/trainer-leaderboard-races-races";
      return [u, pathcompob[u]];
    }
    return [null, null];
  }, [path, jstr(psearch), override_path]);

  if (nils(upath)) return null;
  return (
    <div class="mx-2">
      <Tag
        onClick={() => {
          set_pop(true);
        }}
        className={twMerge(
          "fr-sc resp-gap-2 bg-r2dark/60 text-acc0 border border-acc4 select-none",
        )}
      >
        <FontAwesomeIcon className="resp-text-2" icon={faQuestionCircle} />
        <span className="resp-text-1">HELP</span>
      </Tag>
      <PopUp
        {...{
          openstate: pop,
          overlayclose: false,
          onClose: () => set_pop(false),
          wrapcn: "top-[2rem]",
          innercn: "translate-y-[0%]",
        }}
      >
        <PopupCloseBtn
          closepopup={() => {
            set_pop(false);
          }}
        />
        <div class="relative w-[90vw] h-[90vh] bg-r2dark/60 rounded-lg border border-acc0 resp-p-2 overflow-auto">
          {!nils(pathcomp) && React.createElement(pathcomp, { path: upath })}
        </div>
      </PopUp>
    </div>
  );
};
