import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { get_auth_header, useAuthContext } from "../wrappers/AuthWrapper.js";
import { Loader01c } from "../components/anims.js";
import {
  BImg,
  Card,
  HeadC2L,
  Img,
  InpText,
  Tag,
  TokenIcon,
} from "../components/utilityComps.js";
import { useMatch, useParams } from "react-router";
import {
  base64_to_json,
  dec,
  get_inp,
  getv,
  json_to_base64,
  jstr,
  nils,
  set_inp,
  iso_format,
  cdelay,
  jparse,
  gen_hash,
} from "../utils/utils.js";
import { useInfiniteQuery, useQueries } from "react-query";
import {
  iserr,
  q_bikeinfo,
  q_hstats_doc,
  q_price,
  q_vault_transfer,
  q_vault_updprofile,
  q_vaultbikes,
  q_vaultinfo,
  qiserr,
  qissuccesss,
  useStepQuery,
  btbk,
  q_validate_vault_name,
  q_vaultskins,
  q_skininfo,
  q_bikeinfo_cac,
  q_vaultinfo_cc,
  q_vault_get_cprofiles,
  q_vault_save_cprofile,
  q_vault_delete_cprofile,
  q_vaultbikesinf,
} from "../queries/queries.js";
import { motion } from "framer-motion";
import { MoVariants } from "../utils/motion_helper.js";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import { PopUp } from "../components/popup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBox,
  faCheck,
  faCheckSquare,
  faCircle,
  faGear,
  faLink,
  faPencilAlt,
  faRefresh,
  faSpinner,
  faSquare,
  faStar,
  faTimes,
  faTrash,
  faTrophy,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import BikeCard from "../components/BikeCard.js";
import { Helmet } from "react-helmet-async";
import "intersection-observer";
import { Link } from "react-router-dom";
import {
  SortHead,
  filt_ar_using_filters,
  gen_filters_from_valob,
  gen_valob_from_filters,
  mainfiltbtn,
  sort_fn,
  sort_listob,
} from "../utils/filt.js";
import {
  ElementTag,
  MiniElementTag,
  MiniGenderTag,
} from "../components/ShortComps.js";
import {
  cb_txt,
  class_cn,
  class_text,
  elementmap,
  gendermap,
  get_cprofile_hex,
  rvmode_s,
  tablecn,
} from "../utils/cn_map.js";
import {
  default_cprofile,
  mycprofile_key,
  useAccountContext,
} from "../wrappers/AccountWrapper.js";
import { racecn } from "./Races.js";
import { tokdecn, tokdecn2, useAppContext } from "../App.js";
import { InpAutoWrap, set_state_ob } from "../components/input.js";
import moment from "moment";
import { core_market_link, core_os_link } from "../utils/links.js";
import { fpost } from "../queries/fetch.js";
import { BY_Star, PosTag } from "../utils/raceutils.js";
import { IfInView } from "./FinishedRacesPage.js";
import { TrophiesTab } from "./Trophies.js";
import { GlowBtnGroup, SkewBtn, svg_map } from "../utils/raceutils2.js";
import { SpliceActivityInner } from "./SpliceActivity.js";
import { SkinCard } from "../components/CardComps.js";
import { RVImg } from "../components/BikeImg.js";
import { SliderPicker, TwitterPicker } from "react-color";
import axios from "axios";
import { ImageCropper } from "../utils/process_image.js";
import { useCoresFullData } from "../hooks/useCoresFullData.js";

export const VaultContext = createContext({});
export const useVaultContext = () => useContext(VaultContext);

const VaultEditContext = createContext({});
export const useVaultEditContext = () => useContext(VaultEditContext);

const ColorTag = ({ path, text }) => {
  const vcon = useVaultEditContext();
  const { cprofile, set_cprofile, selectedpath, set_selectedpath } = vcon;
  const v = getv(cprofile, path);
  return (
    <div class="fr-sc resp-gap-2 flex-wrap cursor-pointer resp-p-2">
      <div
        onClick={() => {
          set_selectedpath(path);
        }}
        class={twMerge(
          "fr-sc resp-gap-2 cursor-pointer p-2 rounded-md",
          path == selectedpath ? "border-2 border-white" : "",
        )}
      >
        <div
          style={{
            backgroundColor: `rgb(${v?.r ?? 0} ${v?.g ?? 0} ${v?.b ?? 0})`,
          }}
          class="xs:w-[0.5rem] w-[1rem] aspect-[1/1] rounded-[4px]"
        ></div>
        <span className="resp-text--1">{text}</span>
      </div>
    </div>
  );
};

const cops = [
  ["pagebg", "Page BG"],
  ["r2dark", "Dark"],
  ["r2reg", "Regular"],
  ["r2lig", "Light"],
  ["acc0", "Accent 0"],
  ["acc4", "Accent 4"],
];
const CProfileColorTag = ({ cprofile: cp = {}, onClick, idx, active }) => {
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      class={twMerge(
        "grid grid-cols-3 xs:gap-[1] lg:gap-[3] p-2 cursor-pointer rounded-md",
        active ? "border border-acc0" : "",
      )}
    >
      {_.map(cops, 0).map((k) => {
        return (
          <div
            key={k}
            style={{
              backgroundColor: cp[k]?.hex,
            }}
            className="xs:w-[1rem] w-[2rem] aspect-[1/1] rounded-[4px]"
          ></div>
        );
      })}
    </div>
  );
};

const VaultPageCustomization = ({ set_editcprofile }) => {
  const vcon = useVaultContext();
  const accon = useAccountContext();
  const { vault, cprofile, set_cprofile } = accon;

  const [qocprofiles] = useQueries([q_vault_get_cprofiles({ vault })]);
  const [cprofiles, set_cprofiles] = useState([]);
  useEffect(() => {
    console.log("qocprofiles", qocprofiles);
    let cps = getv(qocprofiles, "data.result", []);
    set_cprofiles(cps);
    // return cps;
    return;
  }, [qocprofiles.dataUpdatedAt]);

  const [selectedpath, set_selectedpath] = useState(getv(cops, "0.0"));
  const [bkp, set_bkp] = useState({});

  const vecon = {
    cprofile,
    set_cprofile,
    selectedpath,
    set_selectedpath,
  };

  const change_color = (path, color) => {
    console.log("change", path, color.rgb, color.hex);
    // let v = { ...color.rgb, hex: color.hex };
    let v = { ...color.rgb, hex: color.hex };
    set_state_ob(cprofile, set_cprofile, path, v);
  };

  const revert_bkp_color = () => {
    if (!nils(bkp)) set_state_ob(cprofile, set_cprofile, selectedpath, bkp);
  };

  const save_new_cprofile = async () => {
    // let basec = getv(qocprofiles, "data.result.0");
    // if (gen_hash({ ...cprofile, hash: 0 }) == gen_hash({ ...basec, hash: 0 })) {
    //   console.log("same");
    //   return;
    // }
    localStorage.setItem(mycprofile_key, jstr(cprofile));
    let resp = await q_vault_save_cprofile({ vault, cprofile }).queryFn();
    console.log(resp);
    setTimeout(() => {
      qocprofiles.refetch();
    }, 1 * 1e3);
  };

  const delete_cprofile = async (cprofile_hash) => {
    let resp = await q_vault_delete_cprofile({
      vault,
      cprofile_hash,
    }).queryFn();
    set_cprofiles(cprofiles.filter((e) => e.hash != cprofile_hash));
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     save_new_cprofile();
  //   }, 5 * 1e3);
  //   return () => {
  //     if (timer) clearTimeout(timer);
  //   };
  // }, [cprofile]);
  useEffect(() => {
    return () => {
      save_new_cprofile();
    };
  }, []);

  return (
    <VaultEditContext.Provider value={vecon}>
      <Card
        className={twMerge(
          "col-span-12 backdrop-blur-md w-full overflow-auto relative",
        )}
      >
        <div class="absolute top-0 right-0">
          <Tag
            onClick={() => {
              set_editcprofile(false);
            }}
            className="fc-cc w-[2rem] rounded-full aspect-[1/1] resp-gap-1 text-acc4 bg-red-400"
          >
            <FontAwesomeIcon
              className="resp-text-2 text-white"
              icon={faTimes}
            />
          </Tag>
        </div>
        <p class="resp-text-0 font-digi my-2">Edit Color Profile for Vault</p>
        <div class="grid grid-cols-3 resp-gap-2">
          {cops.map(([path, text]) => {
            if (nils(text)) text = path;
            return <ColorTag {...{ path, text }} />;
          })}
        </div>
        {!nils(selectedpath) && (
          <div class="grid xs:grid-cols-1 lg:grid-cols-2 resp-gap-2 max-w-full overflow-hidden">
            <div class="">
              <div class="">
                <TwitterPicker
                  colors={[
                    "#000000",
                    "#007BFF",
                    "#6C757D",
                    "#28A745",
                    "#DC3545",
                    "#FFC107",
                    "#17A2B8",
                    "#343A40",
                    "#E0E0E0",
                    "#6F42C1",
                  ]}
                  triangle={"hide"}
                  color={getv(cprofile, selectedpath)}
                  onChange={(e) => {
                    change_color(selectedpath, e);
                  }}
                  // onSwatchHover={(e) => {
                  //   change_color(selectedpath, e);
                  // }}
                />
              </div>
            </div>
            <div>
              <div class="xs:w-[95%] lg:w-[95%]">
                <SliderPicker
                  color={getv(cprofile, selectedpath)}
                  onChange={(e) => {
                    change_color(selectedpath, e);
                  }}
                  // onSwatchHover={(e) => {
                  //   change_color(selectedpath, e);
                  // }}
                />
              </div>
            </div>
          </div>
        )}

        <hr className="my-2 w-full" />
        <p className="resp-text-0 font-digi">Saved Color Profiles</p>
        <div class="fr-sc w-full resp-gap-2 flex-wrap">
          {_.uniqBy([...cprofiles, default_cprofile], (e = {}) =>
            gen_hash({ ...e, hash: 0 }),
          ).map((cp, idx) => {
            let active = gen_hash(cp) == gen_hash(cprofile);
            return (
              <div class="relative">
                <CProfileColorTag
                  {...{
                    key: idx,
                    cprofile: cp,
                    idx,
                    active,
                    onClick: () => {
                      set_cprofile(cp);
                    },
                  }}
                />
                {default_cprofile?.hash !== cp.hash && (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      delete_cprofile(cp.hash);
                    }}
                    class="absolute cursor-pointer opacity-10 hover:opacity-100 top-0 right-0"
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="resp-text-1 text-red-400"
                    />
                  </div>
                )}
              </div>
            );
          })}
          <div class="flex-1"></div>
          <div class="h-[4rem] w-[0.1rem] bg-white mx-2"></div>
          <div class="fc-cc">
            <CProfileColorTag
              {...{
                key: "c",
                cprofile: cprofile,
                onClick: () => {},
                delete: () => {},
              }}
            />
          </div>
          <Tag
            onClick={() => {
              save_new_cprofile();
            }}
            className="fr-sc resp-gap-1 text-acc0 border border-acc0"
          >
            <span class="resp-text-0 font-digi">Save</span>
          </Tag>
        </div>
      </Card>
    </VaultEditContext.Provider>
  );
};

const VaultProfile = () => {
  const vcon = useVaultContext();
  const { vdoc, is_auvault } = vcon;

  const [src, set_src] = useState(vdoc.profile_url);
  useEffect(() => {
    set_src(vdoc.profile_url);
  }, [vdoc.profile_url]);

  const key = "profile";
  const id = "upload-profile";
  const [edit, set_edit] = useState(false);

  const on_save = async (imgblob) => {
    if (!imgblob) return;
    let file = new File([imgblob], "cropped_image.png", { type: "image/png" });
    await vault_upload_request(key, file);
    setTimeout(() => {
      set_src(`${vdoc.profile_url}?t=${new Date().getTime()}`);
    }, 2 * 1e3);
  };

  return (
    <Card
      className={twMerge(
        "col-span-4",
        "bg-r2lig/20 backdrop-blur-md w-full",
        "fc-cc relative",
      )}
    >
      <div class="xs:w-[10rem] lg:w-[20rem] max-w-[100%] aspect-[1/1]">
        <Img img={src} />
      </div>
      {is_auvault && (
        <div class="absolute top-[5%] right-[5%]">
          <ImageCropper
            {...{
              src,
              set_src,
              id,
              on_discard: () => {
                set_src(vdoc.profile_url);
              },
              on_save: async (img) => {
                await on_save(img);
              },
            }}
          />
        </div>
      )}
    </Card>
  );
};
const VaultBanner = () => {
  const vcon = useVaultContext();
  const { vdoc, is_auvault } = vcon;

  const obk = "banner_url";
  const key = "banner";
  const id = "upload-banner";
  const [src, set_src] = useState(vdoc[obk]);
  useEffect(() => {
    set_src(vdoc[obk]);
  }, [vdoc[obk]]);

  const [edit, set_edit] = useState(false);

  const on_save = async (imgblob) => {
    if (!imgblob) return;
    let file = new File([imgblob], "cropped_image.png", { type: "image/png" });
    await vault_upload_request(key, file);
    setTimeout(() => {
      set_src(`${vdoc[obk]}?t=${new Date().getTime()}`);
    }, 2 * 1e3);
  };

  return (
    <Card
      className={twMerge(
        "col-span-12",
        "bg-r2lig/20 backdrop-blur-md w-full",
        "fc-cc relative ",
      )}
    >
      <div class="w-full aspect-[4/1]">
        <Img img={src} />
      </div>
      {is_auvault && (
        <div class="absolute top-[5%] right-[5%]">
          <ImageCropper
            {...{
              src,
              set_src,
              aspect: 4 / 1,
              id,
              on_discard: () => {
                set_src(vdoc[obk]);
              },
              on_save: async (img) => {
                await on_save(img);
              },
            }}
          />
        </div>
      )}
    </Card>
  );
};

const vault_grab_asset = async (id, key) => {
  let el = document.getElementById(id);
  if (!el) return;
  let file = el.files[0];
  if (!file) return;
  return file;
};
const vault_upload_request = async (key, file) => {
  try {
    if (!key || !file) return;
    console.log("handle_upload:init", { key, file });
    const formData = new FormData();
    formData.append("file", file);
    let auheaders = get_auth_header();
    let api = `${btbk}/fbike/vault/upload_profile_imgs`;
    const resp = await axios.post(api, formData, {
      headers: {
        ...auheaders,
        "x-uploadkey": key,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("handle_upload:resp", resp);
  } catch (e) {
    console.log("handle_upload:err", e);
  }
};

const VaultPageProfileBanner_OldUpdater = () => {
  const vcon = useVaultContext();
  const { refresh_vaultdata } = vcon;

  const [loading, set_loading] = useState(false);

  const central_upload = async () => {
    if (loading) return;
    set_loading(true);

    await vault_upload_request(
      "banner",
      await vault_grab_asset("upload-banner"),
    );
    await vault_upload_request(
      "profile",
      await vault_grab_asset("upload-profile"),
    );

    setTimeout(() => {
      refresh_vaultdata();
    }, 1.5 * 1e3);
    setTimeout(() => {
      set_loading(false);
    }, 3 * 1e3);
  };

  return (
    <>
      <Card
        style={{ transition: "background 0.5s" }}
        className={twMerge(
          "bg-r2lig/20 backdrop-blur-md xs:w-[90%] lg:w-[50rem] mx-auto overflow-auto",
        )}
      >
        <div class="fr-sc resp-gap-2 my-2">
          <p>Upload profile</p>
          <p>
            <input type="file" id="upload-profile" />
          </p>
        </div>

        <div class="fr-sc resp-gap-2 my-2">
          <p>Upload banner</p>
          <p>
            <input type="file" id="upload-banner" />
          </p>
        </div>
        <div class="fr-sc resp-gap-2">
          <div class="flex-1"></div>
          <Tag
            onClick={() => {
              if (loading) return;
              central_upload();
            }}
            className={twMerge(
              "fr-sc resp-gap-1 bg-acc4/40  cursor-pointer -skew-x-12 text-white",
              loading ? "bg-acc0/10 text-acc4" : "",
            )}
          >
            {loading ? (
              <>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="rep-text-1 spin-anim text-white"
                />
                <span class="resp-text-1">Upload</span>
              </>
            ) : (
              <span class="resp-text-1">Upload</span>
            )}
          </Tag>
        </div>
      </Card>
    </>
  );
};

const VaultName = () => {
  const vcon = useVaultContext();
  const { qo_vaultinfo, vdoc, vault, is_auvault } = vcon;
  const [editmode, set_editmode] = useState(false);

  const newvname = get_inp("inp_vault_name");
  const [qovalid] = useQueries([
    q_validate_vault_name(
      { name: newvname },
      {
        enabled: editmode && !nils(newvname),
      },
    ),
  ]);
  const namevalid = useMemo(() => {
    let valid = getv(qovalid, "data.result.valid") == true;
    return valid;
  }, [qovalid.dataUpdatedAt]);

  const [editing, set_editing] = useState(false);
  const apply_updprofile = () => {
    set_editing(true);
    let id = "inp_vault_name";
    let upd = {};
    upd.vault_name = get_inp(id);
    if (nils(upd.vault_name)) {
      set_editing(false);
    } else {
      upd.vault_name = upd.vault_name.trim();
      q_vault_updprofile({ upd })
        .queryFn()
        .then(() => {
          setTimeout(() => {
            qo_vaultinfo.refetch();
          }, 1000);

          setTimeout(() => {
            set_inp(id, "");
            set_editmode(false);
            set_editing(false);
          }, 3000);
        });
    }
  };

  return (
    <>
      <Card
        style={{ transition: "background 0.5s" }}
        className={twMerge(
          "col-span-8",
          "bg-r2lig/20 backdrop-blur-md w-full",
          "fc-cc xs:w-full lg:w-full",
        )}
      >
        <motion.div
          variants={MoVariants.show_hide}
          animate={editmode == false ? "visible" : "hidden"}
        >
          <div className="fc-cs font-digi resp-text-1 flex-1 w-full">
            <div className="fr-cc resp-gap-2 flex-wrap my-4 w-full">
              <span className="w-full text-center resp-text-2">
                {vdoc.vault_name}
              </span>
            </div>
            <div className="fr-sc resp-gap-2 flex-wrap">
              <span className="resp-text--1"> Vault Address: </span>
              <span className="text-acc0 xs:text-[7px] lg:text-[0.8rem] whitespace-break-spaces">
                <span className="xs:hidden lg:block">{vault}</span>
                <span className="xs:block lg:hidden">
                  0x...{vault.slice(0, 5)}
                </span>
              </span>
            </div>
          </div>
        </motion.div>

        {is_auvault === true && (
          <>
            <hr className="resp-my-2" />

            <motion.div
              variants={MoVariants.show_hide}
              animate={editmode == true ? "visible" : "hidden"}
            >
              <div className="fr-sc resp-gap-2 flex-wrap font-digi resp-text-1">
                <span className="resp-text--1"> Vault Address: </span>
                <span className="text-acc0 xs:text-[7px] lg:text-[0.8rem] whitespace-break-spaces">
                  <span className="xs:hidden lg:block">{vault}</span>
                  <span className="xs:block lg:hidden">
                    0x...{vault.slice(0, 5)}
                  </span>
                </span>
              </div>

              <div className="fr-cc resp-gap-2 resp-text--2">
                <span className="font-digi">Vault Name:</span>
                <InpText
                  {...{
                    id: "inp_vault_name",
                    // def_val: vdoc.vault_name,
                    setter: (v) => {},
                    inpprops: {
                      className: "xs:w-[10rem] lg:w-[20rem]",
                    },
                  }}
                />
              </div>
            </motion.div>

            <div className="fr-sc resp-gap-2 w-full">
              <div className="flex-1"></div>

              {editmode && (
                <>
                  {nils(newvname) ? null : qovalid.isLoading ? (
                    <FontAwesomeIcon
                      icon={faRefresh}
                      className="rep-text-1 spin-anim text-acc4"
                    />
                  ) : namevalid == true ? (
                    <Tag
                      onClick={apply_updprofile}
                      className={twMerge(
                        "fr-cc resp-gap-2",
                        editing
                          ? "bg-dark text-acc0"
                          : "bg-green-300 text-black",
                      )}
                    >
                      {editing && <Loader01c size="s" />}
                      <span>Change Name</span>
                    </Tag>
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="rep-text-1 text-red-400"
                      />
                      <span className="resp-text--2 text-red-300">
                        name already in use
                      </span>
                    </>
                  )}
                </>
              )}
              <Tag
                onClick={() => {
                  set_editmode(!editmode);
                }}
                className={twMerge(
                  "text-black",
                  editmode ? "bg-red-300" : "bg-yellow-300",
                )}
              >
                {editmode ? "Discard" : "Edit Vault Detais"}
              </Tag>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

const VaultInfoSection = () => {
  const vcon = useVaultContext();
  const { is_auvault, vdoc } = vcon;

  const aucon = useAccountContext();
  const { cprofile } = aucon;

  const [editcprofile, set_editcprofile] = useState(false);

  return (
    <>
      {_.isEmpty(vdoc) ? (
        <Loader01c />
      ) : (
        <>
          <div class="grid grid-cols-12 my-2 resp-gap-2 xs:w-[95%] lg:w-[70rem] max-w-[95%] mx-auto overflow-auto">
            <VaultProfile />
            <VaultName />
            <VaultBanner />
          </div>

          {is_auvault === true && (
            <>
              <div class="my-2 resp-gap-2 xs:w-[95%] lg:w-[70rem] max-w-[95%] mx-auto overflow-auto">
                <div class="fr-sc w-full">
                  <div class="flex-1"></div>

                  {editcprofile === false && (
                    <div class="w-max">
                      <CProfileColorTag
                        {...{
                          cprofile: cprofile,
                          onClick: () => {
                            set_editcprofile(true);
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {editcprofile === true && (
                <div class="grid grid-cols-12 my-2 resp-gap-2 xs:w-[95%] lg:w-[70rem] max-w-[95%] mx-auto overflow-auto">
                  <VaultPageCustomization
                    {...{
                      editcprofile,
                      set_editcprofile,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const FiltSection = () => {
  const vcon = useVaultContext();
  const { filt, set_filt, clear_filt, tab, rvmode, set_rvmode, cprofile } =
    vcon;

  const inpargs = {
    fkey: "vaultfilt",
    filters: filt,
    set_filters: set_filt,
  };

  const c4 = get_cprofile_hex("acc4");
  const dark = get_cprofile_hex("r2dark");

  return (
    <Card
      className={twMerge("bg-r2lig/20 backdrop-blur-md max-w-[95vw] w-[50rem]")}
    >
      <div class="fr-cc my-2 resp-gap-2">
        {rvmode_s.map((rv) => {
          let active = rvmode == rv;
          return (
            <div
              onClick={() => {
                set_rvmode(rv);
              }}
              style={
                active
                  ? {
                      transition: "background 0.5s",
                    }
                  : {}
              }
              className={twMerge(
                "cursor-pointer resp-p-2 h-full rounded-md",
                active ? "bg-acc4 shadow-acc0 shadow-md" : "bg-r2dark/20",
              )}
            >
              <RVImg
                {...{ rvmode: rv }}
                hex_code={!active ? c4 : "#FFFFFF"}
                className={"xs:w-[4rem] lg:w-[8rem]"}
              />
            </div>
          );
        })}
      </div>

      <div className="grid xs:grid-cols-1 lg:grid-cols-1 ">
        <div className="mx-auto my-2 fr-sc w-full flex-wrap max-w-full resp-gap-2">
          <InpAutoWrap {...{ ...inpargs, idd: "breed_now" }} />
          <InpAutoWrap {...{ ...inpargs, idd: "in_arena" }} />
          <InpAutoWrap {...{ ...inpargs, idd: "is_maiden" }} />
          <div class="flex-1"></div>
          <div
            onClick={clear_filt}
            className="resp-text--3 resp-p-1 resp-px-4 rounded-full bg-red-400 cursor-pointer"
          >
            clear filters
          </div>
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "type" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "element" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "gender" }} />
        </div>
        {tab == "bikestats" && (
          <div className="w-max mx-auto fr-cc wrap max-w-full">
            <InpAutoWrap {...{ ...inpargs, idd: "cb" }} />
          </div>
        )}
      </div>
      <div className="fr-sc"></div>
    </Card>
  );
};
const BikeCountsRow = () => {
  const vcon = useVaultContext();
  const { qo_vaultbikes, hids, filthids, bikesob } = vcon;

  return (
    <>
      {qo_vaultbikes.isLoading == false && (
        <>
          <div className="fr-sc items-center max-w-[95vw] w-[50rem] mx-auto">
            {_.keys(bikesob).length !== hids.length ? (
              <>
                <Loader01c size="s" />
                <p className="text-acc0 resp-text--1 resp-px-2">
                  {`Loaded ${_.keys(bikesob).length} / ${hids.length} Bikes`}
                </p>
              </>
            ) : (
              ""
            )}
            <div className="flex-1"></div>

            {filthids.length != hids.length && (
              <p className="text-yellow-300 resp-text--1 resp-px-2">
                {`Filtered ${filthids.length} / ${hids.length} Bikes`}
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
};

const BikeCardsView = () => {
  const accon = useAccountContext();
  const { g_acc_config, s_acc_config, refresh_bikeslist } = accon;

  const vcon = useVaultContext();
  const { hids, filthids, bikesob, is_auvault, rvmode, set_rvmode, cprofile } =
    vcon;
  const [refreshing, set_refreshing] = useState(false);

  const refresh = async () => {
    set_refreshing(true);
    await refresh_bikeslist();
    await cdelay(2 * 1e3);
    set_refreshing(false);
  };

  const trainers_hids = useMemo(() => {
    return _.chain(filthids)
      .filter((hid) => {
        return getv(bikesob, `${hid}.type`) == "trainer";
      })
      .value();
  }, [jstr(bikesob), filthids]);

  useEffect(() => {
    s_acc_config("vaultrvmode.basefilt.rvmode", rvmode);
  }, [rvmode]);

  if (_.isEmpty(hids))
    return (
      <>
        <div class="fc-cc font-digi resp-text-1 text-center">
          <p>Seems like you don't have any bikes yet</p>
          <Link to="/claim-trainer">
            <Tag className="bg-acc4/60 -skew-x-12">
              <span className="resp-text-1">Claim a Free Trainer Bike Now</span>
            </Tag>
          </Link>
          <div class="fr-cc w-full resp-gap-2">
            <div class="bg-white w-full h-[0.1rem] my-[2rem]"></div>
            <p>OR</p>
            <div class="bg-white w-full h-[0.1rem] my-[2rem]"></div>
          </div>
          <p>I'm sure i own bikes.....</p>
          <p>Allow us to refresh our list</p>

          <Tag
            onClick={() => {
              if (refreshing) return;
              refresh();
            }}
            className="fr-sc resp-gap-2 border border-acc4 text-acc4 bg-r2dark/40  -skew-x-12"
          >
            {refreshing ? (
              <>
                <Loader01c size="s" />
                <span className="resp-text-1">Refreshing.....</span>
              </>
            ) : (
              <span className="resp-text-1">Refresh My Bikes List</span>
            )}
          </Tag>
        </div>
      </>
    );

  return (
    <>
      <BikeCountsRow />
      <FiltSection />

      <div class="">
        {filthids.map((hid, idx) => {
          let b = getv(bikesob, `${hid}`);
          let ext_class = ``;
          let ext_style = {
            // background: `${cprofile?.acc ?? "#09D3FF"}30`,
            // transition: "background 0.5s",
          };
          if (b.type == "trainer")
            ext_class = `trainer-${trainers_hids.indexOf(hid)}`;
          if (_.isEmpty(b)) return;
          return (
            <BikeCard
              key={hid}
              bike={b}
              {...{
                rvmode,
                mybike: is_auvault,
                ext_class,
                ext_style,
              }}
            />
          );
        })}
      </div>
    </>
  );
};

const MarketView = () => {
  const vcon = useVaultContext();
  const { hids, hsob } = vcon;
  const filthids = useMemo(() => {
    let ar = [];
    for (let hid of hids) {
      let h = getv(hsob, `${hid}`);
      if (nils(h)) continue;
      let dnaamt = getv(h, "price.dna.amt");
      let osamt = getv(h, "price.os.amt");
      let dnabid = getv(h, "price.dnahbid.amt");
      if (!nils(dnaamt) || !nils(osamt) || !nils(dnabid)) ar.push(hid);
    }
    return ar;
  }, [jstr(hids), jstr(hsob)]);

  const [sorts, set_sorts] = useState([]);
  const sort_ob = {
    hid: { disp: "hid", fn: (i1) => sort_fn(i1, "hid", "n") },
    name: { disp: "Name", fn: (i1) => sort_fn(i1, "name", "txt") },
    price_dna: {
      disp: "Price DNA",
      fn: (i1) => sort_fn(i1, "price.dna.amt", "n"),
    },
    price_os: {
      disp: "Price OS",
      fn: (i1) => sort_fn(i1, "price.os.amt", "n"),
    },
    price_dnahbid: {
      disp: "Highest Bid",
      fn: (i1) => sort_fn(i1, "price.dnahbid.amt", "n"),
    },
  };
  const headcn = "text-acc0 font-digi";
  const par_ar = { sorts, set_sorts, sort_ob };

  const sorted_hids = useMemo(() => {
    if (_.isEmpty(filthids)) return [];

    let ar = sort_listob({
      list: filthids.map((hid) => hsob[hid]),
      sorts,
      sort_ob,
      id: "hid",
    });
    return ar;
  }, [jstr(hsob), jstr(sorts), jstr(sort_ob), jstr(filthids)]);

  if (_.isEmpty(sorted_hids)) {
    return <p class="text-yellow-400 text-center">No Market Activity found</p>;
  }
  return (
    <div>
      <table className={twMerge(tablecn.table, " resp-text--2 w-max mx-auto")}>
        <thead>
          <tr className="thintdrowp4">
            <th>
              <SortHead {...{ ...par_ar, k: "hid", className: headcn }} />
            </th>
            <th>
              <SortHead {...{ ...par_ar, k: "name", className: headcn }} />
            </th>
            <th>
              <SortHead {...{ ...par_ar, k: "price_dna", className: headcn }} />
            </th>
            <th>
              <SortHead {...{ ...par_ar, k: "price_os", className: headcn }} />
            </th>
            <th>
              <SortHead
                {...{ ...par_ar, k: "price_dnahbid", className: headcn }}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {sorted_hids.map((hid) => {
            let h = getv(hsob, `${hid}`) || {};
            return (
              <tr className="thintdrowp4" key={hid}>
                <td>
                  <span>{hid}</span>
                </td>
                <td>
                  <span>{h.name}</span>
                </td>

                <td className="">
                  {nils(getv(h, "price.dna")) ? (
                    <span className="slate-400">--</span>
                  ) : (
                    <Tag
                      redirect={core_market_link(hid)}
                      className="fr-sc resp-gap-1 text-acc0 border border-acc0"
                    >
                      <TokenIcon
                        size="xxs"
                        token={getv(h, "price.dna.token")}
                      />
                      <span>
                        {dec(
                          getv(h, "price.dna.amt") ?? 0,
                          tokdecn2(getv(h, "price.dna.token")),
                        )}
                      </span>
                    </Tag>
                  )}
                </td>

                <td>
                  {nils(getv(h, "price.os")) ? (
                    <span className="slate-400">--</span>
                  ) : (
                    <Tag
                      redirect={core_os_link(hid)}
                      className="fr-sc resp-gap-1 text-purple-400 border border-purple-400"
                    >
                      <TokenIcon size="xxs" token={getv(h, "price.os.token")} />
                      <span>
                        {dec(
                          getv(h, "price.os.amt") ?? 0,
                          tokdecn2(getv(h, "price.os.token")),
                        )}
                      </span>
                    </Tag>
                  )}
                </td>

                <td>
                  {!nils(getv(h, "price.dnahbid")) ? (
                    <Tag
                      redirect={core_os_link(hid)}
                      className="fr-sc resp-gap-1 text-acc0 border border-acc0"
                    >
                      <TokenIcon
                        size="xxs"
                        token={getv(h, "price.dnahbid.token")}
                      />
                      <span>
                        {dec(
                          getv(h, "price.dnahbid.amt") ?? 0,
                          tokdecn2(getv(h, "price.dnahbid.amt")),
                        )}
                      </span>
                      <span>by {getv(h, "price.dnahbid.bidder_name")}</span>
                    </Tag>
                  ) : (
                    <span className="text-slate-400">--</span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const SkinsView = () => {
  const { vault } = useVaultContext();

  const [qo_vaultskins] = useQueries([
    q_vaultskins({ vault }, { enabled: !nils(vault) }),
  ]);
  const srvs = useMemo(() => {
    let vaultbikes = getv(qo_vaultskins, "data.result") ?? [];
    return vaultbikes;
  }, [qo_vaultskins.dataUpdatedAt]);
  const qst_sdocs = useStepQuery({
    q_: q_skininfo,
    par_ar: srvs.map(({ skinid, rvmode }) => [{ skinid, rvmode }]),
    lim: 5,
  });
  const skinssob = useMemo(() => {
    let b = {};
    for (let q of qst_sdocs.qs) {
      let d = getv(q, "data.result");
      if (nils(d)) continue;
      let k = `${d.skinid}||${d.rvmode}`;
      d.k = k;
      d.k2 = `${d.skin}||${d.rvmode}`;
      b[k] = d;
    }
    return b;
  }, [jstr(_.map(qst_sdocs.qs, "dataUpdatedAt"))]);
  const groupedskins = useMemo(() => {
    let o = _.chain(skinssob).values().groupBy("k2").value();
    return o;
  }, [jstr(skinssob)]);
  return (
    <div>
      <p className="text-white text-center font-digi resp-text-2 my-2">Skins</p>
      {qo_vaultskins.isLoading ? (
        <Loader01c />
      ) : _.isEmpty(srvs) ? (
        <div class="fc-cc resp-gap-2 resp-text-2">
          <p className="">No Skins found</p>
          <Link to={`https://www.dnaracing.run/skinmint?selboxtype=base`}>
            <Tag className={twMerge("bg-acc4/40 -skew-x-12")}>
              <span class="resp-text-1">
                <>Buy a Skin Now</>
              </span>
            </Tag>
          </Link>
        </div>
      ) : (
        <div className="grid lg:grid-cols-4 xs:grid-cols-2 gap-2 w-full max-w-[98vw]">
          {_.entries(groupedskins).map(([k2, skins]) => {
            let [skinname, rvmode] = k2.split("||");
            let s0 = {
              skin: skinname,
              rarity: skins[0].rarity,
            };
            let active = false;
            let n = skins.length;
            return (
              <>
                <div
                  onClick={() => {}}
                  className={twMerge(
                    "col-span-1 relative",
                    active ? "border border-acc0" : "",
                  )}
                >
                  <div class="absolute z-[10] top-[5px] right-[5px]">
                    <div class="fc-cc w-[2rem] bg-transparent rounded-full aspect-[1/1] font-digi resp-text-1">
                      <span>x{n}</span>
                    </div>
                  </div>
                  <SkinCard
                    rvmode={rvmode}
                    skin={skinname}
                    rarity={skins[0].rarity}
                  />

                  <div class="absolute z-[10] bottom-[5px] left-[5px]">
                    <span class="font-digi resp-text-0">{skinname}</span>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Vault = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const aucon = useAuthContext();
  const { vault } = useParams();

  const accon = useAccountContext();
  const {
    g_acc_config,
    s_acc_config,
    refresh_bikeslist,
    cprofile,
    set_cprofile,
  } = accon;

  const [qo_vaultinfo, qo_vaultbikes] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
    q_vaultbikesinf({ vault }, { enabled: !nils(vault) }),
  ]);
  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);
  const refresh_vaultdata = async () => {
    try {
      await q_vaultinfo_cc({ vault }).queryFn();
      await cdelay(1.5 * 1e3);
      await qo_vaultinfo.refetch();
    } catch (err) {}
  };
  const [hids, base_bikesob] = useMemo(() => {
    let hs = getv(qo_vaultbikes, "data.result") ?? [];
    let hids = _.map(hs, (e) => e.hid);
    let hsob = _.keyBy(hs, "hid");
    return [hids, hsob];
  }, [qo_vaultbikes.dataUpdatedAt]);

  // const qst_bdocs = useStepQuery({
  //   q_: q_bikeinfo_cac,
  //   par_ar: hids.map((hid) => [{ hid }, { enabled: true }]),
  //   lim: 2,
  // });
  const qst_bdocs = useCoresFullData(hids);
  const bikesob = useMemo(() => {
    let b = {};
    for (let q of qst_bdocs.qs) {
      let d = getv(q, "data.result");
      if (nils(d)) continue;
      b[d.hid] = d;
    }
    for (let hid of hids) {
      if (b[hid]) continue;
      else b[hid] = base_bikesob[hid];
    }

    return b;
  }, [
    jstr(_.map(qst_bdocs.qs, "dataUpdatedAt")),
    jstr(base_bikesob),
    jstr(hids),
  ]);

  const [editmode, set_editmode] = useState(false);

  const is_auvault = useMemo(() => {
    // return true;
    let a = aucon?.vault?.toLowerCase();
    let b = vault?.toLowerCase();
    return !nils(a) && a === b;
  }, [aucon.vault, vault]);

  const basefilt = useMemo(() => {
    let f = psearch.f;
    f = base64_to_json(f);
    f = f ?? {};
    if (_.isEmpty(f)) {
      let cac_f = g_acc_config("vaultbfilt.basefilt", {});
      f = cac_f;
    }
    return f;
  }, [psearch]);
  const [filt, set_filt] = useState({
    cb: {
      type: "options-only",
      path: "cb",
      cfilter: false,
      options: [
        "all",
        ..._.sortBy(
          [
            ...[9, 11, 13, 16, 19, 21, 23],
            ...[10, 12, 14, 18, 20, 22],
            ...[15, 17],
          ],
          (o) => o,
        ),
      ],
      vals: basefilt?.cb ?? "all",
      txt_fn: (o) => {
        return o == "all" ? "All" : `CB${o}00`;
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return `bg-dark ${elementmap[o]?.text}`;
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        return `shadow shadow-white transform text-white bg-acc0/40 -skew-x-12`;
      },
    },

    type: {
      type: "options",
      path: "type",
      cfilter: true,
      options: ["genesis", "morphed", "freak", "xclass"],
      vals: basefilt?.type,
      txt_fn: (o) => {
        return _.upperCase(_.kebabCase(o));
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/40";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    element: {
      type: "options",
      path: "element",
      cfilter: true,
      options: ["metal", "fire", "earth", "water"],
      vals: basefilt?.element,
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={elementmap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        return `shadow shadow-white transform text-white ${elementmap[op]?.bg} -skew-x-12`;
      },
    },
    gender: {
      type: "options-only-ar",
      path: "gender",
      cfilter: true,
      label: "gender",
      show_label: false,
      options: _.keys(gendermap),
      filter_exception: [],
      vals: _.compact(basefilt?.gender),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={gendermap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        return `shadow shadow-white transform text-white ${gendermap[op]?.bg} -skew-x-12`;
      },
    },
    breed_now: {
      type: "switch",
      path: "gender",
      cfilter: true,
      label: "Can Breed Now?",
      show_label: true,
      vals: basefilt?.breed_now ?? null,
      path: (h, f, filters) => {
        let fm = getv(filters, "breed_now.vals") ?? false;
        if (fm == true) {
          let b = getv(h, "splice_core") ?? 0;
          if (h.type !== "genesis") {
            let bday = getv(h, "mint.date");
            let diff = moment().diff(moment(bday), "days");
            if (diff < 30) {
              return false;
              // `New Born! Eligible to enter arena after ${30 - diff} days`;
            }
          }

          if (b.life_splices_n >= b.mxlife_splices_n) {
            return false;
            // `Max Life Splices Reached`;
          }

          if (b.cycle_splices_n >= b.mxcycle_splices_n) {
            return false;
            // `Max Splices Limit Reached for Cycle `;
          }
          return true;
        }
        return true;
      },
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
      active_cn: (active, op) => {},
    },
    is_maiden: {
      type: "switch",
      path: "is_maiden",
      cfilter: true,
      label: "Is Maiden?",
      show_label: true,
      vals: basefilt?.is_me ?? null,
      path: (h, f, filters) => {
        let fm = getv(filters, "is_maiden.vals") ?? false;
        if (fm == true) {
          let is_maiden = getv(h, "is_maiden");
          return is_maiden === true;
        }
        return true;
      },
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
      active_cn: (active, op) => {},
    },
    in_arena: {
      type: "btngroup",
      path: "gender",
      cfilter: true,
      label: "In Arena?",
      show_label: true,
      vals: basefilt?.in_arena ?? "all",
      path: (h, f, filters) => {
        let fm = getv(filters, "in_arena.vals") ?? "all";
        if (fm === "all") return true;
        // console.log("inarena", { fm });

        let b = getv(h, "splice_core") ?? 0;
        let instud = b.in_stud ?? false;
        return instud === fm;
      },
      options: [true, false, "all"],
      txt_fn: (o) => {
        if (o === true) return "ON";
        else if (o === false) return "OFF";
        else return "All";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
      active_cn: (active, op) => {},
    },
  });
  const valfilt = useMemo(() => {
    let valfilt = gen_valob_from_filters(filt);
    s_acc_config("vaultbfilt.basefilt", valfilt);
    return valfilt;
  }, [filt]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const [tab, set_tab] = useState(psearch.tab ?? "bikecards");
  const rem = useMemo(() => {
    let rem = {};
    rem.f = json_to_base64(valfilt);
    rem.tab = tab;
    upd_psearch(rem);
    return rem;
  }, [jstr(valfilt), tab]);

  const filthids = useMemo(() => {
    // if (nils(valfilt)) return hids;
    // else {
    let nhids = filt_ar_using_filters({ ar: _.map(bikesob), filters: filt });
    nhids = _.map(nhids, "hid");
    nhids = _.sortBy(nhids, (hid) => hids.indexOf(hid));
    // console.log("nhids", nhids);
    return nhids;
    // }
  }, [filt, jstr(hids), jstr(bikesob)]);
  /* useEffect(() => {
    console.log("filthids", filthids);
  }, [filthids]); */

  const [sorts, set_sorts] = useState([]);
  const [rvmode, set_rvmode] = useState(
    g_acc_config("vaultrvmode.basefilt.rvmode", "bike"),
  );

  const cb = getv(filt, "cb.vals") ?? "all";
  const qshscs = useStepQuery({
    q_: q_hstats_doc,
    par_ar: hids.map((hid) => [{ hid }]),
    lim: 5,
    options: { enabled: tab == "bikestats" },
  });
  const hstatsob = useMemo(() => {
    let o = {};
    for (let q of qshscs.qs) {
      if (!qissuccesss(q)) continue;
      let hid = getv(q, "data.result.hid");
      let s = getv(q, "data.result.data");
      o[hid] = s;
    }
    return o;
  }, [jstr(qshscs.qs.map((q) => q.dataUpdatedAt))]);

  const qshps = useStepQuery({
    q_: q_price,
    par_ar: hids.map((hid) => [{ hid }]),
    lim: 5,
    options: { enabled: true },
  });
  const hpriceob = useMemo(() => {
    let o = {};
    for (let q of qshps.qs) {
      if (!qissuccesss(q)) continue;
      let hid = getv(q, "data.result.hid");
      let s = getv(q, "data.result");
      o[hid] = s;
    }
    return o;
  }, [jstr(qshps.qs.map((q) => q.dataUpdatedAt))]);

  const hsob = useMemo(() => {
    let o = {};
    hids.map((hid) => {
      o[hid] = _.cloneDeep(bikesob[hid]) || {};
      if (!_.isEmpty(o[hid])) {
        let s = getv(hstatsob, `${hid}.${cb == "all" ? "career" : cb}`) || {};
        let p = getv(hpriceob, `${hid}`);
        if (s) o[hid].s = s;
        if (p) o[hid].price = p;
      }
    });
    return o;
  }, [jstr(bikesob), jstr(hstatsob), jstr(hpriceob), cb]);

  const sort_ob = {
    hid: { disp: "hid", fn: (i1) => sort_fn(i1, "hid", "n") },
    name: { disp: "Name", fn: (i1) => sort_fn(i1, "name", "txt") },
    element: {
      disp: "El",
      fn: (i1) => sort_fn(i1, "element", "txt"),
    },
    type: { disp: "Ty", fn: (i1) => sort_fn(i1, "type", "txt") },
    gender: { disp: "G", fn: (i1) => sort_fn(i1, "gender", "txt") },
    fno: { disp: "F", fn: (i1) => sort_fn(i1, "fno", "n") },

    races_n: { disp: "#Race", fn: (i1) => sort_fn(i1, "s.races_n", "n") },
    win_n: { disp: "w", fn: (i1) => sort_fn(i1, "s.p2_n", "n") },
    p2_n: { disp: "2", fn: (i1) => sort_fn(i1, "s.p2_n", "n") },
    p3_n: { disp: "3", fn: (i1) => sort_fn(i1, "s.p3_n", "n") },
    win_p: { disp: "Win%", fn: (i1) => sort_fn(i1, "s.win_p", "n") },

    paid_races_n: {
      disp: "#Race",
      fn: (i1) => sort_fn(i1, "s.paid_races_n", "n"),
    },
    paid_win_n: { disp: "w", fn: (i1) => sort_fn(i1, "s.paid_p2_n", "n") },
    paid_p2_n: { disp: "2", fn: (i1) => sort_fn(i1, "s.paid_p2_n", "n") },
    paid_p3_n: { disp: "3", fn: (i1) => sort_fn(i1, "s.paid_p3_n", "n") },
    paid_win_p: { disp: "Win%", fn: (i1) => sort_fn(i1, "s.paid_win_p", "n") },
    profit_weth: {
      disp: "Profit WETH",
      fn: (i1) => sort_fn(i1, "s.profit", "n"),
    },
    profit_dez: {
      disp: "Profit DEZ",
      fn: (i1) => sort_fn(i1, "s.profit_dez", "n"),
    },

    price_dna: {
      disp: "Price DNA",
      fn: (i1) => sort_fn(i1, "price.dna.amt", "n"),
    },
    price_os: {
      disp: "Price OS",
      fn: (i1) => sort_fn(i1, "price.os.amt", "n"),
    },

    bluestar_p: {
      disp: (
        <div className="fr-sc text-blue-400">
          <FontAwesomeIcon icon={faStar} />
          <span>%</span>
        </div>
      ),
      fn: (i1) => sort_fn(i1, "s.bluestar_p", "n"),
    },
    yellowstar_p: {
      disp: (
        <div className="fr-sc text-yellow-400">
          <FontAwesomeIcon icon={faStar} />
          <span>%</span>
        </div>
      ),
      fn: (i1) => sort_fn(i1, "s.yellowstar_p", "n"),
    },

    // dna_price: { disp: "DNA Price", fn: (i1) => sort_fn(i1, "dna.amt", "n") },
    // os_price: { disp: "OS Price", fn: (i1) => sort_fn(i1, "os.amt", "n") },
  };
  const sorted_hids = useMemo(() => {
    return filthids;
    // if (_.isEmpty(filthids)) return [];
    //
    // let ar = sort_listob({
    //   list: filthids.map((hid) => hsob[hid]),
    //   sorts,
    //   sort_ob,
    //   id: "hid",
    // });
    // return ar;
  }, [jstr(hsob), jstr(sorts), jstr(sort_ob), jstr(filthids)]);

  const vcon = {
    vault,
    is_auvault,
    vdoc,

    qo_vaultinfo,
    qo_vaultbikes,

    cprofile,
    set_cprofile,

    editmode,
    set_editmode,

    qst_bdocs,
    bikesob,

    filt,
    set_filt,

    clear_filt,

    filthids,
    hids,
    filthids,
    hsob,
    sorted_hids,

    sorts,
    set_sorts,
    sort_ob,

    tab,
    set_tab,

    rvmode,
    set_rvmode,
    refresh_vaultdata,
  };

  const pagetitle = useMemo(() => {
    if (nils(vdoc)) return `Vault ${vault} | FBike DNA`;
    let rn = vdoc.vault_name;
    return `'${rn}' Vault | FBike DNA`;
  }, [vdoc]);

  return (
    <div className="">
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className={twMerge("h-page")}>
        <div className="max-w-[98vw] w-[80rem] mx-auto">
          <div className="h-[5rem]"></div>
          <VaultContext.Provider value={vcon}>
            <VaultInfoSection />
            <div class="h-[5rem]"></div>
            <div class="lg:flex fr-cc xs:grid xs:grid-cols-2 gap-2 my-2">
              {[
                ["/my-vault-3d", "3D"],
                ["https://www.dnaracing.run/bikeburn?tab=elig", "Core Burn"],
                [`/staking/${vault}`, "Staking"],
                [`/racing-dash/${vault}`, "Racing"],
              ].map(([to, txt]) => {
                return (
                  <Link to={to}>
                    <SkewBtn
                      cont_cn="xs:w-[10rem] lg:w-[15rem]"
                      key={txt}
                      text={
                        <span className="font-digi resp-text-2">{txt}</span>
                      }
                    />
                  </Link>
                );
              })}
            </div>

            <GlowBtnGroup
              {...{
                options: [
                  "splice_ledger",
                  "skins",
                  "bikecards",
                  "market",
                  "trophies",
                ],
                selected: tab,
                opmap: _.chain([
                  ["bikecards", "Cores", "bike"],
                  ["splice_ledger", "Splice Ledger", "result"],
                  ["skins", "Skins", "bike"],
                  ["market", "Market", "market"],
                  ["trophies", "Trophies", "trophies"],
                ])
                  .map(([c, text, svg_k]) => {
                    return [c, { text, svg: svg_map[svg_k] }];
                  })
                  .fromPairs()
                  .value(),
                setter: set_tab,
              }}
            />
            <div class="fr-sc my-2 mx-auto max-w-[95vw] w-[50rem]">
              <div class="flex-1"></div>
              <Tag
                onClick={refresh_bikeslist}
                className="fr-sc resp-gap-1 text-acc4 bg-acc4/20 border border-acc4"
              >
                <FontAwesomeIcon icon={faRefresh} />
                <span>Refresh</span>
              </Tag>
            </div>

            {tab == "bikecards" && <BikeCardsView />}
            {tab == "market" && <MarketView />}
            {tab == "trophies" && <TrophiesTab vault={vault} />}
            {tab == "splice_ledger" && <SpliceActivityInner vault={vault} />}
            {tab == "skins" && <SkinsView />}

            <div class="h-[5rem]"></div>
          </VaultContext.Provider>
        </div>
      </div>
    </div>
  );
};

export const VaultBackLink = () => {
  const aucon = useAuthContext();
  const { vault } = aucon;

  return (
    <div class="fr-sc my-2 w-full">
      <Link to={`/vault/${vault}`}>
        <Tag className="text-acc4 -skew-x-12 border border-acc4 bg-r2lig/20">
          <div class="fr-sc resp-gap-2 resp-text-0">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span class="">Back to My Vault</span>
          </div>
        </Tag>
      </Link>
    </div>
  );
};

export default Vault;
