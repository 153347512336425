import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
} from "react";
import { twMerge } from "tailwind-merge";
import { Card, InpText, Tag, tokenimgmap } from "../components/utilityComps";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso,
  json_to_base64,
  jstr,
  nils,
  toeth,
  tofeth,
  trim2,
  trim_n,
} from "../utils/utils";
import _ from "lodash";
import { useQueries } from "react-query";
import {
  polytxnidlink,
  q_bikename_validate,
  q_que_txn,
  q_quick_splicelist,
  q_splicing_v02_splicedoc,
  qiserr,
  qissuccesss,
} from "../queries/queries";
import {
  faArrowLeftLong,
  faArrowRight,
  faArrowRightLong,
  faCheckCircle,
  faCircle,
  faCopy,
  faInfoCircle,
  faRefresh,
  faRightLong,
  faSpinner,
  faTimesCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import {
  CopyBtn,
  ElementTag,
  GenderTag,
  MiniElementTag,
  MiniGenderTag,
} from "../components/ShortComps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  elementmap,
  gendermap,
  get_cprofile_hex,
  tablecn,
} from "../utils/cn_map";
import { SkewWrap2 } from "../utils/raceutils2";
import { RVImg } from "../components/BikeImg";
import { Loader01c } from "../components/anims";
import { tokdecn, useAppContext } from "../App";
import Vault from "./Vault";
import { useAuthContext } from "../wrappers/AuthWrapper";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs";
import Splicing_V02 from "../contracts/Splicing_V02/Splicing_v02";
import { set_state_ob } from "../components/input";
import { polytokens, useThirdWebLoginContext } from "./ThirdWebLogin";
import { polygon } from "thirdweb/chains";
import moment from "moment";
import { useNavigate } from "react-router";
import { ConnectButton, PayEmbed } from "thirdweb/react";
import { contractAddress_list } from "../contracts/constants";

const SpliceContext = createContext({});
const useSpliceContext = () => useContext(SpliceContext);

const SplicingContract = Splicing_V02;

const InsufficientBalance = ({ costusd, cost, token, recheck_from_start }) => {
  const { vault, aumode } = useAuthContext();
  const { thirdweb_client, active_account, polytokens } =
    useThirdWebLoginContext();

  const t3_handle_buy = async () => {
    try {
    } catch (err) {
      console.log("t3_handle_buy err", err);
    }
  };

  const [show_payembed, set_show_payembed] = useState(false);
  const token_jsx = <span className="text-purple-400">{token}</span>;
  return (
    <>
      <div class="fc-cc resp-gap-2 mt-2 rounded-md border border-red-400 resp-text-0 resp-p-1">
        {aumode == "thirdweb" ? (
          <>
            <p>Logged in using ThiredWeb</p>
            <p>Transfer required {token_jsx} to your wallet</p>
            <div class="fr-cc resp-gap-1">
              <span className="font-mono">{trim2(vault, 6, 6)}</span>
              <CopyBtn txt={vault} />
            </div>
            <p>--OR--</p>
            <p
              onClick={() => {
                set_show_payembed((e) => !e);
              }}
              className="text-acc0 cursor-pointer"
            >
              Buy {token_jsx} with crypto/fiat
            </p>
            {show_payembed && (
              <PayEmbed
                client={thirdweb_client}
                payOptions={{
                  mode: "fund_wallet",
                  prefillBuy: {
                    chain: polygon,
                    token: polytokens[token],
                    amount: dec(cost, tokdecn(token)),
                    allowEdits: {
                      amount: true,
                      token: false,
                      chain: false,
                    },
                  },
                }}
                supportedTokens={{ 137: [polytokens[token]] }}
              />
            )}
          </>
        ) : (
          <>
            <p>Logged in using MetaMask</p>
            <p>Transfer some {token_jsx} to metamask wallet</p>
            <div class="fr-cc resp-gap-1">
              <span className="font-mono">{trim2(vault, 6, 6)}</span>
              <CopyBtn txt={vault} />
            </div>
          </>
        )}
      </div>
      <div class="mt-2 fr-sc px-4">
        <div class="flex-1"></div>
        <div
          onClick={() => {
            recheck_from_start();
          }}
          className={twMerge(
            "text-acc0 cursor-pointer font-digi underline underline-offset-2 resp-text-0 fr-sc resp-gap-1",
          )}
        >
          <span>Done your transfer?</span>
          <span></span>
          <FontAwesomeIcon icon={faRefresh} />
          <span>Refresh?</span>
        </div>
      </div>
    </>
  );
};

const Stage_1 = ({ stage }) => {
  const scon = useSpliceContext();
  const { data, set_data, get_active_cn, go_next, qolist, ranges, listmap } =
    scon;

  return (
    <div className="w-full">
      <p class="resp-text-2 font-digi text-left my-2 ">Select Price Range</p>

      <div class="w-full fr-cc flex-wrap gap-4">
        {!_.isEmpty(ranges) &&
          ranges.map((r) => {
            const [btn_cn, text_cn] = get_active_cn("range", r);
            return (
              <div
                onClick={() => {
                  set_data((o) => ({ ...o, range: r, stage: o.stage + 1 }));
                }}
                class={twMerge(
                  "cursor-pointer xs:w-[8rem] lg:w-[16rem]",
                  btn_cn,
                )}
              >
                <SkewWrap2>
                  <span
                    className={twMerge(
                      "resp-text-2 font-digi text-center",
                      text_cn,
                    )}
                  >
                    {`$${dec(r, 0)}`}
                  </span>
                  <div class="fr-sc w-full resp-px-4 resp-pb-2">
                    <div class="flex-1"></div>
                    <span className="resp-text--1">
                      {getv(listmap, `${r}`, []).length} Options
                    </span>
                  </div>
                </SkewWrap2>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const Stage_2 = ({ stage }) => {
  const scon = useSpliceContext();
  const { data, set_data, get_active_cn, go_next, qolist, ranges, listmap } =
    scon;

  const list = useMemo(() => {
    return listmap[data?.range];
  }, [jstr(listmap), data?.range]);

  const hmap = useMemo(() => {
    return getv(qolist, "data.result.hmap", {});
  }, [qolist.dataUpdatedAt]);

  const hinfo = (hid, cont_cn = "") => {
    let h = hmap[hid];
    let is_dad = h.gender == "male";
    let text_cn = is_dad ? "text-blue-400" : "text-pink-400";
    let bg_cn = is_dad ? "bg-blue-500/50" : "bg-pink-500/50";
    return (
      <div
        className={twMerge(
          "resp-px-2 flex flex-col justify-start items-start h-[5rem] resp-text-0 font-digi",
          cont_cn,
        )}
      >
        <p className={twMerge(text_cn, "resp-text--1")}>
          {is_dad ? "DAD" : "MOM"}#{hid}
        </p>
        <p>{trim_n(h.name, 15)}</p>
        <div class="fr-sc resp-gap-2 font-digi resp-text--2">
          <span>{_.upperCase(h.type.slice(0, 3))}</span>
          <span>F{h.fno}</span>
          <FontAwesomeIcon
            className={gendermap[h.gender].text}
            icon={gendermap[h.gender].icon}
          />
          <FontAwesomeIcon
            className={elementmap[h.element].text}
            icon={elementmap[h.element].icon}
          />
        </div>
      </div>
    );
  };

  const c0 = get_cprofile_hex("acc0");
  return (
    <div className="w-full h-full overflow-auto">
      <p class="resp-text-2 font-digi text-left my-2 ">Select a Parents Pair</p>

      <div class="w-full grid grid-cols-3 gap-4 ">
        {list.map((p) => {
          let { h_f_hid, h_m_hid } = p;
          let p_k = `${p.h_f_hid}-${p.h_m_hid}`;
          return (
            <Card
              key={p_k}
              onClick={() => {
                set_data((o) => {
                  return {
                    ...o,
                    pair: _.cloneDeep(p),
                    stage: o.stage + 1,
                  };
                });
              }}
              className={twMerge(
                "border border-acc0 bg-r2dark/60 resp-p-2 w-full h-full",
                "cursor-pointer",
              )}
            >
              <div class="grid w-full grid-cols-1">
                <div class="">{hinfo(p.h_f_hid, "")}</div>
                <div class="">{hinfo(p.h_m_hid, "items-end")}</div>
              </div>

              <div class="fr-sc resp-gap-2">
                <span>Good for </span>
                <span className="text-acc0 font-digi">
                  {_.upperCase(p.dprange)}
                </span>
                <div class="w-[2rem] aspect-[1/1]">
                  <RVImg rvmode={p.rvmode} hex_code={c0} />
                </div>
              </div>

              <div class="fr-sc resp-gap-2">
                <div class="flex-1"></div>
                <Tag className="font-digi  text-acc0 bg-acc0/40 fr-sc border border-acc0">
                  <span class="resp-text-1">{p.totcost} USD</span>
                </Tag>
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

const Stage_3 = ({ stage }) => {
  const scon = useSpliceContext();
  const { data, set_data, get_active_cn, go_next, qolist, ranges, listmap } =
    scon;

  const [name, set_name] = useState(data.name ?? "");

  const [qvalid] = useQueries([q_bikename_validate({ name })]);
  const valid = getv(qvalid, "data.result.valid") && name.length > 3;
  const [loading, set_loading] = useState(false);

  const set_name_fn = async () => {
    console.log("set_name_fn", name);
    set_data((o) => {
      return {
        ...o,
        splice_name: name,
        stage: o.stage + 1,
      };
    });
  };

  return (
    <div className="w-full h-full overflow-auto">
      <p class="resp-text-2 font-digi text-left my-2 ">
        Set a Name for your Splice
      </p>
      <div class="h-[4rem]"></div>
      <div className="fr-sc mx-auto w-max flex-wrap resp-gap-2 my-2">
        <InpText
          placeholder={"Enter Splice Name"}
          inpprops={{
            className: `resp-text--2 xs:w-[60vw] lg:w-[25rem] ${""} `,
          }}
          id={`inp_bikename:${"--"}`}
          setter={(v) => {
            set_name(v);
          }}
        />
        <div className="flex-1"></div>
        {qvalid.isLoading ? (
          <>
            <FontAwesomeIcon className="text-acc4 spin-anim" icon={faSpinner} />
          </>
        ) : valid ? (
          <>
            <FontAwesomeIcon className="text-green-400" icon={faCheckCircle} />
            {loading ? <Loader01c size="s" /> : null}
          </>
        ) : (
          <FontAwesomeIcon className="text-red-400" icon={faTimesCircle} />
        )}
      </div>
      <div class="fr-cc my-1">
        {!loading && valid && (
          <Tag
            onClick={() => {
              set_name_fn();
            }}
            className="bg-acc0/40 resp-text-0 -skew-x-12"
          >
            Set Name
          </Tag>
        )}
      </div>
    </div>
  );
};

const Stage_4 = ({ stage }) => {
  const aucon = useAuthContext();
  const { aumode } = aucon;
  const { thirdweb_client, active_account } = useThirdWebLoginContext();

  const appcon = useAppContext();
  const { tok_to_usd_val, usd_to_tok_val, tokmap, psearch, upd_psearch } =
    appcon;

  const scon = useSpliceContext();
  const {
    data,
    set_data,
    get_active_cn,
    go_next,
    qolist,
    ranges,
    listmap,
    vault,
  } = scon;

  const p = useMemo(() => {
    return getv(data, "pair");
  }, [jstr(data)]);
  const token = "WETH";
  const [costusd, cost] = useMemo(() => {
    let usd = getv(p, "totcost");
    let cost = usd_to_tok_val(usd, token);
    return [usd, cost];
  }, [jstr(p), jstr(tokmap)]);

  const init_step = 1;
  const init_resps = {
    0: [
      "info",
      `Cost: ${dec(costusd)} USD | ${dec(cost, tokdecn(token))} ${token}`,
    ],
  };

  const [step, set_step] = useState(init_step);
  const [c, set_c] = useState(0);
  const [resps, set_resps] = useState(init_resps);

  const sp_conaddr = useMemo(() => {
    return SplicingContract.get_contract_address();
  }, []);
  const get_balance = async () => {
    const con =
      aumode == "thirdweb"
        ? await t3_asset_signer(token)
        : await mm_asset_signer(token);
    let bal = await con.balanceOf(vault);
    bal = parseFloat(tofeth(bal));
    return bal;
  };
  const get_allowance = async () => {
    const con =
      aumode == "thirdweb"
        ? await t3_asset_signer(token)
        : await mm_asset_signer(token);
    let alw = await con.allowance(vault, sp_conaddr);
    alw = parseFloat(tofeth(alw));
    return alw;
  };
  const set_allowance = async (amt) => {
    const con =
      aumode == "thirdweb"
        ? await t3_asset_signer(token)
        : await mm_asset_signer(token);
    let toval = parseFloat(amt);
    toval = parseFloat(toval.toFixed(tokdecn(token)));
    if (aumode == "thirdweb") {
      const approval = await t3_contract_call(
        token,
        "approve",
        [sp_conaddr, toeth(dec(toval, tokdecn(token)))],
        "txn",
        true,
        { active_account: active_account },
      );
      console.log("approval", approval);
      await cdelay(5 * 1e3);
      return true;
    } else {
      let approval = await con.approve(
        sp_conaddr,
        toeth(dec(toval, tokdecn(token))),
      );
      approval = await approval.wait();
      console.log("approval", approval);
      await cdelay(5 * 1e3);
      return true;
    }
  };

  const service = "splicingv02";
  const gen_new_request = async ({
    vault,
    father_coreid,
    mother_coreid,
    encoded,
  }) => {
    const splicing =
      aumode == "thirdweb"
        ? await SplicingContract.get_contract({
            rpc: polygon.rpc,
            nosigner: true,
          })
        : await SplicingContract.get_contract({});
    let resp = null;

    // const weth = await WETH_MockToken.get_contract();

    // console.log("popdata", popdata);
    // console.log("request_splice:encoded", encoded);
    let encoded_b = json_to_base64(encoded);
    let args = [vault, father_coreid, mother_coreid, encoded_b];
    // console.log("request_splice:args", args);

    // throw new Error("not implemented");

    if (aumode == "thirdweb") {
      resp = await t3_contract_call(
        "splicingv02",
        "request_splice",
        args,
        "txn",
        true,
        { active_account: active_account },
      );
      await cdelay(2 * 1e3);
    } else {
      resp = await splicing.request_splice(...args);
      console.log("request_splice resp", resp);
    }

    if (!nils(resp?.hash)) {
      let qdoc = { hash: resp.hash, service, dets: {} };
      console.log("qdoc", qdoc);
      await q_que_txn(qdoc).queryFn();
    }

    console.log("splicing:resp", resp);

    await cdelay(2 * 1e3);
    let logs = await splicing.getParsedLogs(resp.logs);
    console.log("logs", logs);
    let reqid = null;
    for (let l of logs) {
      if (l.name == "SpliceRequested") {
        reqid = l.args?.reqid;
      }
    }
    if (nils(reqid))
      throw new Error(`Sorry couldnt Locate your requests,\n please retry`);
    console.log("reqid", reqid);
    return { reqid, hash: resp.hash, args };
  };

  const upd_resp = (k, v) => {
    set_state_ob(resps, set_resps, k, v);
  };
  const fn_map = {
    1: async ({ step }) => {
      let msg = `Checking Balance [Required: ${dec(cost, tokdecn(token))} ${token}]`;
      upd_resp(step, ["loading", msg]);
      let bal = await get_balance();
      if (bal < cost) {
        msg = "Insufficient Balance";
        upd_resp(step, ["error", msg]);
      } else {
        msg = "Sufficient Balance";
        upd_resp(step, ["success", msg]);
        return true;
      }
    },
    2: async ({ step }) => {
      let msg = `Checking Spend Allowance`;
      upd_resp(step, ["loading", msg]);
      let alw = await get_allowance();
      console.log("alw", alw);
      if (alw >= cost) {
        msg = "Spending Allowance is Enough";
        upd_resp(step, ["success", msg]);
        return true;
      } else {
        if (aumode == "thirdweb") msg = "Sending Approval Transaction";
        else msg = "Please Approve Spending Allowance";
        upd_resp(step, ["action", msg]);
        await set_allowance(cost + 0.001);
        msg = "Approval Sent";
        upd_resp(step, ["success", msg]);
        return true;
      }
    },
    3: async ({ step }) => {
      try {
        let msg = `Send Splice Request`;
        upd_resp(step, ["info", msg]);
        await cdelay(2 * 1e3);
        msg = `Sending Splice Request....`;
        upd_resp(step, ["loading", msg]);

        let params = {
          vault,
          father_coreid: getv(data, "pair.h_f_hid"),
          mother_coreid: getv(data, "pair.h_m_hid"),
          encoded: {
            splice_name: getv(data, "splice_name"),
            paytoken: token,
          },
        };
        console.log("request:params", params);
        // throw new Error("not implemented");

        let resp = await gen_new_request(params);
        let { reqid, hash, args } = resp;
        window.open(polytxnidlink(hash));

        upd_psearch({
          action: "create-splice",
          reqid,
          autopop: "open",
        });
        set_data((o) => {
          return { ...o, reqid, stage: o.stage + 1 };
        });
        await cdelay(1 * 1e3);
        // return "next";
      } catch (err) {
        console.log(err);
        let errmsg = !nils(err.reason) ? err.reason : err.message;
        if (errmsg.length > 100) errmsg = errmsg.substring(0, 100) + "...";
        let msg = `Error Sending Splice Request [${errmsg}]`;
        upd_resp(step, ["error", msg]);
      }
    },
  };

  useEffect(() => {
    let fn = fn_map[step];
    const run = async () => {
      if (!nils(fn)) {
        let done = await fn({ step });
        if (done == true) {
          setTimeout(() => {
            set_step(step + 1);
          }, 1 * 1e3);
        } else if (done == "next") {
          setTimeout(() => {
            go_next();
          }, 1 * 1e3);
        }
      }
    };
    run();
  }, [step, c]);

  const recheck_from_start = () => {
    set_resps(init_resps);
    set_step(init_step);
    set_c((i) => i + 1);
  };

  return (
    <>
      <div class="">
        <p class="resp-text-2 font-digi text-left my-2 "></p>
        <div class="h-[4rem]"></div>
        <div class="fc-ss w-max mx-auto resp-gap-1 min-w-[30rem]">
          {_.entries(resps).map(([step, [i, msg]], idx) => {
            return (
              <div key={step} class="w-full">
                <div class="fr-sc resp-gap-2 font-digi resp-text-0">
                  <div class="w-[2rem]">
                    {i == "info" ? (
                      <FontAwesomeIcon
                        className="text-blue-400 resp-text-2"
                        icon={faInfoCircle}
                      />
                    ) : i == "loading" ? (
                      <FontAwesomeIcon
                        className="text-blue-400 spin-anim resp-text-2"
                        icon={faSpinner}
                      />
                    ) : i == "action" ? (
                      <FontAwesomeIcon
                        className="text-yellow-400 resp-text-2"
                        icon={faTriangleExclamation}
                      />
                    ) : i == "success" ? (
                      <FontAwesomeIcon
                        className="text-green-400 resp-text-2"
                        icon={faCheckCircle}
                      />
                    ) : i == "error" ? (
                      <FontAwesomeIcon
                        className="text-red-400 resp-text-2"
                        icon={faTimesCircle}
                      />
                    ) : null}
                  </div>
                  <span>{msg}</span>
                </div>
                {i == "error" && msg == "Insufficient Balance" && (
                  <InsufficientBalance
                    {...{
                      costusd,
                      cost,
                      token,
                      recheck_from_start,
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const Stage_5 = ({ stage }) => {
  const scon = useSpliceContext();
  const { vault, data, set_data, post_mint_fn } = scon;

  const reqid = getv(data, "reqid");
  const [qosplicedoc] = useQueries([
    q_splicing_v02_splicedoc(
      { reqid },
      {
        staleTime: 10 * 1e3,
        refetchInterval: 10 * 1e3,
      },
    ),
  ]);
  const splicedoc = useMemo(() => {
    if (qissuccesss(qosplicedoc)) return getv(qosplicedoc, "data.result");
    return {};
  }, [qosplicedoc.dataUpdatedAt]);

  const mxwaittime = useMemo(() => {
    return moment().add(10, "minutes").toISOString();
    return null;
  }, [jstr(splicedoc)]);

  const hid = getv(splicedoc, "hid") ?? "";

  const history = useNavigate();

  useEffect(() => {
    if (qissuccesss(qosplicedoc)) {
      if (!nils(post_mint_fn)) {
        post_mint_fn({ hid, reqid });
      }
    }
  }, [jstr(qosplicedoc)]);

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Splicing Request
      </p>
      <p className="font-mon resp-text--1 text-center">{reqid}</p>
      <p className="my-2 text-center font-digi italic resp-text-1">
        Mint Splice<span className="text-acc0">#{hid}</span>
      </p>

      {!nils(reqid) && (
        <>
          {qosplicedoc.isLoading ||
          qiserr(qosplicedoc) ||
          splicedoc.minted != true ? (
            <>
              <p className="text-acc0 resp-text--2 text-center">
                Waiting for your Mint
              </p>
              <Loader01c />
            </>
          ) : qissuccesss(qosplicedoc) && splicedoc.minted === true ? (
            <>
              <p className="text-center text- my-2">
                Congratulations on brand new Splice
              </p>
              <div className="fc-cc my-2">
                <Tag
                  onClick={() => {
                    history(`/bike/${hid}`);
                  }}
                  className="bg-acc0/40 -skew-x-12"
                >
                  View Core
                </Tag>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="fr-sc resp-text--2">
            <div className="flex-1"></div>
            {!nils(mxwaittime) && (
              <div className="text-yellow-300 fc-ss resp-gap-1">
                <span>Est Waiting Time</span>
                <span>{from_time_mini(mxwaittime)}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const QuickSpliceCard = ({
  card_cn = "bg-r2lig/20 backdrop-blur-md border border-acc4",
  post_mint_fn = null,
}) => {
  const { psearch, upd_psearch } = useAppContext();
  const { vault } = useAuthContext();

  const [data, set_data] = useState(
    !nils(psearch.reqid)
      ? {
          stage: 5,
          reqid: psearch.reqid,
        }
      : {
          stage: 1,
        },
  );
  const [response, set_response] = useState({});

  const stage = data.stage;
  const mxstage = 5;

  const prevstage = () => {
    let n_s = Math.max(1, stage - 1);
    set_data({ ...data, stage: n_s });
  };
  const nextstage = () => {
    let n_s = Math.min(mxstage, stage + 1);
    set_data({ ...data, stage: n_s });
  };
  const go_prev = prevstage;
  const go_next = nextstage;

  const [next_allowed, set_next_allowed] = useState(false);
  useEffect(() => {
    set_next_allowed(false);
  }, [stage]);

  const validate_next = useMemo(() => {
    console.log("update", stage, data);
    if (stage == 1) {
      if (nils(data.range)) return false;
    } else if (stage == 2) {
      if (nils(data.pair)) return false;
    } else if (stage == 3) {
      if (nils(data.hid)) return false;
    } else if (stage == 4) {
      if (nils(data.quest_type)) return false;
    }
    if (stage >= mxstage) return false;
    return true;
  }, [stage, jstr(data)]);

  // 1: select cost range
  // 2: select parents pair
  // 3: show cost | give name | set allowance | send gen request
  // 4: waiting | response page

  const [qolist] = useQueries([
    q_quick_splicelist(
      {},
      {
        staleTime: 60 * 1e3,
        refetchInterval: 60 * 1e3,
      },
    ),
  ]);

  const [ranges, listmap] = useMemo(() => {
    let d = getv(qolist, "data.result", {
      ranges: [],
      listmap: {},
    });
    let filtranges = d.ranges.filter((r) => !_.isEmpty(d.listmap[r]));
    return [filtranges, d.listmap];
  }, [jstr(qolist.dataUpdatedAt)]);

  const StageComp = {
    1: Stage_1,
    2: Stage_2,
    3: Stage_3,
    4: Stage_4,
    5: Stage_5,
  };

  const get_active_cn = (k, op) => {
    const val = getv(data, k);
    const selected = op == val;
    const cn = nils(val) || selected ? "opacity-100" : "opacity-50";

    const text_cn = selected ? "italic text-acc0" : "";
    return [cn, text_cn];
  };
  const custom_txt_fns = {
    range: (v) => {
      return `Cost Range <${dec(v, 0)}USD`;
    },
    pair: (v) => {
      return `Parents F#${v.h_f_hid} M#${v.h_m_hid}`;
    },
  };

  const stageprops = {
    vault,

    stage,
    data,
    set_data,
    response,
    set_response,
    prevstage,
    nextstage,
    next_allowed,
    set_next_allowed,
    go_next,
    go_prev,
    get_active_cn,

    qolist,
    ranges,
    listmap,

    post_mint_fn,
  };
  return (
    <SpliceContext.Provider value={stageprops}>
      <Card className={twMerge("xs:w-[95vw] lg:w-[60rem]  p-4", card_cn)}>
        <div class="p-2 w-full h-[50rem] max-h-[60vh] fc-sc overflow-auto">
          <div class="w-full fr-sc flex-wrap gap-1">
            {!nils(data.range) && (
              <>
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <span className="resp-text--1 font-digi ">
                    {!nils(custom_txt_fns.range)
                      ? custom_txt_fns.range(data.range)
                      : data.range}
                  </span>
                </Tag>
              </>
            )}
            {!nils(data.pair) && (
              <>
                <FontAwesomeIcon
                  className="text-white resp-text--1"
                  icon={faRightLong}
                />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <span className="resp-text--1 font-digi ">
                    {!nils(custom_txt_fns.pair)
                      ? custom_txt_fns.pair(data.pair)
                      : data.pair}
                  </span>
                </Tag>
              </>
            )}

            {!nils(data.splice_name) && (
              <>
                <FontAwesomeIcon
                  className="text-white resp-text--1"
                  icon={faRightLong}
                />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <span className="resp-text--1 font-digi ">
                    Name: {data.splice_name}
                  </span>
                </Tag>
              </>
            )}
          </div>

          {!nils(StageComp[stage]) && React.createElement(StageComp[stage], {})}
        </div>
        <div class="cursor-pointer fr-sc w-full gap-2">
          <div
            onClick={() => {
              go_prev();
            }}
            class="cursor-pointer fr-sc rounded-md bg-r2dark/40 px-[2rem] py-[0.5rem]"
          >
            <FontAwesomeIcon
              className="xs:text-[1rem] lg:text-[3rem]"
              icon={faArrowLeftLong}
            />
          </div>

          <div class="flex-1"></div>
          {[...new Array(mxstage)].map((e, i) => {
            return (
              <div
                class={twMerge(
                  "xs:w-[0.5rem] lg:w-[1rem] aspect-[1/1] rounded-full bg-white",
                  i < stage ? "" : "opacity-20",
                )}
              ></div>
            );
          })}
          <div class="flex-1"></div>

          <div
            onClick={() => {
              if (!validate_next) return;
              go_next();
            }}
            class={twMerge(
              "cursor-pointer fr-sc rounded-md bg-r2dark/40 px-[2rem] py-[0.5rem]",
              validate_next ? "" : "opacity-0",
            )}
          >
            <FontAwesomeIcon
              className="xs:text-[1rem] lg:text-[3rem]"
              icon={faArrowRightLong}
            />
          </div>
        </div>
      </Card>
    </SpliceContext.Provider>
  );
};
