import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { Loader01c } from "../components/anims.js";
import {
  BImg,
  Card,
  HeadC2L,
  InpText,
  Tag,
  TokenIcon,
} from "../components/utilityComps.js";
import { useMatch, useParams } from "react-router";
import {
  base64_to_json,
  dec,
  get_inp,
  getv,
  json_to_base64,
  jstr,
  nils,
  set_inp,
  iso_format,
} from "../utils/utils.js";
import { useInfiniteQuery, useQueries } from "react-query";
import {
  iserr,
  q_bikeinfo,
  q_hstats_doc,
  q_price,
  q_vault_transfer,
  q_vault_updprofile,
  q_vaultbikes,
  q_vaultinfo,
  qiserr,
  qissuccesss,
  useStepQuery,
  btbk,
  q_validate_vault_name,
  q_token_prices,
  q_open_races,
  q_races_myraces,
} from "../queries/queries.js";
import { motion } from "framer-motion";
import { MoVariants } from "../utils/motion_helper.js";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import { PopUp } from "../components/popup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBox,
  faCheck,
  faCheckSquare,
  faCircle,
  faFilter,
  faGear,
  faLink,
  faRefresh,
  faSquare,
  faStar,
  faTimes,
  faTrophy,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import BikeCard from "../components/BikeCard.js";
import { Helmet } from "react-helmet-async";
import "intersection-observer";
import { Link } from "react-router-dom";
import {
  SortHead,
  filt_ar_using_filters,
  gen_filters_from_valob,
  gen_valob_from_filters,
  mainfiltbtn,
  sort_fn,
  sort_listob,
  filt_ar_using_valfilt,
} from "../utils/filt.js";
import {
  ElementTag,
  MiniElementTag,
  MiniGenderTag,
} from "../components/ShortComps.js";
import {
  cb_txt,
  class_cn,
  class_text,
  elementmap,
  gendermap,
  get_cprofile_hex,
  get_payout_txt,
  rvmode_s,
  tablecn,
} from "../utils/cn_map.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import {
  racecn,
  RacesContextWrapper,
  RacesFiltSection,
  RacesListInner,
  RacesSection,
  SMFiltSection,
  SMMyBikes,
  SMMyRaces,
  useRacesContext,
} from "./Races.js";
import { tokdecn, tokdecn2, useAppContext } from "../App.js";
import { InpAutoWrap } from "../components/input.js";
import moment from "moment";
import { core_market_link, core_os_link } from "../utils/links.js";
import { fpost } from "../queries/fetch.js";
import { BY_Star, PosTag } from "../utils/raceutils.js";
import { IfInView } from "./FinishedRacesPage.js";
import { TrophiesTab } from "./Trophies.js";
import { GlowBtnGroup, SkewBtn, svg_map } from "../utils/raceutils2.js";
import { LedgerInner } from "./Ledger.js";
import { RaceContext } from "./RacePage.js";
import { ErrorBoundary } from "../utils/errbou.js";
import { RacesNext } from "./RacesNext.js";
import { RVImg } from "../components/BikeImg.js";

export const VaultContext = createContext({});
export const useVaultContext = () => useContext(VaultContext);

const VaultNameCard = () => {
  const vcon = useVaultContext();
  const {
    vdoc,
    editmode,
    vault,
    is_auvault,
    apply_updprofile,
    set_editmode,
    editing,
  } = vcon;

  const newvname = get_inp("inp_vault_name");
  const [qovalid] = useQueries([
    q_validate_vault_name(
      { name: newvname },
      {
        enabled: editmode && !nils(newvname),
      },
    ),
  ]);
  const namevalid = useMemo(() => {
    let valid = getv(qovalid, "data.result.valid") == true;
    return valid;
  }, [qovalid.dataUpdatedAt]);

  return (
    <>
      {_.isEmpty(vdoc) ? (
        <Loader01c />
      ) : (
        <>
          <Card
            className={
              "bg-r2lig/20 backdrop-blur-md xs:w-[90%] lg:w-[50rem] mx-auto overflow-auto"
            }
          >
            <motion.div
              variants={MoVariants.show_hide}
              animate={editmode == false ? "visible" : "hidden"}
            >
              <div className="fc-cs font-digi resp-text-1 flex-1">
                <div className="fr-sc resp-gap-2 flex-wrap">
                  <span className="resp-text--1"> Vault: </span>
                  <span className="text-acc0 resp-text--1">
                    {vdoc.vault_name}
                  </span>
                </div>
                <div className="fr-sc resp-gap-2 flex-wrap">
                  <span className="resp-text--1"> Vault Address: </span>
                  <span className="text-acc0 xs:text-[7px] lg:text-[0.8rem] whitespace-break-spaces">
                    <span className="xs:hidden lg:block">{vault}</span>
                    <span className="xs:block lg:hidden">
                      0x...{vault.slice(0, 5)}
                    </span>
                  </span>
                </div>
              </div>
            </motion.div>

            {is_auvault === true && (
              <>
                <hr className="resp-my-2" />

                <motion.div
                  variants={MoVariants.show_hide}
                  animate={editmode == true ? "visible" : "hidden"}
                >
                  <div className="fr-cc resp-gap-2 resp-text--2">
                    <span className="font-digi">Vault Name:</span>
                    <InpText
                      {...{
                        id: "inp_vault_name",
                        setter: (v) => {},
                        inpprops: {
                          className: "xs:w-[10rem] lg:w-[20rem]",
                        },
                      }}
                    />
                  </div>
                </motion.div>

                <div className="fr-sc resp-gap-2">
                  <div className="flex-1"></div>

                  {editmode && (
                    <>
                      {nils(newvname) ? null : qovalid.isLoading ? (
                        <FontAwesomeIcon
                          icon={faRefresh}
                          className="rep-text-1 spin-anim text-acc4"
                        />
                      ) : namevalid == true ? (
                        <Tag
                          onClick={apply_updprofile}
                          className={twMerge(
                            "fr-cc resp-gap-2",
                            editing
                              ? "bg-dark text-acc0"
                              : "bg-green-300 text-black",
                          )}
                        >
                          {editing && <Loader01c size="s" />}
                          <span>Apply Changes</span>
                        </Tag>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="rep-text-1 text-red-400"
                          />
                          <span className="resp-text--2 text-red-300">
                            name already in use
                          </span>
                        </>
                      )}
                    </>
                  )}
                  <Tag
                    onClick={() => {
                      set_editmode(!editmode);
                    }}
                    className={twMerge(
                      "text-black",
                      editmode ? "bg-red-300" : "bg-yellow-300",
                    )}
                  >
                    {editmode ? "Discard" : "Edit Profile"}
                  </Tag>
                </div>
              </>
            )}
          </Card>
        </>
      )}
    </>
  );
};
const FiltSection = () => {
  const vcon = useVaultContext();
  const {
    filt,
    set_filt,
    clear_filt,
    tab,
    rvmode,
    set_rvmode,

    statssp,
    set_statssp,
  } = vcon;

  const inpargs = {
    fkey: "vaultfilt",
    filters: filt,
    set_filters: set_filt,
  };

  const c4 = get_cprofile_hex("acc4");

  return (
    <Card className={"bg-r2lig/20 backdrop-blur-md max-w-[95vw] w-[50rem]"}>
      <div class="fr-cc my-2 resp-gap-2">
        {rvmode_s.map((rv) => {
          let active = rvmode == rv;
          return (
            <div
              onClick={() => {
                set_rvmode(rv);
              }}
              className={twMerge(
                "cursor-pointer resp-p-2 h-full rounded-md",
                active ? "bg-acc4 shadow-acc0 shadow-md" : "bg-r2dark/20",
              )}
            >
              <RVImg
                {...{ rvmode: rv }}
                hex_code={!active ? c4 : "#FFFFFF"}
                className={"xs:w-[4rem] lg:w-[8rem]"}
              />
            </div>
          );
        })}
      </div>
      <div className="grid xs:grid-cols-1 lg:grid-cols-1 ">
        <div className="mx-auto my-2 fr-sc w-full flex-wrap max-w-full resp-gap-2">
          <InpAutoWrap {...{ ...inpargs, idd: "breed_now" }} />
          <InpAutoWrap {...{ ...inpargs, idd: "in_arena" }} />
          <InpAutoWrap {...{ ...inpargs, idd: "is_maiden" }} />
          <div class="flex-1"></div>
          <div
            onClick={clear_filt}
            className="resp-text--3 resp-p-1 resp-px-4 rounded-full bg-red-400 cursor-pointer"
          >
            clear filters
          </div>
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "type" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "element" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "gender" }} />
        </div>
        {tab == "bikestats" && (
          <>
            <div className="w-max mx-auto fr-cc wrap max-w-full">
              <InpAutoWrap {...{ ...inpargs, idd: "cb" }} />
            </div>
            <div className="w-max mx-auto fr-cc wrap max-w-full">
              <InpAutoWrap {...{ ...inpargs, idd: "payout" }} />
            </div>
          </>
        )}
      </div>

      {tab == "bikestats" && (
        <div class="grid grid-cols-3 my-2 resp-gap-2">
          {[
            "all",
            // "season0", "season1", "season2",
            "season3",
            "season4",
          ].map((sp) => {
            let active = statssp == sp;
            return (
              <Tag
                onClick={() => {
                  set_statssp(sp);
                }}
                className={twMerge(
                  "fr-sc resp-gap-2 border border-acc0",
                  active ? "bg-acc0" : "text-white",
                )}
              >
                <p class="resp-text-1 font-digi uppercase text-center w-full">
                  {sp}
                </p>
              </Tag>
            );
          })}
        </div>
      )}
      <div className="fr-sc"></div>
    </Card>
  );
};
const BikeCountsRow = () => {
  const vcon = useVaultContext();
  const { qo_vaultbikes, hids, filthids, bikesob } = vcon;

  return (
    <>
      {qo_vaultbikes.isLoading == false && (
        <>
          <div className="fr-sc items-center max-w-[95vw] w-[50rem] mx-auto">
            {_.keys(bikesob).length !== hids.length ? (
              <>
                <Loader01c size="s" />
                <p className="text-acc0 resp-text--1 resp-px-2">
                  {`Loaded ${_.keys(bikesob).length} / ${hids.length} Bikes`}
                </p>
              </>
            ) : (
              ""
            )}
            <div className="flex-1"></div>

            {filthids.length != hids.length && (
              <p className="text-yellow-300 resp-text--1 resp-px-2">
                {`Filtered ${filthids.length} / ${hids.length} Bikes`}
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
};

const BikeStatsView = () => {
  const vcon = useVaultContext();
  const {
    tab,

    rvmode,
    set_rvmode,
    sorted_hids,
    hsob,
    sorts,
    set_sorts,
    sort_ob,

    statssp,
    set_statssp,

    filt,
  } = vcon;

  let par_ar = { sorts, set_sorts, sort_ob };

  const headcn = "text-acc0";
  const cb = getv(filt, "cb.vals") ?? "all";
  const payout = getv(filt, "payout.vals") ?? null;

  return (
    <>
      <BikeCountsRow />
      <FiltSection />
      <div className="max-w-[95vw] w-[80rem] mx-auto overflow-auto bg-r2lig/20 backdrop-blur-md rounded-md border border-r2lig">
        <table
          className={twMerge(tablecn.table, "w-full thintdrowp4-table-r2lig")}
        >
          <thead>
            <tr className="resp-text--2 text-acc0">
              <td colSpan={3}>
                <div class="fr-sc resp-gap-2 flex-wrap">
                  <span>{cb == "all" ? "All" : `CB${cb}`}</span>
                  {!nils(payout) && (
                    <span>Payout:{get_payout_txt(payout)}</span>
                  )}
                </div>
              </td>
              <td colSpan={4} className="border-l border-acc0">
                All Races
              </td>
              <td colSpan={2} className="border-l border-acc0">
                Paid Races
              </td>
              <td colSpan={4}></td>
            </tr>
            <tr className="thintdrowp4 card-dark-bg">
              <td>
                <SortHead {...{ ...par_ar, k: "hid", className: headcn }} />
              </td>
              <td>
                <SortHead {...{ ...par_ar, k: "name", className: headcn }} />
              </td>
              <td>
                <div className="fr-sc resp-gap-2">
                  <SortHead
                    {...{ ...par_ar, k: "element", className: headcn }}
                  />
                  <SortHead
                    {...{ ...par_ar, k: "gender", className: headcn }}
                  />
                  <SortHead {...{ ...par_ar, k: "fno", className: headcn }} />
                  <SortHead {...{ ...par_ar, k: "type", className: headcn }} />
                </div>
              </td>

              <td className="border-l border-acc0">
                <div className="fr-sc resp-gap-1">
                  <SortHead
                    {...{ ...par_ar, k: "bluestar_p", className: headcn }}
                  />
                </div>
              </td>
              <td>
                <div className="fr-sc resp-gap-1">
                  <SortHead
                    {...{ ...par_ar, k: "yellowstar_p", className: headcn }}
                  />
                </div>
              </td>

              <td>
                <div className="fr-sc resp-gap-1">
                  <SortHead
                    {...{ ...par_ar, k: "races_n", className: headcn }}
                  />
                  {/* <span>/</span> */}
                  <SortHead {...{ ...par_ar, k: "win_n", className: headcn }} />
                  <SortHead {...{ ...par_ar, k: "p2_n", className: headcn }} />
                  <SortHead {...{ ...par_ar, k: "p3_n", className: headcn }} />
                </div>
              </td>
              <td>
                <div className="fr-sc resp-gap-1">
                  <SortHead {...{ ...par_ar, k: "win_p", className: headcn }} />
                </div>
              </td>

              <td className="border-l border-acc0">
                <div className="fr-sc resp-gap-1">
                  <SortHead
                    {...{ ...par_ar, k: "paid_races_n", className: headcn }}
                  />
                  {/* <span>/</span> */}
                  <SortHead
                    {...{ ...par_ar, k: "paid_win_n", className: headcn }}
                  />
                  <SortHead
                    {...{ ...par_ar, k: "paid_p2_n", className: headcn }}
                  />
                  <SortHead
                    {...{ ...par_ar, k: "paid_p3_n", className: headcn }}
                  />
                </div>
              </td>
              <td>
                <div className="fr-sc resp-gap-1">
                  <SortHead
                    {...{ ...par_ar, k: "paid_win_p", className: headcn }}
                  />
                </div>
              </td>
              <td className="border-l border-acc0">
                <div className="fr-sc resp-gap-1">
                  <SortHead
                    {...{ ...par_ar, k: "profit_weth", className: headcn }}
                  />
                </div>
              </td>
              <td>
                <div className="fr-sc resp-gap-1">
                  <SortHead
                    {...{ ...par_ar, k: "profit_dez", className: headcn }}
                  />
                </div>
              </td>
              <td className="border-l border-acc0">
                <div className="fr-sc resp-gap-1">
                  <SortHead
                    {...{ ...par_ar, k: "price_dna", className: headcn }}
                  />
                </div>
              </td>
              <td>
                <div className="fr-sc resp-gap-1">
                  <SortHead
                    {...{ ...par_ar, k: "price_os", className: headcn }}
                  />
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {sorted_hids.map((hid, idx) => {
              let h = getv(hsob, `${hid}`);
              if (_.isEmpty(h)) return;
              // if (hid == 10) console.log("h", hid, h);
              return (
                <tr className="thintdrowp4 resp-text--2">
                  <td>
                    <span>{h.hid}</span>
                  </td>
                  <td>
                    <Link
                      // target="_blank"
                      to={`/bike/${h.hid}`}
                    >
                      <div className="fr-sc font-digi text-acc0 xs:w-max lg:min-w-[12rem]">
                        <span>{h.name}</span>
                        {h.is_maiden && (
                          <span class="resp-text--2 text-acc0">ME</span>
                        )}
                      </div>
                    </Link>
                  </td>
                  <td>
                    <div className="fr-sc resp-gap-1">
                      <MiniElementTag element={h.element} />
                      <MiniGenderTag gender={h.gender} />
                      <span className="text-left xs:w-[1.5rem] lg:w-[2rem] text-acc0">
                        F{h.fno}
                      </span>
                      <span className="text-left xs:w-[2rem] lg:w-[3rem]">
                        {_.capitalize(h.type)}
                      </span>
                    </div>
                  </td>

                  <td className="border-l border-acc0">
                    {nils(getv(h, "s.bluestar_p")) ||
                    getv(h, "s.bluestar_p") == 0 ? (
                      <span className="text-slate-400">--</span>
                    ) : (
                      <span className={twMerge("fr-sc text-blue-400")}>
                        {dec(getv(h, "s.bluestar_p") * 100, 1)}%
                      </span>
                    )}
                  </td>
                  <td>
                    {nils(getv(h, "s.yellowstar_p")) ||
                    getv(h, "s.yellowstar_p") == 0 ? (
                      <span className="text-slate-400">--</span>
                    ) : (
                      <span className={twMerge("fr-sc text-yellow-400")}>
                        {dec(getv(h, "s.yellowstar_p") * 100, 1)}%
                      </span>
                    )}
                  </td>

                  <td>
                    <div className="fr-sc resp-gap-1">
                      {nils(getv(h, "s.races_n")) ||
                      getv(h, "s.races_n") == 0 ? (
                        <span className="text-slate-400">--</span>
                      ) : (
                        <>
                          <span className="text-acc0">
                            {getv(h, "s.races_n")}
                          </span>
                          <span>-</span>
                          <span>{getv(h, "s.win_n")}</span>
                          <span>-</span>
                          <span>{getv(h, "s.p2_n")}</span>
                          <span>-</span>
                          <span>{getv(h, "s.p3_n")}</span>
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="fr-sc resp-gap-1">
                      {nils(getv(h, "s.races_n")) ||
                      getv(h, "s.races_n") == 0 ? (
                        <span className="text-slate-400">--</span>
                      ) : (
                        <>
                          <span className="text-acc0">
                            {dec(getv(h, "s.win_p") * 100, 2)}%
                          </span>
                        </>
                      )}
                    </div>
                  </td>

                  <td className="border-l border-acc0">
                    <div className="fr-sc resp-gap-1">
                      {nils(getv(h, "s.paid_races_n")) ||
                      getv(h, "s.paid_races_n") == 0 ? (
                        <span className="text-slate-400">--</span>
                      ) : (
                        <>
                          <span className="text-acc0">
                            {getv(h, "s.paid_races_n")}
                          </span>
                          <span>-</span>
                          <span>{getv(h, "s.paid_win_n")}</span>
                          <span>-</span>
                          <span>{getv(h, "s.paid_p2_n")}</span>
                          <span>-</span>
                          <span>{getv(h, "s.paid_p3_n")}</span>
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="fr-sc resp-gap-1">
                      {nils(getv(h, "s.paid_races_n")) ||
                      getv(h, "s.paid_races_n") == 0 ? (
                        <span className="text-slate-400">--</span>
                      ) : (
                        <>
                          <span className="text-acc0">
                            {dec(getv(h, "s.paid_win_p") * 100, 2)}%
                          </span>
                        </>
                      )}
                    </div>
                  </td>

                  <td className="border-l border-acc0">
                    <div
                      className={twMerge(
                        "fr-sc resp-gap-1",
                        parseFloat(getv(h, "s.profit")) > 0
                          ? "text-green-400"
                          : getv(h, "s.profit") == 0 ||
                              nils(getv(h, "s.profit"))
                            ? "text-slate-400"
                            : "text-red-400",
                      )}
                    >
                      <TokenIcon size="xxs" token={"WETH"} />
                      <span>{dec(getv(h, "s.profit") ?? 0, 3)}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={twMerge(
                        "fr-sc resp-gap-1",

                        parseFloat(getv(h, "s.profit_dez")) > 0
                          ? "text-green-400"
                          : getv(h, "s.profit_dez") == 0 ||
                              nils(getv(h, "s.profit_dez"))
                            ? "text-slate-400"
                            : "text-red-400",
                      )}
                    >
                      <TokenIcon size="xxs" token={"DEZ"} />
                      <span>{dec(getv(h, "s.profit_dez") ?? 0, 0)}</span>
                    </div>
                  </td>

                  <td className="border-l border-acc0">
                    {nils(getv(h, "price.dna")) ? (
                      <span className="slate-400">--</span>
                    ) : (
                      <Tag
                        redirect={core_market_link(hid)}
                        className="fr-sc resp-gap-1 text-acc0 border border-acc0"
                      >
                        <TokenIcon
                          size="xxs"
                          token={getv(h, "price.dna.token")}
                        />
                        <span>
                          {dec(
                            getv(h, "price.dna.amt") ?? 0,
                            tokdecn2(getv(h, "price.dna.token")),
                          )}
                        </span>
                      </Tag>
                    )}
                  </td>

                  <td>
                    {nils(getv(h, "price.os")) ? (
                      <span className="slate-400">--</span>
                    ) : (
                      <Tag
                        redirect={core_os_link(hid)}
                        className="fr-sc resp-gap-1 text-purple-400 border border-purple-400"
                      >
                        <TokenIcon
                          size="xxs"
                          token={getv(h, "price.os.token")}
                        />
                        <span>
                          {dec(
                            getv(h, "price.os.amt") ?? 0,
                            tokdecn2(getv(h, "price.os.token")),
                          )}
                        </span>
                      </Tag>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const BikeCardsView = () => {
  const vcon = useVaultContext();
  const { filthids, bikesob, is_auvault } = vcon;

  const trainers_hids = useMemo(() => {
    return _.chain(filthids)
      .filter((hid) => {
        return getv(bikesob, `${hid}.type`) == "trainer";
      })
      .value();
  }, [jstr(bikesob), filthids]);

  return (
    <>
      <BikeCountsRow />
      <FiltSection />

      <div class="">
        {filthids.map((hid, idx) => {
          let b = getv(bikesob, `${hid}`);
          let ext_class = "";
          if (b.type == "trainer")
            ext_class = `trainer-${trainers_hids.indexOf(hid)}`;
          if (_.isEmpty(b)) return;
          return (
            <BikeCard
              key={hid}
              bike={b}
              {...{ mybike: is_auvault, ext_class }}
            />
          );
        })}
      </div>
    </>
  );
};

const RacesResultsView = () => {
  const vcon = useVaultContext();
  const { vault, hsob } = vcon;
  const appcon = useAppContext();
  const { tok_to_usd_val } = appcon;

  const fetchpage = ({ page = 0 }) => {
    if (page == -1) return null;
    return fpost(`${btbk}/fbike/vault/raceresults`, { vault, page });
  };

  const q = useInfiniteQuery({
    queryKey: ["vault_race_results", { vault }],
    queryFn: (pars) => {
      console.log("queryFn", pars.pageParam);
      return fetchpage(pars.pageParam || {});
    },
    getNextPageParam: (lastPage, allPages) => {
      let rslen = (getv(lastPage, "result.races") || []).length;
      if (rslen == 0) return { page: -1 };
      let page = getv(lastPage, "result.next_page");
      return { page };
    },
    refetchInterval: 2 * 60 * 1e3,
  });
  // useEffect(() => {
  //   console.log(q);
  // }, [q.dataUpdatedAt]);
  const rs = useMemo(() => {
    let rs = _.chain(q.data?.pages)
      .filter((e) => getv(e, "status") == "success")
      .map((e) => getv(e, "result.races"))
      .flatten()
      .uniqBy((e) => `${e.rid}-${e.hid}`)
      .map((r) => {
        r.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
        r.prize_usd = tok_to_usd_val(r.prize, r.paytoken);
        return r;
      })
      .value();
    // console.log("rs", rs);
    return rs;
  }, [q.dataUpdatedAt]);

  return (
    <>
      <div className="rounded-md mx-auto max-w-[95vw] w-[80rem] overflow-auto bg-r2lig/20 backdrop-blur-md border border-acc4">
        {q.isLoading ? (
          <div class="mx-auto w-max">
            <Loader01c size="s" />
          </div>
        ) : _.isEmpty(rs) ? (
          <>
            <p className="text-yellow-400 text-center">No Races Yet</p>
          </>
        ) : (
          <>
            <table className={twMerge(tablecn.table, "resp-text--2")}>
              <thead className="thintdrow text-acc0 bg-r2dark">
                <th colSpan={2}>
                  <span className="remp-my-2">Race</span>
                </th>
                <th colSpan={2}>Bike</th>
                <th colSpan={2}>Class - CB</th>
                <th>Format</th>
                <th>Payout</th>
                <th>Gates</th>
                <th>Star</th>
                <th>Pos</th>
                <th>Time</th>
                <th>Fee</th>
                <th>Prize</th>
              </thead>
              <tbody>
                {rs.map((r) => {
                  let hid = r.hid;
                  let h = getv(hsob, `${hid}`) || {};
                  return (
                    <tr className="thintdrow resp-text--2 ">
                      <td colSpan={2}>
                        <Link
                          to={`/race/${r.rid}`}
                          className="block resp-gap-1 xs:min-w-[8rem] lg:min-w-[12rem]"
                        >
                          <div className="fc-ss">
                            <span className="font-digi resp-text--3 italic">
                              {r.race_name}
                            </span>
                            {!_.isEmpty(r.eventtags) && (
                              <div className="fr-sc resp-gap-1">
                                {r.eventtags.map((e) => (
                                  <span className="resp-text--4 rounded-md resp-p-1 bg-slate-600 text-white">
                                    {_.capitalize(e)}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>

                          <div className="fr-sc my-1 resp-gap-2">
                            <FontAwesomeIcon icon={faLink} />
                            <span>{r.rid}</span>
                          </div>
                          <span className="resp-text--4 text-slate-300">
                            @{iso_format(r.start_time)}
                          </span>
                        </Link>
                      </td>
                      <td colSpan={2}>
                        <Link
                          to={`/bike/${r.hid}`}
                          className="fr-sc resp-gap-2 xs:min-w-[8rem] lg:min-w-[12rem]"
                        >
                          <div class="xs:w-[1.5rem] lg:w-[3rem]">
                            <RVImg rvmode={r.rvmode} hex_code={"FFFFFF"} />
                          </div>

                          <span className={twMerge(`text-${r.hcolor}`)}>
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                          <span className="font-digi italic text-acc0">
                            {r.hname}
                          </span>
                        </Link>
                      </td>
                      <td colSpan={2}>
                        <div className="fr-sc">
                          <Tag
                            className={twMerge(
                              class_cn(r.class),
                              "xs:resp-text--2 lg:resp-text--2",
                            )}
                          >
                            {class_text(r.class, "t")}
                          </Tag>
                          <Tag
                            className={
                              "text-acc0 xs:resp-text--2 lg:resp-text--2"
                            }
                          >
                            {cb_txt(r.cb, "t")}
                          </Tag>
                        </div>
                      </td>
                      <td>
                        <span className="text-white">
                          {_.upperCase(r.format)}
                        </span>
                      </td>
                      <td>
                        <span className="text-purple-400">
                          {_.upperCase(r.payout)}
                        </span>
                      </td>
                      <td>
                        <span className="xs:w-[1.5rem] lg:w-[3.5rem]">
                          G-{r.rgate}
                        </span>
                      </td>
                      <td>
                        <span>
                          <BY_Star star={r.star} />
                        </span>
                      </td>

                      <td>
                        <PosTag pos={r.pos} className={"resp-text--2"} />
                      </td>
                      <td>
                        <span className="resp-text--1 text-green-400 font-digi">
                          {dec(r.time, 1)}s
                        </span>
                      </td>
                      <td>
                        <div className="fc-cc resp-gap-2">
                          {parseFloat(r.fee) == 0 ? (
                            <>
                              <span className="text-yellow-400">FREE</span>
                            </>
                          ) : (
                            <>
                              <div className="fr-sc text-yellow-400">
                                <TokenIcon token={r.paytoken} size="xs" />
                                <span className="resp-text--2 font-mon">
                                  {dec(r.fee, tokdecn(r.paytoken))}
                                </span>
                              </div>
                              <div className="fr-sc text-yellow-400">
                                <FontAwesomeIcon icon={faUsd} />
                                <span className="resp-text--2 font-mon">
                                  {dec(tok_to_usd_val(r.fee, r.paytoken), 2)}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="fc-cc resp-gap-2">
                          {parseFloat(r.prize_eth) == 0 ? (
                            <>
                              <span className="text-slate-300">--</span>
                            </>
                          ) : (
                            <>
                              <div className="fr-sc text-green-400">
                                <TokenIcon token={r.paytoken} size="xs" />
                                <span className="resp-text--2 font-mon">
                                  {dec(r.prize_eth, tokdecn(r.paytoken))}
                                </span>
                              </div>
                              <div className="fr-sc text-green-400">
                                <FontAwesomeIcon icon={faUsd} />
                                <span className="resp-text--2 font-mon">
                                  {dec(
                                    tok_to_usd_val(r.prize_eth, r.paytoken),
                                    2,
                                  )}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        {q.isFetching && (
          <div class="w-max mx-auto">
            <Loader01c size="s" />
          </div>
        )}
        <IfInView runViewFn={!q.isFetching} onView={q.fetchNextPage} />
        <div class="h-[5rem]"></div>
      </div>
    </>
  );
};

const MarketView = () => {
  const vcon = useVaultContext();
  const { hids, hsob } = vcon;
  const filthids = useMemo(() => {
    let ar = [];
    for (let hid of hids) {
      let h = getv(hsob, `${hid}`);
      if (nils(h)) continue;
      let dnaamt = getv(h, "price.dna.amt");
      let osamt = getv(h, "price.os.amt");
      let dnabid = getv(h, "price.dnahbid.amt");
      if (!nils(dnaamt) || !nils(osamt) || !nils(dnabid)) ar.push(hid);
    }
    return ar;
  }, [jstr(hids), jstr(hsob)]);

  const [sorts, set_sorts] = useState([]);
  const sort_ob = {
    hid: { disp: "hid", fn: (i1) => sort_fn(i1, "hid", "n") },
    name: { disp: "Name", fn: (i1) => sort_fn(i1, "name", "txt") },
    price_dna: {
      disp: "Price DNA",
      fn: (i1) => sort_fn(i1, "price.dna.amt", "n"),
    },
    price_os: {
      disp: "Price OS",
      fn: (i1) => sort_fn(i1, "price.os.amt", "n"),
    },
    price_dnahbid: {
      disp: "Highest Bid",
      fn: (i1) => sort_fn(i1, "price.dnahbid.amt", "n"),
    },
  };
  const headcn = "text-acc0 font-digi";
  const par_ar = { sorts, set_sorts, sort_ob };

  const sorted_hids = useMemo(() => {
    if (_.isEmpty(filthids)) return [];

    let ar = sort_listob({
      list: filthids.map((hid) => hsob[hid]),
      sorts,
      sort_ob,
      id: "hid",
    });
    return ar;
  }, [jstr(hsob), jstr(sorts), jstr(sort_ob), jstr(filthids)]);

  if (_.isEmpty(sorted_hids)) {
    return <p class="text-yellow-400 text-center">No Market Activity found</p>;
  }
  return (
    <div>
      <table className={twMerge(tablecn.table, " resp-text--2 w-max mx-auto")}>
        <thead>
          <tr className="thintdrowp4">
            <th>
              <SortHead {...{ ...par_ar, k: "hid", className: headcn }} />
            </th>
            <th>
              <SortHead {...{ ...par_ar, k: "name", className: headcn }} />
            </th>
            <th>
              <SortHead {...{ ...par_ar, k: "price_dna", className: headcn }} />
            </th>
            <th>
              <SortHead {...{ ...par_ar, k: "price_os", className: headcn }} />
            </th>
            <th>
              <SortHead
                {...{ ...par_ar, k: "price_dnahbid", className: headcn }}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {sorted_hids.map((hid) => {
            let h = getv(hsob, `${hid}`) || {};
            return (
              <tr className="thintdrowp4" key={hid}>
                <td>
                  <span>{hid}</span>
                </td>
                <td>
                  <span>{h.name}</span>
                </td>

                <td className="">
                  {nils(getv(h, "price.dna")) ? (
                    <span className="slate-400">--</span>
                  ) : (
                    <Tag
                      redirect={core_market_link(hid)}
                      className="fr-sc resp-gap-1 text-acc0 border border-acc0"
                    >
                      <TokenIcon
                        size="xxs"
                        token={getv(h, "price.dna.token")}
                      />
                      <span>
                        {dec(
                          getv(h, "price.dna.amt") ?? 0,
                          tokdecn2(getv(h, "price.dna.token")),
                        )}
                      </span>
                    </Tag>
                  )}
                </td>

                <td>
                  {nils(getv(h, "price.os")) ? (
                    <span className="slate-400">--</span>
                  ) : (
                    <Tag
                      redirect={core_os_link(hid)}
                      className="fr-sc resp-gap-1 text-purple-400 border border-purple-400"
                    >
                      <TokenIcon size="xxs" token={getv(h, "price.os.token")} />
                      <span>
                        {dec(
                          getv(h, "price.os.amt") ?? 0,
                          tokdecn2(getv(h, "price.os.token")),
                        )}
                      </span>
                    </Tag>
                  )}
                </td>

                <td>
                  {!nils(getv(h, "price.dnahbid")) ? (
                    <Tag
                      redirect={core_os_link(hid)}
                      className="fr-sc resp-gap-1 text-acc0 border border-acc0"
                    >
                      <TokenIcon
                        size="xxs"
                        token={getv(h, "price.dnahbid.token")}
                      />
                      <span>
                        {dec(
                          getv(h, "price.dnahbid.amt") ?? 0,
                          tokdecn2(getv(h, "price.dnahbid.amt")),
                        )}
                      </span>
                      <span>by {getv(h, "price.dnahbid.bidder_name")}</span>
                    </Tag>
                  ) : (
                    <span className="text-slate-400">--</span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const RacesLobbyView = () => {
  const rcon = useRacesContext();
  const {
    showmybikes,
    set_showmybikes,
    showmyraces,
    set_showmyraces,
    smshowfilters,
    set_smshowfilters,
  } = rcon;

  return (
    <>
      <div class="w-full">
        <div class="small-screen xs:block lg:hidden">
          <div className="fixed bottom-0 h-[4rem] left-0 w-full bg-r2dark z-30">
            <div className="flex flex-row justify-evenly w-full">
              <SMFiltSection
                {...{
                  show: smshowfilters,
                  open: () => set_smshowfilters(true),
                  close: () => set_smshowfilters(false),
                }}
              />
              <SMMyRaces
                {...{
                  show: showmyraces,
                  open: () => set_showmyraces(true),
                  close: () => set_showmyraces(false),
                }}
              />
              <SMMyBikes
                {...{
                  show: showmybikes,
                  open: () => set_showmybikes(true),
                  close: () => set_showmybikes(false),
                }}
              />
              {[
                ["Filters", smshowfilters, () => set_smshowfilters(true)],
                ["My Races", showmyraces, () => set_showmyraces(true)],
                ["My Bikes", showmybikes, () => set_showmybikes(true)],
              ].map((e) => {
                let [txt, active, click] = e;
                return (
                  <Tag
                    onClick={click}
                    className={twMerge(
                      `small-rsnav-link-${_.snakeCase(txt)}`,
                      "text-slate-200 flex-1 text-center transform duration-300",
                      active ? "text-acc0" : "",
                    )}
                  >
                    <span className="text-[0.8rem] font-digi">{txt}</span>
                  </Tag>
                );
              })}
            </div>
          </div>
          <RacesSection />
        </div>
        <div class="large-screen xs:hidden lg:block">
          <RacesFiltSection />
          <RacesSection />
        </div>
      </div>
    </>
  );
};

const ActiveRacesView = () => {
  const appcon = useAppContext();
  const { tok_to_usd_val, tokmap } = appcon;
  const { vault } = useVaultContext();

  const [qo_races] = useQueries([
    q_races_myraces(
      { vault },
      { enabled: true, staleTime: 30 * 1e3, refetchInterval: 30 * 1e3 },
    ),
  ]);

  const [races, races_n] = useMemo(() => {
    let rs = getv(qo_races.data, "result");
    if (_.isEmpty(rs)) return [[], 0];
    let races = [];
    for (let r of rs) {
      let race = r;
      if (race) {
        race.fee_usd = tok_to_usd_val(race.fee, race.paytoken);
        race.prize_usd = tok_to_usd_val(race.prize, race.paytoken);
        // console.log(race);
      }
      races.push(race);
    }
    races = _.compact(races);
    races = _.compact(races);
    return [races, races.length];
  }, [qo_races.dataUpdatedAt, jstr(tokmap)]);
  const [selrace, set_selrace] = useState(null);

  return (
    <div>
      {qo_races.isLoading && (
        <div class="w-max mx-auto">
          <Loader01c />
        </div>
      )}
      {qissuccesss(qo_races) && _.isEmpty(races) && (
        <p class="resp-text-1 font-digi text-center my-1">No Active races</p>
      )}
      <div class="">
        <div class="my-1">
          <RacesListInner
            {...{ races, qo_races: qo_races, selrace, set_selrace }}
          />
        </div>
      </div>
    </div>
  );
};

const RacingDashboard = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const aucon = useAuthContext();
  const { vault } = useParams();

  const accon = useAccountContext();
  const { g_acc_config, s_acc_config, refresh_bikeslist } = accon;

  const [qo_vaultinfo, qo_vaultbikes] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
    q_vaultbikes({ vault }, { enabled: !nils(vault) }),
  ]);
  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);
  const hids = useMemo(() => {
    let vaultbikes = getv(qo_vaultbikes, "data.result") ?? [];
    return vaultbikes;
  }, [qo_vaultbikes.dataUpdatedAt]);

  const qst_bdocs = useStepQuery({
    q_: q_bikeinfo,
    par_ar: hids.map((hid) => [{ hid }]),
    lim: 5,
  });
  const bikesob = useMemo(() => {
    let b = {};
    for (let q of qst_bdocs.qs) {
      let d = getv(q, "data.result");
      if (nils(d)) continue;
      b[d.hid] = d;
    }
    return b;
  }, [jstr(_.map(qst_bdocs.qs, "dataUpdatedAt"))]);

  const [editmode, set_editmode] = useState(false);
  const [editing, set_editing] = useState(false);

  const is_auvault = useMemo(() => {
    // return true;
    let a = aucon?.vault?.toLowerCase();
    let b = vault?.toLowerCase();
    return !nils(a) && a === b;
  }, [aucon.vault, vault]);

  const apply_updprofile = () => {
    set_editing(true);
    let id = "inp_vault_name";
    let upd = {};
    upd.vault_name = get_inp(id);
    if (nils(upd.vault_name)) {
      set_editing(false);
    } else {
      upd.vault_name = upd.vault_name.trim();
      q_vault_updprofile({ upd })
        .queryFn()
        .then(() => {
          setTimeout(() => {
            qo_vaultinfo.refetch();
          }, 1000);

          setTimeout(() => {
            set_inp(id, "");
            set_editmode(false);
            set_editing(false);
          }, 3000);
        });
    }
  };

  const basefilt = useMemo(() => {
    let f = psearch.f;
    f = base64_to_json(f);
    f = f ?? {};
    if (_.isEmpty(f)) {
      let cac_f = g_acc_config("vaultbfilt.basefilt", {});
      f = cac_f;
    }
    return f;
  }, [psearch]);
  const [filt, set_filt] = useState({
    cb: {
      type: "options-only",
      path: "cb",
      cfilter: false,
      options: [
        "all",
        ..._.sortBy(
          [
            ...[9, 11, 13, 16, 19, 21, 23],
            ...[10, 12, 14, 18, 20, 22],
            ...[15, 17],
          ],
          (o) => o,
        ),
      ],
      vals: basefilt?.cb ?? "all",
      txt_fn: (o) => {
        return o == "all" ? "All" : `CB${o}00`;
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/40";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },

    payout: {
      type: "options-only",
      path: "payout",
      cfilter: false,
      options: [
        "wta",
        "1v1",
        "top2",
        "top3",
        "dblup",
        "variance",
        "pro",
        "revvar",
        "pity",
        "pitylow",
        "last",
        "revwta",
      ],
      vals: basefilt?.payout ?? null,
      txt_fn: (o) => {
        if (nils(o)) return "--";
        return get_payout_txt(o);
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/40";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },

    type: {
      type: "options",
      path: "type",
      cfilter: true,
      options: ["genesis", "morphed", "freak", "xclass"],
      vals: basefilt?.type,
      txt_fn: (o) => {
        return _.upperCase(_.kebabCase(o));
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/40";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    element: {
      type: "options",
      path: "element",
      cfilter: true,
      options: ["metal", "fire", "earth", "water"],
      vals: basefilt?.element,
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={elementmap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        return `shadow shadow-white transform text-white ${elementmap[op]?.bg} -skew-x-12`;
      },
    },
    gender: {
      type: "options-only-ar",
      path: "gender",
      cfilter: true,
      label: "gender",
      show_label: false,
      options: _.keys(gendermap),
      filter_exception: [],
      vals: _.compact(basefilt?.gender),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={gendermap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-r2dark/20 border border-acc4";
        return `shadow shadow-white transform text-white ${gendermap[op]?.bg} -skew-x-12`;
      },
    },
    breed_now: {
      type: "switch",
      path: "gender",
      cfilter: true,
      label: "Can Breed Now?",
      show_label: true,
      vals: basefilt?.breed_now ?? null,
      path: (h, f, filters) => {
        let fm = getv(filters, "breed_now.vals") ?? false;
        if (fm == true) {
          let b = getv(h, "splice_core") ?? 0;
          if (h.type !== "genesis") {
            let bday = getv(h, "mint.date");
            let diff = moment().diff(moment(bday), "days");
            if (diff < 30) {
              return false;
              // `New Born! Eligible to enter arena after ${30 - diff} days`;
            }
          }

          if (b.life_splices_n >= b.mxlife_splices_n) {
            return false;
            // `Max Life Splices Reached`;
          }

          if (b.cycle_splices_n >= b.mxcycle_splices_n) {
            return false;
            // `Max Splices Limit Reached for Cycle `;
          }
          return true;
        }
        return true;
      },
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
      active_cn: (active, op) => {},
    },
    is_maiden: {
      type: "switch",
      path: "is_maiden",
      cfilter: true,
      label: "Is Maiden?",
      show_label: true,
      vals: basefilt?.is_me ?? null,
      path: (h, f, filters) => {
        let fm = getv(filters, "is_maiden.vals") ?? false;
        if (fm == true) {
          let is_maiden = getv(h, "is_maiden");
          return is_maiden === true;
        }
        return true;
      },
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
      active_cn: (active, op) => {},
    },
    in_arena: {
      type: "switch",
      path: "gender",
      cfilter: true,
      label: "In Arena?",
      show_label: true,
      vals: basefilt?.in_arena ?? null,
      path: (h, f, filters) => {
        let fm = getv(filters, "in_arena.vals") ?? false;
        // console.log("inarena", { fm });
        if (fm == true) {
          let b = getv(h, "splice_core") ?? 0;
          return b.in_stud === true;
        }
        return true;
      },
      txt_fn: (o) => {
        if (nils(o)) return "--";
        else if (o == true) return "ON";
        else return "OFF";
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `resp-text--1`,
      color_fn: (o) => {
        if (nils(o)) return "text-slate-400";
        else if (o == true) return "text-green-400";
        else return "text-red-400";
      },
      active_cn: (active, op) => {},
    },
  });
  const valfilt = useMemo(() => {
    let valfilt = gen_valob_from_filters(filt);
    s_acc_config("vaultbfilt.basefilt", valfilt);
    return valfilt;
  }, [filt]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const [tab, set_tab] = useState(psearch.tab ?? "bikecards");
  const rem = useMemo(() => {
    let rem = {};
    rem.f = json_to_base64(valfilt);
    rem.tab = tab;
    upd_psearch(rem);
    return rem;
  }, [jstr(valfilt), tab]);

  const filthids = useMemo(() => {
    // if (nils(valfilt)) return hids;
    // else {
    let nhids = filt_ar_using_filters({ ar: _.map(bikesob), filters: filt });
    nhids = _.map(nhids, "hid");
    nhids = _.sortBy(nhids, (hid) => hids.indexOf(hid));
    // console.log("nhids", nhids);
    return nhids;
    // }
  }, [filt, jstr(hids), jstr(bikesob)]);
  /* useEffect(() => {
    console.log("filthids", filthids);
  }, [filthids]); */

  const [rvmode, set_rvmode] = useState(
    g_acc_config("racedash.basefilt.rvmode", "bike"),
  );

  const [statssp, set_statssp] = useState(
    g_acc_config("racedash.basefilt.statssp", "all"),
  );
  const [sorts, set_sorts] = useState([]);

  const cb = getv(filt, "cb.vals") ?? "all";
  const payout = getv(filt, "payout.vals") ?? null;

  const qshscs = useStepQuery({
    q_: q_hstats_doc,
    par_ar: hids.map((hid) => [{ hid, rvmode, sp: statssp }]),
    lim: 5,
    options: { enabled: tab == "bikestats" },
  });
  const hstatsob = useMemo(() => {
    let o = {};
    for (let q of qshscs.qs) {
      if (!qissuccesss(q)) continue;
      let hid = getv(q, "data.result.hid");
      let ds = getv(q, "data.result.data");
      let ps = getv(q, "data.result.payouts_data");
      o[hid] = {
        ...ds,
        payouts_data: ps,
      };
    }
    // console.log("hstatsob", o);
    return o;
  }, [jstr(qshscs.qs.map((q) => q.dataUpdatedAt))]);

  const qshps = useStepQuery({
    q_: q_price,
    par_ar: hids.map((hid) => [{ hid }]),
    lim: 5,
    options: { enabled: true },
  });
  const hpriceob = useMemo(() => {
    let o = {};
    for (let q of qshps.qs) {
      if (!qissuccesss(q)) continue;
      let hid = getv(q, "data.result.hid");
      let s = getv(q, "data.result");
      o[hid] = s;
    }
    return o;
  }, [jstr(qshps.qs.map((q) => q.dataUpdatedAt))]);

  const hsob = useMemo(() => {
    let o = {};
    hids.map((hid) => {
      o[hid] = _.cloneDeep(bikesob[hid]) || {};
      let statpath = !nils(payout)
        ? `${hid}.payouts_data.${payout}.${cb == "all" ? "career" : cb}`
        : `${hid}.${cb == "all" ? "career" : cb}`;
      // let statpath = `${hid}.${cb == "all" ? "career" : cb}`;
      let s = getv(hstatsob, statpath) || {};
      let p = getv(hpriceob, `${hid}`);
      o[hid].s = s;
      o[hid].price = p;
    });
    return o;
  }, [jstr(bikesob), jstr(hstatsob), jstr(hpriceob), cb, payout]);

  const sort_ob = {
    hid: { disp: "hid", fn: (i1) => sort_fn(i1, "hid", "n") },
    name: { disp: "Name", fn: (i1) => sort_fn(i1, "name", "txt") },
    element: {
      disp: "El",
      fn: (i1) => sort_fn(i1, "element", "txt"),
    },
    type: { disp: "Ty", fn: (i1) => sort_fn(i1, "type", "txt") },
    gender: { disp: "G", fn: (i1) => sort_fn(i1, "gender", "txt") },
    fno: { disp: "F", fn: (i1) => sort_fn(i1, "fno", "n") },

    races_n: { disp: "#Race", fn: (i1) => sort_fn(i1, "s.races_n", "n") },
    win_n: { disp: "w", fn: (i1) => sort_fn(i1, "s.p2_n", "n") },
    p2_n: { disp: "2", fn: (i1) => sort_fn(i1, "s.p2_n", "n") },
    p3_n: { disp: "3", fn: (i1) => sort_fn(i1, "s.p3_n", "n") },
    win_p: { disp: "Win%", fn: (i1) => sort_fn(i1, "s.win_p", "n") },

    paid_races_n: {
      disp: "#Race",
      fn: (i1) => sort_fn(i1, "s.paid_races_n", "n"),
    },
    paid_win_n: { disp: "w", fn: (i1) => sort_fn(i1, "s.paid_p2_n", "n") },
    paid_p2_n: { disp: "2", fn: (i1) => sort_fn(i1, "s.paid_p2_n", "n") },
    paid_p3_n: { disp: "3", fn: (i1) => sort_fn(i1, "s.paid_p3_n", "n") },
    paid_win_p: { disp: "Win%", fn: (i1) => sort_fn(i1, "s.paid_win_p", "n") },
    profit_weth: {
      disp: "Profit WETH",
      fn: (i1) => sort_fn(i1, "s.profit", "n"),
    },
    profit_dez: {
      disp: "Profit DEZ",
      fn: (i1) => sort_fn(i1, "s.profit_dez", "n"),
    },

    price_dna: {
      disp: "Price DNA",
      fn: (i1) => sort_fn(i1, "price.dna.amt", "n"),
    },
    price_os: {
      disp: "Price OS",
      fn: (i1) => sort_fn(i1, "price.os.amt", "n"),
    },

    bluestar_p: {
      disp: (
        <div className="fr-sc text-blue-400">
          <FontAwesomeIcon icon={faStar} />
          <span>%</span>
        </div>
      ),
      fn: (i1) => sort_fn(i1, "s.bluestar_p", "n"),
    },
    yellowstar_p: {
      disp: (
        <div className="fr-sc text-yellow-400">
          <FontAwesomeIcon icon={faStar} />
          <span>%</span>
        </div>
      ),
      fn: (i1) => sort_fn(i1, "s.yellowstar_p", "n"),
    },

    // dna_price: { disp: "DNA Price", fn: (i1) => sort_fn(i1, "dna.amt", "n") },
    // os_price: { disp: "OS Price", fn: (i1) => sort_fn(i1, "os.amt", "n") },
  };
  const sorted_hids = useMemo(() => {
    if (_.isEmpty(filthids)) return [];

    let ar = sort_listob({
      list: filthids.map((hid) => hsob[hid]),
      sorts,
      sort_ob,
      id: "hid",
    });
    return ar;
  }, [jstr(hsob), jstr(sorts), jstr(sort_ob), jstr(filthids)]);

  useEffect(() => {
    s_acc_config("vaultrvmode.basefilt.rvmode", rvmode);
  }, [rvmode]);

  const vcon = {
    vault,
    is_auvault,
    vdoc,

    qo_vaultinfo,
    qo_vaultbikes,

    editmode,
    set_editmode,

    editing,
    set_editing,
    apply_updprofile,

    qst_bdocs,
    bikesob,

    filt,
    set_filt,

    clear_filt,

    filthids,
    hids,
    filthids,
    hsob,
    sorted_hids,

    rvmode,
    set_rvmode,

    sorts,
    set_sorts,
    sort_ob,

    tab,
    set_tab,

    statssp,
    set_statssp,
  };

  const pagetitle = useMemo(() => {
    let a = "Racing Dashboard";
    if (nils(vdoc)) return `${a} ${vault} | FBike DNA`;
    let rn = vdoc.vault_name;
    return `${a} '${rn}' | FBike DNA`;
  }, [vdoc]);

  return (
    <div className="">
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[80rem] mx-auto">
          <div className="h-[5rem]"></div>
          <VaultContext.Provider value={vcon}>
            <VaultNameCard />
            <div class="h-[5rem]"></div>
            <div class="ms-auto lg:max-w-[75rem] lg:grid lg:grid-cols-4 xs:grid xs:grid-cols-2 gap-2 my-2">
              {[
                ["/fqual", "Events"],
                ["/scoreboard", "Scoreboard"],
                [`/ranks`, "Ranks"],
                [`/vault/${vault}`, "Vault"],
                // null,
                [`/quest-cards`, "Quest Cards"],
                [`/trails-cards`, "Trails Cards"],
              ].map((e) => {
                if (nils(e)) return <div class="col-span-1"></div>;
                let [to, txt] = e;
                return (
                  <Link to={to}>
                    <SkewBtn
                      cont_cn="xs:w-[10rem] lg:w-[15rem]"
                      key={txt}
                      text={
                        <span
                          className={twMerge(
                            "font-digi ",
                            txt.length > 10 ? "resp-text-0" : "resp-text-1",
                          )}
                        >
                          {txt}
                        </span>
                      }
                    />
                  </Link>
                );
              })}
            </div>

            <GlowBtnGroup
              {...{
                options: [
                  "lobby",
                  "active",
                  "results",
                  "bikestats",
                  "race_ledger",
                ],
                selected: tab,
                opmap: _.chain([
                  ["lobby", "Lobby", "stats"],
                  ["active", "Active", "c50"],
                  ["bikestats", "Stats", "stats"],
                  ["results", "Results", "result"],
                  ["race_ledger", "Ledger", "result"],
                ])
                  .map(([c, text, svg_k]) => {
                    return [c, { text, svg: svg_map[svg_k] }];
                  })
                  .fromPairs()
                  .value(),
                setter: set_tab,
              }}
            />
            <div class="fr-sc my-2 mx-auto max-w-[95vw] w-[50rem]">
              <div class="flex-1"></div>
            </div>

            {tab == "lobby" && (
              <RacesContextWrapper>
                <RacesLobbyView />
              </RacesContextWrapper>
            )}
            {tab == "bikestats" && <BikeStatsView />}
            {tab == "results" && <RacesResultsView />}
            {tab == "race_ledger" && <LedgerInner vault={vault} />}
            {tab == "active" && <ActiveRacesView />}

            <div class="h-[5rem]"></div>
          </VaultContext.Provider>
        </div>
      </div>
    </div>
  );
};

export const RacingDashBackLink = () => {
  const aucon = useAuthContext();
  const { vault } = aucon;

  return (
    <div class="fr-sc my-2 w-full">
      <Link to={`/racing-dash/${vault}`}>
        <Tag className="text-acc4 -skew-x-12 border border-acc4 bg-r2lig/20">
          <div class="fr-sc resp-gap-2 resp-text-0">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span class="">Back to My Racing Dashboard</span>
          </div>
        </Tag>
      </Link>
    </div>
  );
};

export default RacingDashboard;
