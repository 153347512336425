import React, { useEffect, useState } from "react";
import { getv, iso } from "../utils/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import { Img, Tag } from "../components/utilityComps.js";
import { useAppContext } from "../App.js";

const base_s3 = "https://cdn.dnaracing.run/help_imgs";

const tmap = _.chain([
  [
    "getting started",
    [
      // "https://i.imgur.com/OZKpeVF.jpg",
      // "https://i.imgur.com/7rROJb5.jpg"
      `${base_s3}/getting-started-01.png`,
      `${base_s3}/getting-started-02.png`,
    ],
  ],
  [
    "splicing",
    [
      // "https://i.imgur.com/n0ZiNLg.jpg",
      // "https://i.imgur.com/xTb3pBQ.jpg",
      // "https://i.imgur.com/y6hfTQq.jpg",
      // "https://i.imgur.com/rR8roLJ.jpg",
      // "https://i.imgur.com/RLEeBWH.jpg",
      // "https://i.imgur.com/GZQbVGD.jpg",
      // "https://i.imgur.com/DU3i8MM.jpg",
      // "https://i.imgur.com/3bS3AVZ.jpg",
      // "https://i.imgur.com/mO0T04f.jpg",
      // "https://i.imgur.com/p1WOpSO.jpg",
      `${base_s3}/splicing-08.png`,
      `${base_s3}/splicing-09.png`,
      `${base_s3}/splicing_breed_cycles.png`,
    ],
  ],
  [
    "skins",
    [
      // "https://i.imgur.com/LQSGZcx.jpg",
      // "https://i.imgur.com/GxGN1OI.jpg",
      // "https://i.imgur.com/kFHVOwZ.jpg",
      // "https://i.imgur.com/IIHw9u5.jpg",
      // "https://i.imgur.com/woKKiMV.jpg",
      `${base_s3}/skins-04.png`,
      `${base_s3}/skins-05.png`,
    ],
  ],
  [
    "tokens",
    [
      // "https://i.imgur.com/6gaIEta.jpg",
      // "https://i.imgur.com/IIHw9u5.jpg",
      // "https://i.imgur.com/3bS3AVZ.jpg",
      // "https://i.imgur.com/TMrH606.jpg",
      // "https://i.imgur.com/mO0T04f.jpg",
      // "https://i.imgur.com/JEyiolM.jpg",
      // "https://i.imgur.com/cQ5oODH.jpg",
      `${base_s3}/tokens-02.png`,
      `${base_s3}/tokens-03.png`,
      `${base_s3}/tokens-04.png`,
      `${base_s3}/tokens-05.png`,
      `${base_s3}/tokens-06.png`,
      `${base_s3}/tokens-07.png`,
    ],
  ],
  [
    "racing",
    [
      // "https://i.imgur.com/1BOIt46.jpg",
      // "https://i.imgur.com/fCzax95.jpg",
      // "https://i.imgur.com/HqBneFX.jpg",
      // "https://i.imgur.com/1DC5GwX.jpg",
      // "https://i.imgur.com/UPMkWzJ.jpg",
      // "https://i.imgur.com/oZgmb0A.jpg",
      // "https://i.imgur.com/UnQE5LR.jpg",
      // "https://i.imgur.com/3XQrP2q.jpg",
      // "https://i.imgur.com/o1j897o.jpg",
      // "https://i.imgur.com/cn4DA1i.jpg",
      // "https://i.imgur.com/6QNmmC4.jpg",
      // "https://i.imgur.com/DU7vS20.jpg",
      // "https://i.imgur.com/qaV6wHz.jpg",
      // "https://i.imgur.com/ctxWApP.jpg",
      // "https://i.imgur.com/RVhqWVP.jpg",
      `${base_s3}/racing-09.png`,
      `${base_s3}/racing-10.png`,
      `${base_s3}/racing-11.png`,
      `${base_s3}/racing-12.png`,
      `${base_s3}/racing-13.png`,
      `${base_s3}/racing-14.png`,
      `${base_s3}/raing-star.png`,
    ],
  ],
  [
    "market",
    [
      // "https://i.imgur.com/NbSQ0Ca.jpg",
      // "https://i.imgur.com/7YYK5lZ.jpg",
      // "https://i.imgur.com/31mLNG2.jpg",
    ],
  ],
  [
    "help",
    [
      // "https://i.imgur.com/FhCxQaX.jpg",
      // "https://i.imgur.com/fq5TTcM.jpg"
      `${base_s3}/help-01.png`,
      `${base_s3}/help-02.png`,
    ],
  ],
])
  .map((e) => ({ tab: e[0], imgs: e[1] }))
  .keyBy("tab")
  .value();

function HelpPage() {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const [tab, set_tab] = useState(psearch.tab ?? "splicing");
  useEffect(() => {
    upd_psearch({ tab });
  }, [tab]);

  return (
    <div className="h-page">
      <div className="max-w-[95vw] w-[60rem] mx-auto">
        <div className="h-[3rem]"></div>
        <div className="text-acc0 resp-text-1 text-center my-2">
          <FontAwesomeIcon icon={faQuestionCircle} />
          <span>{" Help"}</span>
        </div>

        <div className="fr-cc resp-gap-1 my-2">
          {[
            "getting started",
            "splicing",
            "skins",
            "tokens",
            "racing",
            "market",
            "help",
          ].map((_tab) => {
            let active = tab === _tab;
            return (
              <Tag
                onClick={() => set_tab(_tab)}
                className={twMerge(
                  "resp-text--1 font-digi",
                  active ? "text-acc0 bg-reg/40 -skew-x-12" : "text-white",
                )}
              >
                {_.chain(_tab)
                  .split(" ")
                  .map((e) => _.capitalize(e))
                  .join(" ")
                  .value()}
              </Tag>
            );
          })}
        </div>

        {tab && tmap[tab] && (
          <div className="mx-auto w-[40rem] max-w-full">
            {tmap[tab].imgs.map((e, ei) => {
              // console.log(e);
              return (
                <div className="" key={`${tab}-${ei}`}>
                  <Img img={e} className="w-full" />
                </div>
              );
            })}
          </div>
        )}

        <div className="h-[5rem]"></div>
      </div>
    </div>
  );
}

export default HelpPage;
