import React from "react";
import _ from "lodash";
import { InpText, Tag } from "./utilityComps.js";
import { cdelay, getv, iso, jstr, nano, nils } from "../utils/utils.js";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { AnimatePresence } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCheckSquare,
  faPlus,
  faSquare,
  faSquareMinus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { Mo, MoVariants } from "../utils/motion_helper.js";
import { get_cprofile_hex } from "../utils/cn_map.js";

export const Input = ({
  id,
  setter,
  defaultValue,
  placeholder = "Enter",
  autoComplete = "on",
  show_btn = <>SET</>,
  cont_cn = "",
  inp_cn = "",
  btn_cn = "",
  bg_c = "bg-red-400",
  border_c = "border-red-400",
  keep_track = false,
  onBlur = null,
  type = "text",
}) => {
  useEffect(() => {
    // let val =
    // if(defaultValue!==)
    set_val_inp(id, defaultValue);
  }, [defaultValue]);
  return (
    <div className="flex flex-row justify-center">
      <div
        className={`
          rounded-md
          h-min
          xs:border lg:border-2
          ${border_c}
          focus-within:shadow-lg
          focus-within:outline-none
          ease-in duration-300
          ${cont_cn}
        `}
      >
        <div className="w-1"></div>
        <input
          className={`
          outline:none
          focus:outline-none
          text-center
          bg-dark
          text-white resp-p-1
          focus:${bg_c}
          ease-in duration-300
          ${inp_cn}

          m-0
          `}
          id={id}
          placeholder={placeholder}
          type={type}
          defaultValue={defaultValue}
          onKeyDown={(e) => e.keyCode == 13 && setter()}
          onBlur={onBlur || setter}
          autoComplete={autoComplete}
        />
        {show_btn !== false && (
          <button
            onClick={setter}
            className={twMerge(bg_c, `resp-p-1 resp-pr-2 resp-pl-2 `, btn_cn)}
          >
            {show_btn}
          </button>
        )}
      </div>
    </div>
  );
};

export const DateInput = ({
  id,
  setter = () => {},
  defaultValue,
  placeholder = "Enter",
  autoComplete = "on",
  show_btn = <>SET</>,
  cont_cn = "",
  inp_cn = "",
  btn_cn = "",
  bg_c = "bg-dark",
  border_c = "border-acc0",
  keep_track = false,
}) => {
  const [date, set_date] = useState(undefined);
  useEffect(() => {
    // console.log(defaultValue, moment(new Date(defaultValue)).isValid());
    if (!defaultValue || nano(defaultValue) == 0) {
      return set_date(undefined);
    }
    if (moment(new Date(defaultValue)).isValid())
      set_date(new Date(defaultValue));
    else set_date(undefined);
  }, [defaultValue]);
  useEffect(() => {
    if (nano(date) == 0) {
      return set_date(undefined);
    }
    if (moment(new Date(date)).isValid())
      setter(moment(new Date(date)).toISOString());
  }, [date]);
  return (
    <div className="flex flex-row justify-center">
      <div
        className={`
          rounded-md
          h-min
          border
          ${border_c}
          focus-within:shadow-lg
          ease-in duration-300
          ${cont_cn}
        `}
      >
        <div className="w-1"></div>
        <DatePicker
          id={id}
          showTimeSelect
          dateFormat="Pp"
          placeholderText={placeholder}
          autoComplete="off"
          className="bg-dark px-4 py-2 text-center"
          value={() => moment(date).format("DD-MM-YYYY HH:mmA")}
          {...(!date ? {} : { selected: date })}
          onChange={(date) => set_date(date)}
        />
      </div>
    </div>
  );
};

export const TWDateInput = () => {
  return <></>;
};

export const extract_inp = (id, type = "txt") => {
  let el = document.getElementById(id);
  if (!el) return undefined;
  let val = el.value;
  if (!val) return undefined;
  if (type == "n") {
    val = parseFloat(val);
    if (!_.isNaN(val)) return val;
    else return undefined;
  }
  return val;
};
export const set_val_inp = (id, txt = "") => {
  let el = document.getElementById(id);
  if (!el) return;
  el.value = txt;
};

export const set_state_ob_inp = (init, set, id, path, type = "txt") => {
  try {
    let ob = _.cloneDeep(init);
    let val = extract_inp(id, type);
    _.set(ob, path, val);
    // console.log(ob);
    set(ob);
  } catch (err) {}
};
export const set_state_ob = (init, set, path, val) => {
  try {
    if (nils(path)) set(val);
    else {
      let ob = _.cloneDeep(init);
      _.set(ob, path, val);
      set(ob);
    }
  } catch (err) {}
};

export const InpRangeWrap = ({ idd, fkey, filters, set_filters }) => {
  const id_gen = (k) => `_${fkey}_${idd}_${k}`;
  const type = getv(filters, `${idd}.type`) ?? [];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const cn = getv(filters, `${idd}.cn`) ?? "border border-acc4";
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_cn =
    getv(filters, `${idd}.label_cn`) ??
    "text-acc0 italic bg-transparent font-digi";
  const show_label = getv(filters, `${idd}.show_label`) ?? true;
  const autoComplete = getv(filters, `${idd}.autoComplete`) ?? "off";

  return (
    <div className="flex flex-row mb-1 flex-nowrap w-full items-center">
      {show_label && (
        <div className="flex flex-row mb-1 flex-wrap">
          <Tag className={twMerge(cn, color_fn("label"), label_cn)}>
            {label}
          </Tag>
        </div>
      )}
      <div className="flex flex-row mb-1 flex-wrap flex-1 items-center resp-gap-1">
        {[
          ["mi", "Min"],
          ["mx", "Max"],
        ].map(([k, txt]) => (
          <Input
            {...{
              id: id_gen(k),
              key: id_gen(k),
              show_btn: false,
              inp_cn: twMerge("w-[4rem] bg-r2dark/60 resp-text-1", cn),
              cont_cn: cn,
              border_c: cn,
              placeholder: txt,
              defaultValue: getv(filters, `${idd}.vals.${k}`),
              setter: () =>
                set_state_ob_inp(
                  filters,
                  set_filters,
                  id_gen(k),
                  `${idd}.vals.${k}`,
                  "n",
                ),
              autoComplete,
            }}
          />
        ))}
      </div>
    </div>
  );
};
export const InpOnlyWrap = ({
  idd,
  fkey,
  filters,
  set_filters,
  dir,
  placeholder = null,
  inptype = "n",
}) => {
  const label = getv(filters, `${idd}.label`) ?? "label";
  const id_gen = `_${fkey}_${idd}`;
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  return (
    <div className="flex flex-row mb-1 flex-nowrap w-full items-center">
      <div className="flex flex-row mb-1 flex-wrap">
        <Tag className={`${cn} ${color_fn("label")}`}>{label}</Tag>
      </div>
      <div className="flex flex-row mb-1 flex-wrap flex-1 items-center">
        {
          <Input
            {...{
              id: id_gen,
              key: id_gen,
              show_btn: false,
              inp_cn: "w-[4rem] " + cn,
              border_c: cn,
              placeholder: placeholder || "..",
              defaultValue: getv(filters, `${idd}.vals`),
              setter: () =>
                set_state_ob_inp(
                  filters,
                  set_filters,
                  id_gen,
                  `${idd}.vals`,
                  inptype,
                ),
            }}
          />
        }
      </div>
    </div>
  );
};

export const InpSwitch = ({ idd, fkey, filters, set_filters }) => {
  const id_gen = `_${fkey}_${idd}`;
  const val = getv(filters, `${idd}.vals`);
  // console.log("InpSwitch", val);
  const txt = getv(filters, `${idd}.txt`);
  const cn = getv(filters, `${idd}.cn`);
  const onClick = () => set_state_ob(filters, set_filters, `${idd}.vals`, !val);
  return (
    <Tag {...{ onClick, className: `${cn} ${val ? "" : "bg-opacity-40"}` }}>
      {txt}
    </Tag>
  );
};

export const def_txt_fn = (o) => o;
export const def_color_fn = (o) => "bg-green-500";
export const InpOptionsTdHead = ({ idd, fkey, filters, set_filters }) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  const actives = getv(filters, `${idd}.vals`) ?? [];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const add = (o) => _.uniq([...actives, o]);
  const remove = (o) => _.uniq(actives.filter((i) => i !== o));
  return (
    <>
      {options.map((option) => {
        let active = actives.includes(option);
        const click = () => {
          let nops;
          if (type == "options") nops = !active ? add(option) : remove(option);
          if (type == "options-only-ar") nops = !active ? [option] : [];
          set_state_ob(filters, set_filters, `${idd}.vals`, nops);
        };
        return (
          <Tag
            key={`${fkey}-${idd}-${option}`}
            className={bg_active(color_fn(option), active) + " " + cn}
            onClick={click}
          >
            {txt_fn(option)}
          </Tag>
        );
      })}
    </>
  );
};
export const InpSwitchWrap = ({
  idd,
  fkey,
  filters,
  set_filters,
  // show_label = true,
}) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  let actives = getv(filters, `${idd}.vals`) ?? [];

  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_cn =
    getv(filters, `${idd}.label_cn`) ??
    "text-slate-200 bg-transparent font-digi";
  const label_mini = getv(filters, `${idd}.label_mini`) ?? "label_mini";
  const show_label = getv(filters, `${idd}.show_label`) ?? true;

  const base_cn = getv(filters, `${idd}.base_cn`) ?? "";
  const cont_cn = getv(filters, `${idd}.cont_cn`) ?? "";
  const inner_cont_cn = getv(filters, `${idd}.inner_cont_cn`) ?? "";
  const active_cn =
    getv(filters, `${idd}.active_cn`) ??
    ((a, acode) => (a ? "" : "bg-opacity-20"));

  const modify = () => {
    let nops = nils(actives) ? true : !actives;
    set_state_ob(filters, set_filters, `${idd}.vals`, nops);
  };

  return (
    <div
      className={twMerge(
        "fr-sc mb-1 flex-nowrap items-start cursor-pointer",
        cont_cn,
      )}
    >
      {show_label && (
        <div
          onClick={() => {
            modify();
          }}
          className="flex flex-row mb-1 flex-wrap"
        >
          <Tag className={twMerge(cn, color_fn("label"), label_cn)}>
            {label}
          </Tag>
        </div>
      )}
      <div class="fr-sc overflow-hidden rounded-full bg-r2reg">
        {[true, false].map((t) => {
          return (
            <span
              onClick={() => {
                set_state_ob(filters, set_filters, `${idd}.vals`, t);
              }}
              className={twMerge(
                "text-center py-[2px] min-w-[4rem] resp-text--2 font-digi",
                actives == t ? "bg-r2lig" : "",
              )}
            >
              {t ? "ON" : "OFF"}
            </span>
          );
        })}
      </div>
      {/*
      <Tag
        className={twMerge(base_cn, cn, color_fn(actives), active_cn(actives))}
      >
        <span>{txt_fn(actives)}</span>
      </Tag>
      */}
    </div>
  );
};

export const InpBtnGroup = ({
  idd,
  fkey,
  filters,
  set_filters,
  // show_label = true,
}) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  let actives = getv(filters, `${idd}.vals`) ?? [];

  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_cn =
    getv(filters, `${idd}.label_cn`) ??
    "text-slate-200 bg-transparent font-digi";
  const label_mini = getv(filters, `${idd}.label_mini`) ?? "label_mini";
  const show_label = getv(filters, `${idd}.show_label`) ?? true;

  const base_cn = getv(filters, `${idd}.base_cn`) ?? "";
  const cont_cn = getv(filters, `${idd}.cont_cn`) ?? "";
  const inner_cont_cn = getv(filters, `${idd}.inner_cont_cn`) ?? "";
  const active_cn =
    getv(filters, `${idd}.active_cn`) ??
    ((a, acode) => (a ? "" : "bg-opacity-20"));

  const modify = () => {
    let nops = nils(actives) ? true : !actives;
    set_state_ob(filters, set_filters, `${idd}.vals`, nops);
  };

  return (
    <div
      className={twMerge(
        "fr-sc mb-1 flex-nowrap items-start cursor-pointer",
        cont_cn,
      )}
    >
      {show_label && (
        <div
          onClick={() => {
            modify();
          }}
          className="flex flex-row mb-1 flex-wrap"
        >
          <Tag className={twMerge(cn, color_fn("label"), label_cn)}>
            {label}
          </Tag>
        </div>
      )}
      <div class="fr-sc overflow-hidden rounded-full bg-r2reg">
        {options.map((t) => {
          return (
            <span
              onClick={() => {
                set_state_ob(filters, set_filters, `${idd}.vals`, t);
              }}
              className={twMerge(
                "text-center py-[2px] min-w-[4rem] resp-text--2 font-digi",
                actives == t ? "bg-r2lig" : "",
              )}
            >
              {txt_fn(t)}
            </span>
          );
        })}
      </div>
      {/*
      <Tag
        className={twMerge(base_cn, cn, color_fn(actives), active_cn(actives))}
      >
        <span>{txt_fn(actives)}</span>
      </Tag>
      */}
    </div>
  );
};

export const Inp_OptionBtn_R2_rounded = ({ op, active, txt_fn, ...props }) => {
  const text = txt_fn(op);

  const c0 = get_cprofile_hex("acc0");
  const c4 = get_cprofile_hex("acc4");

  return (
    <div
      {...props}
      class={twMerge(
        "fc-cs relative min-w-[6.5rem] aspect-[88/33]  m-[0.5rem]",
        "transition duration-200 cursor-pointer",
        text.length > 10 ? "w-[9rem] aspect-[10/2.5]" : "w-[6rem]",
      )}
    >
      <div class="absolute z-[2] top-[-4px] right-[-4px]">
        {active ? (
          <div class="fc-cc p-[4px] bg-red-400 rounded-full ">
            <FontAwesomeIcon icon={faTimes} />
          </div>
        ) : (
          <div class="fc-cc p-[4px] bg-r2lig rounded-full ">
            <FontAwesomeIcon icon={faPlus} />
          </div>
        )}
      </div>
      <div
        class={twMerge(
          "absolute z-[1] top-0 left-0 w-full h-full",
          "transition duration-500",
          active ? "" : "",
        )}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 88 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.613898 16.5C0.613898 25.2737 7.72637 32.3862 16.5001 32.3862L71.5001 32.3862C80.2738 32.3862 87.3862 25.2737 87.3862 16.5C87.3862 7.72631 80.2738 0.613838 71.5001 0.613838L16.5001 0.613838C7.72638 0.613838 0.613898 7.72631 0.613898 16.5Z"
            fill={c0}
            fill-opacity="0.3618"
            stroke="url(#paint0_linear_61_37)"
            stroke-width="1.22767"
          />
          <defs>
            <linearGradient
              id="paint0_linear_61_37"
              x1="-3.1428"
              y1="5.86667"
              x2="95.4355"
              y2="13.7774"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={c0} />
              <stop offset="1" stop-color={c4} />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="absolute left-[20%] z-[3] w-full">
        <span
          className={twMerge(
            text.length < 5
              ? "resp-text--1"
              : text.length < 7
                ? "resp-text--2"
                : "resp-text--3",
            active ? "" : "",
          )}
        >
          {text}
        </span>
      </div>
    </div>
  );
};

export const InpOptionsWrap_Raw = ({
  idd,
  fkey,
  filters,
  set_filters,
  // show_label = true,
}) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  let actives = getv(filters, `${idd}.vals`) ?? [];
  if (type == "options-only") actives = [actives];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const add = (o) => _.uniq([...actives, o]);
  const remove = (o) => _.uniq(actives.filter((i) => i !== o));
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_cn =
    getv(filters, `${idd}.label_cn`) ??
    "text-acc0 italic bg-transparent font-digi";
  const label_mini = getv(filters, `${idd}.label_mini`) ?? "label_mini";
  const show_label = getv(filters, `${idd}.show_label`) ?? true;

  const base_cn = getv(filters, `${idd}.base_cn`) ?? "";
  const cont_cn = getv(filters, `${idd}.cont_cn`) ?? "";
  const inner_cont_cn = getv(filters, `${idd}.inner_cont_cn`) ?? "";
  const active_cn =
    getv(filters, `${idd}.active_cn`) ??
    ((a, acode) => (a ? "" : "bg-opacity-20"));

  // useEffect(() => {
  //   console.log("filt", idd, options);
  // }, [idd, jstr(options)]);

  return (
    <div
      className={twMerge("flex flex-row mb-1 flex-nowrap items-start", cont_cn)}
    >
      {show_label && (
        <div className="flex flex-row mb-1 flex-wrap">
          <Tag className={twMerge(cn, color_fn("label"), label_cn)}>
            {label}
          </Tag>
        </div>
      )}
      <div
        className={twMerge(
          "flex flex-row mb-1 flex-wrap flex-1",
          inner_cont_cn,
        )}
      >
        {options.map((option) => {
          let active = actives.includes(option);
          const click = () => {
            let nops;
            if (type == "options")
              nops = !active ? add(option) : remove(option);
            if (type == "options-only-ar") nops = !active ? [option] : [];
            if (type == "options-only") nops = !active ? option : undefined;
            set_state_ob(filters, set_filters, `${idd}.vals`, nops);
          };
          return (
            <Inp_OptionBtn_R2_rounded
              {...{
                key: `${fkey}-${idd}-${option}`,
                op: option,
                active: active,
                txt_fn: txt_fn,
                onClick: click,
                className: twMerge(base_cn, cn, color_fn(option)),
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export const InpOptionsWrap = ({
  idd,
  fkey,
  filters,
  set_filters,
  // show_label = true,
}) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  let actives = getv(filters, `${idd}.vals`) ?? [];
  if (type == "options-only") actives = [actives];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const add = (o) => _.uniq([...actives, o]);
  const remove = (o) => _.uniq(actives.filter((i) => i !== o));
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_cn =
    getv(filters, `${idd}.label_cn`) ??
    "text-acc0 italic bg-transparent font-digi";
  const label_mini = getv(filters, `${idd}.label_mini`) ?? "label_mini";
  const show_label = getv(filters, `${idd}.show_label`) ?? true;

  const base_cn = getv(filters, `${idd}.base_cn`) ?? "";
  const cont_cn = getv(filters, `${idd}.cont_cn`) ?? "";
  const inner_cont_cn = getv(filters, `${idd}.inner_cont_cn`) ?? "";
  const active_cn =
    getv(filters, `${idd}.active_cn`) ??
    ((a, acode) => (a ? "" : "bg-opacity-20"));

  return (
    <div
      className={twMerge("flex flex-row mb-1 flex-nowrap items-start", cont_cn)}
    >
      {show_label && (
        <div className="flex flex-row mb-1 flex-wrap">
          <Tag className={twMerge(cn, color_fn("label"), label_cn)}>
            {label}
          </Tag>
        </div>
      )}
      <div
        className={twMerge(
          "flex flex-row mb-1 flex-wrap flex-1",
          inner_cont_cn,
        )}
      >
        {options.map((option) => {
          let active = actives.includes(option);
          const click = () => {
            let nops;
            if (type == "options")
              nops = !active ? add(option) : remove(option);
            if (type == "options-only-ar") nops = !active ? [option] : [];
            if (type == "options-only") nops = !active ? option : undefined;
            set_state_ob(filters, set_filters, `${idd}.vals`, nops);
          };
          return (
            <Tag
              key={`${fkey}-${idd}-${option}`}
              className={twMerge(
                base_cn,
                cn,
                color_fn(option),
                active_cn(active, option),
              )}
              onClick={click}
            >
              {txt_fn(option)}
            </Tag>
          );
        })}
      </div>
    </div>
  );
};
export const InpCheckboxWrap = ({
  idd,
  fkey,
  filters,
  set_filters,
  // show_label = true,
}) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  let actives = getv(filters, `${idd}.vals`) ?? [];
  if (type == "options-only") actives = [actives];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const add = (o) => _.uniq([...actives, o]);
  const remove = (o) => _.uniq(actives.filter((i) => i !== o));
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_mini = getv(filters, `${idd}.label_mini`) ?? "label_mini";
  const show_label = getv(filters, `${idd}.show_label`) ?? true;

  const base_cn = getv(filters, `${idd}.base_cn`) ?? "";
  const cont_cn = getv(filters, `${idd}.cont_cn`) ?? "";
  const inner_cont_cn = getv(filters, `${idd}.inner_cont_cn`) ?? "";
  const active_cn =
    getv(filters, `${idd}.active_cn`) ??
    ((a, acode) => (a ? "" : "bg-opacity-20"));

  const [expand, set_expand] = useState(false);

  const clear = () => {
    let nops = null;
    if (type == "checkbox") nops = [];
    if (type == "checkbox-only-ar") nops = [];
    if (type == "checkbox-only") nops = null;
    set_state_ob(filters, set_filters, `${idd}.vals`, nops);
  };
  const actives_n = actives?.length ?? 0;

  return (
    <div
      className={twMerge(
        "flex flex-col mb-1 flex-nowrap items-start border-b border-acc0/30",
        cont_cn,
      )}
    >
      <div className="fr-sc w-full resp-p-2 mb-1 cursor-pointer">
        <div onClick={() => set_expand(!expand)} className="flex-1 ">
          <div className="flex flex-row mb-1 flex-wrap">
            <Tag className={`${cn} ${color_fn("label")}`}>{label}</Tag>
          </div>
        </div>
        {actives_n > 0 ? (
          <Tag
            onClick={() => {
              clear();
              setTimeout(() => {
                set_expand(false);
              }, 50);
            }}
            className="text-red-400 rounded-[100px] border border-red-400 resp-px-2 text-[10px]"
          >
            clear
          </Tag>
        ) : (
          <></>
        )}
        <Tag onClick={() => set_expand(!expand)} className="resp-text-1">
          <FontAwesomeIcon icon={expand ? faAngleUp : faAngleDown} />
        </Tag>
      </div>
      {
        <motion.div
          variants={MoVariants.show_hide}
          animate={expand ? "visible" : "hidden"}
          className={twMerge(
            "bg-dark border-x-4 rounded-md border-x-reg pl-4 w-full",
            inner_cont_cn,
          )}
        >
          {options.map((option) => {
            let active = actives.includes(option);
            const click = () => {
              let nops;
              if (type == "checkbox")
                nops = !active ? add(option) : remove(option);
              if (type == "checkbox-only-ar") nops = !active ? [option] : [];
              if (type == "checkbox-only") nops = !active ? option : undefined;
              set_state_ob(filters, set_filters, `${idd}.vals`, nops);
            };
            return (
              <Tag
                key={`${fkey}-${idd}-${option}`}
                className={twMerge(
                  "fr-sc resp-gap-2",
                  base_cn,
                  cn,
                  color_fn(option),
                  active_cn(active, option),
                )}
                onClick={click}
              >
                <FontAwesomeIcon
                  icon={active ? faCheckSquare : faSquareMinus}
                />
                <span>{txt_fn(option)}</span>
                <div className="flex-1"></div>
              </Tag>
            );
          })}
        </motion.div>
      }
    </div>
  );
};
export const bg_active = (c, active) =>
  `${c} ${active ? "border-2 border-white" : "bg-opacity-30"}`;

export const InpDatesWrap = ({ idd, fkey, filters, set_filters }) => {
  const type = getv(filters, `${idd}.type`) ?? [];
  const options = getv(filters, `${idd}.options`) ?? [];
  const actives = getv(filters, `${idd}.vals`) ?? [];
  const color_fn = getv(filters, `${idd}.color_fn`) ?? def_color_fn;
  const txt_fn = getv(filters, `${idd}.txt_fn`) ?? def_txt_fn;
  const cn = getv(filters, `${idd}.cn`) ?? " ";
  const add = (o) => _.uniq([...actives, o]);
  const remove = (o) => _.uniq(actives.filter((i) => i !== o));
  const label = getv(filters, `${idd}.label`) ?? "label";
  const label_mini = getv(filters, `${idd}.label_mini`) ?? "label_mini";
  const id_gen = (k) => `_${fkey}_${idd}_${k}`;
  return (
    <div className="flex flex-row mb-1 flex-nowrap w-full items-center">
      <div className="flex flex-row mb-1 flex-wrap">
        <Tag className={`${cn} ${color_fn("label")}`}>{label}</Tag>
      </div>
      <div className="flex flex-row mb-1 flex-wrap flex-1 items-center">
        {[
          ["mi", "Start Date"],
          ["mx", "End Date"],
        ].map(([k, txt]) => (
          <DateInput
            {...{
              id: id_gen(k),
              key: id_gen(k),
              show_btn: false,
              inp_cn: "w-[8rem]",
              placeholder: txt,
              defaultValue: getv(filters, `${idd}.vals.${k}`),
              setter: (val) =>
                set_state_ob(filters, set_filters, `${idd}.vals.${k}`, val),
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const InpDatesRange = ({ idd, label, init, set }) => {
  const cn = "";
  const id_gen = (k) => `_${idd}_${k}`;
  return (
    <div className="flex flex-row mb-1 flex-nowrap w-full items-center">
      <div className="flex flex-row mb-1 flex-wrap">
        <Tag className={`${cn}`}>{label}</Tag>
      </div>
      <div className="flex flex-row mb-1 flex-wrap flex-1 items-center">
        {[
          ["st", "Start Date"],
          ["ed", "End Date"],
        ].map(([k, txt], idx) => (
          <React.Fragment key={id_gen(k)}>
            <DateInput
              {...{
                id: id_gen(k),
                show_btn: false,
                inp_cn: "w-[8rem]",
                placeholder: txt,
                defaultValue: getv(init, `${k}`),
                setter: (val) => set_state_ob(init, set, `${k}`, val),
              }}
            />
            {idx == 0 && <Tag>to</Tag>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const InpAutoWrap = (props) => {
  const type = getv(props, `filters.${props.idd}.type`) ?? null;
  if (type == "options") return <InpOptionsWrap {...props} />;
  if (type == "options-only-ar") return <InpOptionsWrap {...props} />;
  if (type == "options-only") return <InpOptionsWrap {...props} />;
  if (type == "switch") return <InpSwitchWrap {...props} />;
  if (type == "btngroup") return <InpBtnGroup {...props} />;
  if (type == "checkbox") return <InpCheckboxWrap {...props} />;
  if (type == "checkbox-only-ar") return <InpCheckboxWrap {...props} />;
  if (type == "checkbox-only") return <InpCheckboxWrap {...props} />;
  if (type == "date-range") return <InpDatesWrap {...props} />;
  if (type == "range") return <InpRangeWrap {...props} />;
  if (type == "only") return <InpOnlyWrap {...props} />;
  if (type == "inptext") return <InpOnlyWrap {...props} type="txt" />;
  return <></>;
};

export const LabInpWrap = ({
  tagw = "",
  id,
  init,
  set,
  k,
  placeholder,
  tlabel,
  ...props
}) => {
  return (
    <div className="flex flex-row mb-1 items-center flex-wrap">
      <Tag className={`${tagw}`}>{tlabel}</Tag>
      <Input
        {...{
          ...props,
          id: id,
          placeholder: placeholder,
          setter: () => set_state_ob_inp(init, set, id, k),
          defaultValue: getv(init, k),
          show_btn: false,
        }}
      />
    </div>
  );
};

export const LabInpWrapS = ({
  tagw = "",
  id,
  init,
  set,
  placeholder,
  tlabel,
  ...props
}) => {
  return (
    <div className="flex flex-row mb-1 items-center">
      <div className={`${tagw}`}>{tlabel}</div>
      <Input
        {...{
          ...props,
          id: id,
          placeholder: placeholder,
          setter: () => set(extract_inp(id)),
          defaultValue: init,
          show_btn: false,
          keep_track: true,
        }}
      />
    </div>
  );
};

export const blur_all_inps_in = async (sel) => {
  await cdelay(500);
  let els = document.querySelectorAll(`${sel} input`);
  // console.log(els);
  Array.from(els).map((e) => e.blur());
};

export const F_DateInput = ({
  nf,
  set_nf,
  nfkey,
  path,
  type = "iso",
  placeholder,
}) => {
  return (
    <DateInput
      id={`${nfkey}-${path}`}
      defaultValue={getv(nf, path)}
      placeholder={placeholder}
      setter={() => {
        let val = extract_inp(`${nfkey}-${path}`);
        if (type == "iso") val = iso(val);
        set_state_ob(nf, set_nf, path, val);
      }}
    />
  );
};

export const F_Input = ({
  nf,
  set_nf,
  nfkey,
  path,
  type,
  disp_txt = null,
  el_postprocess_fn = (i) => i,
  ...props
}) => {
  return (
    <InpText
      {...props}
      id={`${nfkey}-${path}`}
      def_val={disp_txt ? disp_txt(getv(nf, path)) : getv(nf, path)}
      setter={() => {
        let val = extract_inp(`${nfkey}-${path}`);
        if (type == "n") {
          val = parseFloat(val);
          if (nils(val)) val = null;
        }
        if (el_postprocess_fn) val = el_postprocess_fn(val);
        set_state_ob(nf, set_nf, path, val);
      }}
    />
  );
};

export const F_InputTextAr = ({
  nf,
  set_nf,
  nfkey,
  path,
  type,
  el_postprocess_fn = (i) => i,
  delimiter = ",",
}) => {
  return (
    <InpText
      id={`${nfkey}-${path}`}
      def_val={(getv(nf, path) || []).join(`${delimiter} `)}
      setter={() => {
        let val = extract_inp(`${nfkey}-${path}`);
        val = _.chain(val)
          .split(delimiter)
          .map((e) => e.trim())
          .map((e) => {
            if (!nils(el_postprocess_fn)) e = el_postprocess_fn(e);
            return e;
          })
          .filter((e) => !nils(e))
          .value();
        set_state_ob(nf, set_nf, path, val);
      }}
    />
  );
};

export const F_OptionInput = ({
  nf,
  set_nf,
  nfkey,
  path,
  type = null,
  options = [],
  txt_fn = (o) => o,
}) => {
  const vals = getv(nf, path);
  return (
    <div className="fr-sc w-full flex-wrap resp-gap-1">
      {options.map((op) => {
        let active =
          type == "option"
            ? vals == op
            : type == "options" || type == "option-ar"
              ? (vals ?? []).includes(op)
              : false;
        return (
          <Tag
            key={op}
            onClick={() => {
              if (active) {
                if (type == "option") {
                  set_state_ob(nf, set_nf, path, null);
                } else if (type == "options") {
                  set_state_ob(
                    nf,
                    set_nf,
                    path,
                    (vals ?? []).filter((e) => e != op),
                  );
                } else if (type == "option-ar") {
                  set_state_ob(nf, set_nf, path, []);
                }
              } else {
                if (type == "option") {
                  set_state_ob(nf, set_nf, path, op);
                } else if (type == "options") {
                  set_state_ob(nf, set_nf, path, [...(vals || []), op]);
                } else if (type == "option-ar") {
                  set_state_ob(nf, set_nf, path, [op]);
                }
              }
            }}
            className={twMerge(
              "resp-text--2 font-mon transition duration-300",
              active ? "bg-acc0/50" : "bg-transparent text-white",
            )}
          >
            {txt_fn(op)}
          </Tag>
        );
      })}
    </div>
  );
};

export const F_OptionBtn_R2_rounded = ({ op, active, txt_fn, ...props }) => {
  const text = txt_fn(op);

  const c0 = get_cprofile_hex("acc0");
  const c4 = get_cprofile_hex("acc4");

  return (
    <div
      {...props}
      class={twMerge(
        "fc-cs relative aspect-[88/33]  m-[0.5rem]",
        "transition duration-200 cursor-pointer",
        "xs:min-h-[0.8rem] lg:min-h-[2rem]",
        text.length > 10
          ? "xs:w-[7rem] lg:w-[9rem] aspect-[10/2.5]"
          : "xs:w-[4rem] lg:w-[6.5rem]",
      )}
    >
      <div class="absolute z-[2] top-[-4px] right-[-4px]">
        {active ? (
          <div class="fc-cc xs:p-[2px] lg:p-[4px] bg-red-400 rounded-full ">
            <FontAwesomeIcon className="resp-text--1" icon={faTimes} />
          </div>
        ) : (
          <div class="fc-cc xs:p-[2px] lg:p-[4px] bg-acc4 rounded-full ">
            <FontAwesomeIcon className="resp-text--1" icon={faPlus} />
          </div>
        )}
      </div>
      <div
        class={twMerge(
          "absolute z-[1] top-0 left-0 w-full h-full",
          "transition duration-500",
          active ? "" : "",
        )}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 88 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.613898 16.5C0.613898 25.2737 7.72637 32.3862 16.5001 32.3862L71.5001 32.3862C80.2738 32.3862 87.3862 25.2737 87.3862 16.5C87.3862 7.72631 80.2738 0.613838 71.5001 0.613838L16.5001 0.613838C7.72638 0.613838 0.613898 7.72631 0.613898 16.5Z"
            fill={c0}
            fill-opacity="0.3618"
            stroke="url(#paint0_linear_61_37)"
            stroke-width="1.22767"
          />
          <defs>
            <linearGradient
              id="paint0_linear_61_37"
              x1="-3.1428"
              y1="5.86667"
              x2="95.4355"
              y2="13.7774"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={c0} />
              <stop offset="1" stop-color={c4} />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="absolute left-[10%] z-[3] w-[85%]">
        <p
          className={twMerge(
            "text-center",
            text.length < 5
              ? "resp-text--1"
              : text.length < 7
                ? "resp-text--2"
                : "resp-text--3",
            active ? "" : "",
          )}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export const F_OptionInput_Raw = ({
  nf,
  set_nf,
  nfkey,
  path,
  type = null,
  options = [],
  txt_fn = (o) => o,
  cont_cn = "",
  TagComp = null,
}) => {
  const vals = getv(nf, path);
  return (
    <div className={twMerge("fr-sc w-full flex-wrap resp-gap-1", cont_cn)}>
      {options.map((op) => {
        let active =
          type == "option"
            ? vals == op
            : type == "options" || type == "options-and" || type == "option-ar"
              ? (vals ?? []).includes(op)
              : false;
        return (
          <div
            key={op}
            onClick={() => {
              if (active) {
                if (type == "option") {
                  set_state_ob(nf, set_nf, path, null);
                } else if (type == "options" || type == "options-and") {
                  set_state_ob(
                    nf,
                    set_nf,
                    path,
                    (vals ?? []).filter((e) => e != op),
                  );
                } else if (type == "option-ar") {
                  set_state_ob(nf, set_nf, path, []);
                }
              } else {
                if (type == "option") {
                  set_state_ob(nf, set_nf, path, op);
                } else if (type == "options" || type == "options-and") {
                  set_state_ob(nf, set_nf, path, [...(vals || []), op]);
                } else if (type == "option-ar") {
                  set_state_ob(nf, set_nf, path, [op]);
                }
              }
            }}
          >
            {TagComp && <TagComp {...{ op, active, txt_fn }} />}
          </div>
        );
      })}
    </div>
  );
};

export const F_InputRange = ({
  nf,
  set_nf,
  nfkey,
  type = "n",
  path,
  el_postprocess_fn = null,
  ...props
}) => {
  return (
    <div className="fr-sc">
      {["mi", "mx"].map((m) => {
        let id = `${nfkey}-${path}-${m}`;
        let valpath = `${path}.${m}`;
        return (
          <InpText
            contprops={{ className: "w-[4rem]" }}
            inpprops={{ className: "w-full" }}
            placeholder={m}
            id={id}
            def_val={getv(nf, valpath)}
            setter={() => {
              let val = extract_inp(id);
              if (type == "iso") val = iso(val);
              if (type == "n") {
                val = parseFloat(val);
                if (nils(val)) val = null;
              }
              if (el_postprocess_fn) val = el_postprocess_fn(val);
              set_state_ob(nf, set_nf, valpath, val);
            }}
            {...props}
          />
        );
      })}
    </div>
  );
};

export const CapsuleSwitch = ({
  idd,
  label = "",
  show_label = true,
  label_cn = "",
  path = null,
  nf,
  set_nf,
  txt_fn,
  options,
  cont_cn,
  tag_cn,
}) => {
  const set = (val) => {
    set_state_ob(nf, set_nf, path, val);
  };

  // useEffect(() => { console.log("nf", idd, nf); }, [nf]);

  return (
    <div
      className={twMerge(
        "flex flex-row justify-start items-center mb-1 flex-nowrap items-start cursor-pointer",
        cont_cn,
      )}
    >
      {show_label && (
        <div onClick={() => {}} className="flex flex-row mb-1 flex-wrap">
          <Tag className={twMerge(label_cn)}>{label}</Tag>
        </div>
      )}
      <div class="fr-sc overflow-hidden rounded-full bg-r2reg">
        {options.map((t) => {
          let is_active = t === getv(nf, path);
          return (
            <span
              onClick={() => {
                set(t);
              }}
              className={twMerge(
                "text-center py-[2px] xs:min-w-[2.5rem] lg:min-w-[4rem] resp-text--2 font-digi",
                tag_cn,
                is_active ? "bg-r2lig" : "",
              )}
            >
              {txt_fn(t)}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export const BtnGroup = ({
  idd,
  label = "",
  show_label = true,
  label_cn = "",
  path = null,
  nf,
  set_nf,
  txt_fn,
  options,
  cont_cn,
  tag_cn,
}) => {
  const set = (val) => {
    set_state_ob(nf, set_nf, path, val);
  };

  useEffect(() => {
    console.log("nf", idd, nf);
  }, [nf]);

  return (
    <div
      className={twMerge(
        "fr-sc mb-1 flex-nowrap items-start cursor-pointer",
        cont_cn,
      )}
    >
      {show_label && (
        <div onClick={() => {}} className="flex flex-row mb-1 flex-wrap">
          <Tag className={twMerge(label_cn)}>{label}</Tag>
        </div>
      )}
      <div class="fr-sc overflow-hidden rounded-full bg-r2reg">
        {options.map((t) => {
          let is_active = t === getv(nf, path);
          return (
            <span
              onClick={() => {
                set(t);
              }}
              className={twMerge(
                "text-center py-[2px] xs:min-w-[2.5rem] lg:min-w-[4rem] resp-text--2 font-digi",
                tag_cn,
                is_active ? "bg-r2lig" : "",
              )}
            >
              {txt_fn(t)}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export const InpDropdown = ({
  options,
  setter,
  txt,
  text_fn = (o) => o,
  is_active = () => {},
  selection_cn = "",
  drop_cn = "",
  active_cn = "",
  option_cn = "",
}) => {
  const [open, set_open] = useState(false);
  return (
    <div className="relative">
      <Tag
        onClick={() => set_open(!open)}
        className={twMerge(
          "resp-px-2 text-white bg-r2reg/40 xs:w-[5rem] lg:w-[10rem] flex flex-row justify-start items-center resp-text--2",
          "border border-acc4",
          selection_cn,
        )}
      >
        <span>{txt ?? "--Select--"}</span>
        <div className="flex-1"></div>
        <FontAwesomeIcon icon={faAngleDown} />
      </Tag>
      <div className="absolute botom-5 min-w-max w-[10rem] z-[1000]">
        <AnimatePresence>
          {open && (
            <motion.div
              {...Mo.expand()}
              className={twMerge("rounded-md bg-slate-800 text-white", drop_cn)}
            >
              {options.map((o) => {
                return (
                  <motion.div
                    onClick={() => {
                      setter(o);
                      set_open(false);
                    }}
                    className={twMerge(
                      "resp-px-2 resp-py-2 hover:bg-acc4/30 resp-text--2",
                      is_active(o) ? twMerge("bg-r2lig/40", active_cn) : "",
                      option_cn,
                    )}
                  >
                    {text_fn(o)}
                  </motion.div>
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
