import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAppContext, useNowContext } from "../App.js";
import { useRaceContext } from "./RacePage.js";
import { Card, Tag } from "../components/utilityComps.js";
import { BikeImg } from "../components/BikeImg.js";

import cb10 from "../assets/cbimgs/cb10.svg";
import cb12 from "../assets/cbimgs/cb12.svg";
import cb14 from "../assets/cbimgs/cb14.svg";
import cb16 from "../assets/cbimgs/cb16.svg";
import cb18 from "../assets/cbimgs/cb18.svg";
import cb20 from "../assets/cbimgs/cb20.svg";
import cb22 from "../assets/cbimgs/cb22.svg";
import _, { parseInt } from "lodash";
import {
  BY_Star,
  PosTag,
  bez_get_controlpoints,
  bez_y_from_x,
} from "../utils/raceutils.js";
import {
  abs_time_mini,
  dec,
  getv,
  nano,
  nils,
  to_time_mini,
  trim_n,
} from "../utils/utils.js";
import { twMerge } from "tailwind-merge";
import ContrastColor, { contrastColor } from "contrast-color";

const tdur = 100;

const transdot = 0.7;
const transdotop = 1.7;
const transhtoast = 0.5;
const transtrack = 1;

const trackview = 150;
const override_speed = 1;

const dist_obs = {
  1000: { img: cb10, t_h: 4050 },
  1200: { img: cb12, t_h: 4850 },
  1400: { img: cb14, t_h: 5650 },
  1600: { img: cb16, t_h: 6450 },
  1800: { img: cb18, t_h: 7250 },
  2000: { img: cb20, t_h: 8050 },
  2200: { img: cb22, t_h: 8850 },
};
const get_int_dist_obs = (dist) => {
  if (dist_obs[dist]) return dist_obs[dist];
  let closest = _.chain(dist_obs)
    .keys()
    .minBy((e) => Math.abs(parseInt(e) - dist))
    .value();
  closest = parseInt(closest);
  let o = _.cloneDeep(dist_obs[closest]);
  o.t_h = (o.t_h * dist) / closest;
  return o;
};

const RaceRunContext = createContext();
const useRaceRunContext = () => useContext(RaceRunContext);

const BikeToast = ({
  h,
  vau_name,
  acth,
  ended,
  className,
  style = {},
  is_vhhid,
  star = 0,
}) => {
  return (
    <div
      style={style}
      className={twMerge(
        is_vhhid
          ? "shadow-md shadow-acc0 border-2 border-r2lig"
          : "bg-r2lig/20",
        !nils(h.bg_card) ? h.bg_card : "",
        "w-full flex flex-row justify-start items-center resp-gap-1 resp-px-2 rounded-md resp-text--2",
        className,
      )}
    >
      <PosTag {...{ pos: acth?.c_pos }} />
      <div className="flex-1 text-sm">
        <p className="my-0.5 text-left">
          {/* {h.hid} -  */}
          {trim_n(h.name, 20)}
        </p>
        <p className="text-xs text-acc0/80">{trim_n(vau_name, 20)}</p>
        {ended && (
          <p className="my-0.5 text-right text-green-300 font-bold">
            {dec(h.time, 2)}sec
          </p>
        )}
      </div>
      <BY_Star star={star} />
      <BikeCircle
        gate={acth.gate}
        hex_code={h.hex_code}
        is_vhhid={is_vhhid}
        className={"h-[1.5rem] w-[1.5rem]"}
      />
    </div>
  );
};
export const BikeCircle = ({
  className,
  hex_code,
  gate,
  is_vhhid,
  ...props
}) => {
  return (
    <Tag
      {...props}
      style={{
        backgroundColor: `#${hex_code}`,
        color: contrastColor({ bgColor: `#${hex_code}` }),
      }}
      className={twMerge(
        "w-[2rem] h-[2rem] font-bold rounded-full",
        "flex flex-row justify-center items-center",
        "bg-black",
        is_vhhid ? " shadow-md shadow-acc0" : "",
        className,
      )}
    >
      <span>{gate}</span>
    </Tag>
  );
};

const BikeDot = ({
  gate,
  hex_code,
  acth = {},
  detsh = {},
  is_vhhid,
  ...props
}) => {
  const w = useMemo(() => {
    return window.innerWidth;
  }, []);
  let left = w < 1046 ? 10 + gate * 25 : 10 + (gate - 1) * 45;

  const usehex = !nils(detsh?.dot_hex) ? detsh.dot_hex : hex_code;
  return (
    <>
      <div
        {...props}
        style={{
          left,
          bottom: 0,
          marginBottom: acth.c_mb - 35,
          transition: `all ${transdot}s ease, opacity ${transdotop}s ease`,
          ...(acth.c_per >= 0.99 ? { opacity: 0 } : { opacity: 1 }),
          backgroundColor: `#${usehex}`,
          color: contrastColor({
            bgColor: `#${usehex}`,
          }),
        }}
        className={twMerge(
          "lg:border-4 lg:h-[1.5rem] lg:w-[1.5rem]",
          "xs:border xs:h-[1rem] xs:w-[1rem] resp-text--2",
          "font-bold rounded-full",
          "flex flex-row justify-center items-center",
          is_vhhid ? "border-acc0" : "border-white",
          "absolute",
          !nils(acth.bg_card) ? acth.bg_card : "",
          props.className,
        )}
      >
        <span>{gate}</span>
      </div>
    </>
  );
};

const Main2dPlayer = () => {
  const rrcon = useRaceRunContext();
  const {
    transtrack,
    dist_obs,
    track_mb,
    vhids,
    hids,
    dist,
    t,
    hsob,
    movob,
    hsdetsob,
  } = rrcon;

  const distob = get_int_dist_obs(dist);

  const bardists = useMemo(() => {
    let ar = [];
    for (let i = 0; i <= dist; i += 50) {
      let mb = (i / dist) * distob.t_h;
      if (i == dist) {
        ar.push({
          dist: "Finish",
          cn: "text-green-500",
          hrcn: "bg-green-500",
          mb,
          showtext: true,
        });
      } else if (i % 100 == 0) {
        ar.push({
          dist: `${i}M`,
          cn: "text-white/60",
          hrcn: "bg-white/40",
          mb,
          showtext: true,
        });
      } else {
        ar.push({
          dist: `${i}M`,
          cn: "text-gray-400",
          hrcn: "bg-gray-400/40",
          mb,
        });
      }
    }
    return ar;
  }, [dist]);

  return (
    <>
      <Card className="bg-r2dark/80 backdrop-blur-md w-full">
        <div className="w-full relative">
          <div className="racetrack-container xs:h-[550px] lg:h-[550px]">
            <div
              className="racetrack absolute"
              style={{
                transition: `all ${transtrack}s`,
                height: `${distob.t_h}px`,
                backgroundSize: `auto ${distob.t_h}px`,
                backgroundPosition: "bottom right",
                bottom: 0,
                marginBottom: track_mb,
              }}
            >
              {bardists.map((e) => {
                return (
                  <div
                    style={{
                      marginBottom: e.mb,
                      left: 0,
                      bottom: 0,
                    }}
                    class={twMerge(
                      "absolute w-full font-digi flex flex-row justify-end items-baseline resp-gap-2",
                      e.cn,
                    )}
                  >
                    <div class="flex-1">
                      <div
                        class={twMerge("w-full h-[1px] rounded-lg", e.hrcn)}
                      />
                    </div>

                    {e.showtext == true && (
                      <span className="resp-text--2">{e.dist}</span>
                    )}
                  </div>
                );
              })}
              {!nils(t) && (
                <div className=" ">
                  {[...hids].map((hid, idx) => {
                    let h = hsob[hid] || {};
                    let hex_code = getv(hsdetsob, `${hid}.hex_code`);
                    let m = movob[hid];
                    let is_vhhid = vhids.includes(hid);
                    // return null;
                    return (
                      <BikeDot
                        {...{
                          acth: { ...h, ...m },
                          detsh: hsdetsob[hid],
                          gate: h.gate,
                          hex_code,
                          is_vhhid,
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export const RacePage_Run = ({
  race,
  racerun,
  hsdetsob,
  rtstatus,
  watching2d,
  set_watching2d,
  watching2d_st,
  set_watching2d_st,
  vhids,
  showrun = false,
}) => {
  const { psearch } = useAppContext();
  const { now } = useNowContext();
  const r = race;

  const rvob = r.racing_vaults;
  const cvob = r.current_vaults;
  const vnob = r.vaults_names;

  const dist = r.cb * 100;
  const hids = r.hids;
  const speed = override_speed ?? 1;
  const init_t = nils(psearch.init_t) ? 0 : parseInt(psearch.init_t);

  const t0 = useMemo(() => {
    if (nils(watching2d_st)) return 0;
    let elap = now - watching2d_st;
    if (nils(elap)) return 0;
    return elap;
  }, [watching2d_st, now]);
  const t = useMemo(() => {
    return t0 * speed + init_t;
  }, [t0, showrun, r.status]);
  // console.log({ t0, t });

  const { hs, endt, mxtime } = useMemo(() => {
    let hs = getv(racerun, "hs");
    let times = _.map(hs, "time");
    let mitime = _.min(times);
    let mxtime = _.max(times);

    let wint = mitime * 1e3;
    let racetime = mitime * 1e3;
    let endt = mxtime * 1e3 + 10 * 1e3;
    return { hs, mitime, mxtime, racetime, wint, endt };
  }, [racerun]);

  const hsob = showrun
    ? _.keyBy(hs, "hid")
    : _.chain(r.hids)
        .map((hid, idx) => {
          let rhid = `${r.rid}-${hid}`;
          return { hid, rhid, gate: idx + 1 };
        })
        .keyBy("hid")
        .value();
  // console.log(hsob);

  const [movob, track_mb, ord] = useMemo(() => {
    let movob = {};
    let elap = t / 1000;
    if (showrun == false) elap = 0;

    // const trackheight = dist_obs[dist].t_h;
    const trackheight = get_int_dist_obs(dist)?.t_h;
    for (let hid of hids) {
      let h = hsob[hid];
      let c_dist = 0;

      if (elap > 0) {
        const bez = _.find(h.bezs, (e, idx) => {
          if (idx + 1 == h.bezs.length) return true;
          return _.inRange(elap, e.xs[0], e.xs[1]);
        });
        const ps = bez_get_controlpoints(bez.bezstr);
        c_dist = bez_y_from_x(elap, ps, bez.xs, bez.ys);
        c_dist = Math.min(c_dist, dist + 20);
      }

      let c_per = c_dist / dist;
      let c_mb = c_per * trackheight;

      let finished = c_dist >= dist;
      let sortval = finished == true ? (mxtime - h.time) * 10000 : c_dist;

      let m = { hid, gate: h?.gate, c_dist, c_per, c_mb, sortval };
      movob[hid] = m;
      if (h.gate == 1) {
        console.log(h.gate, parseInt(elap), dec(c_dist, 0), dec(c_per, 2));
      }
    }

    let midist = _.min(_.map(movob, "c_dist"));
    let mxdist = _.max(_.map(movob, "c_dist"));

    let pos1 = _.chain(movob)
      .values()
      .maxBy((e) => -e.c_per)
      .value();
    let pos1per = getv(pos1, "c_per");
    // pos1per += 20 / dist;

    let track_mb = null;

    let centdist = mxdist;
    track_mb = centdist - 110;
    let p = track_mb / dist;
    track_mb = mxdist < 100 ? 50 : -1 * p * trackheight;

    let ord = _.chain(movob)
      .values()
      .sortBy((e) => -e.sortval)
      .map("hid")
      .value();

    return [movob, track_mb, ord];
  }, [t, showrun]);

  const ended = useMemo(() => {
    if (nils(t)) return false;
    if (nils(endt)) return false;
    return t > endt;
  }, [endt, t]);

  useEffect(() => {
    if (ended) {
      set_watching2d(false);
      set_watching2d_st(null);
    }
  }, [ended]);

  const rrcon = {
    transtrack,
    dist_obs,
    track_mb,
    vhids,
    hids,
    dist,
    t,
    hsob,
    movob,
    hsdetsob,
  };

  return (
    <RaceRunContext.Provider value={rrcon}>
      <div className="h-[2rem]"></div>
      <div className="flex flex-row justify-start items-stretch resp-gap-2 w-full">
        <div className="xs:hidden lg:block h-auto">
          <Card
            className={
              "resp-p-2 bg-r2dark/80 backdrop-blur-md w-full fc-sc h-full"
            }
          >
            <div className="fc-ss h-full max-h-full overflow-auto">
              <div className="min-w-[20rem] fr-cc resp-px-2 resp-my-2">
                {t > 0 && (
                  <>
                    <span>{"Running"}</span>
                    <div className="flex-1"></div>
                    <span
                      className={twMerge(
                        "font-bold font-digi resp-my-2 text-lg resp-text-1",
                        ended ? "text-transparent" : "text-white",
                      )}
                    >
                      {dec(t / 1e3, 2)} sec
                    </span>
                  </>
                )}
                {t < 0 && (
                  <>
                    <span className="text-yellow-400">Starting in</span>
                    <div className="flex-1"></div>
                    <span
                      className={twMerge(
                        "font-bold font-digi resp-my-2 text-lg resp-text-1",
                        ended ? "text-transparent" : "text-yellow-400",
                      )}
                    >
                      {abs_time_mini(t, 0)}
                    </span>
                  </>
                )}
              </div>

              <div className="relative flex-1 w-full">
                <div className="h-full">
                  {ord.map((hid, i) => {
                    let h = hsob[hid] || {};
                    let { gate } = h;
                    let ho = hsdetsob[hid];
                    if (nils(ho)) return ho;
                    h.gate = gate;
                    h.c_pos = t < 0 ? 0 : i + 1;
                    let is_vhhid = vhids.includes(hid);
                    // const c_vau = getv(cvob, hid);
                    // const vau_name = getv(vnob, c_vau);

                    const r_vau = getv(rvob, hid);
                    const vau_name = nils(r_vau) ? null : getv(vnob, r_vau);
                    // console.log(hid, r_vau, vau_name);

                    return (
                      <BikeToast
                        {...{
                          className: "h-[5rem] absolute",
                          style: {
                            top: `${(5 + 0.6) * i}rem`,
                            transition: `top ${transhtoast}s`,
                            zIndex: 100 + ord.length - i,
                          },
                          key: hid,
                          h: ho,
                          vau_name,
                          acth: h,
                          ended,
                          star: h.star,
                          is_vhhid,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Main2dPlayer />
      </div>
    </RaceRunContext.Provider>
  );
};
