import React, { useState } from "react";
import { createContext, useContext, useEffect, useMemo } from "react";
import { useQueries } from "react-query";
import { useMatch, useParams } from "react-router";
import {
  polytxnidlink,
  q_bikehstats,
  q_bikeinfo,
  q_bikerecentraces,
  q_hraces,
  q_market_bidsn,
  q_market_list,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import {
  base64_to_json,
  dec,
  emp,
  from_time_mini,
  getv,
  iso_format,
  json_to_base64,
  jstr,
  nano,
  nils,
  per_n,
} from "../utils/utils.js";
import { Loader01c } from "../components/anims.js";
import { Card, Img, Tag, TokenIcon } from "../components/utilityComps.js";
import {
  cb_txt,
  class_cn,
  class_text,
  get_cprofile_hex,
  get_payout_cn,
  get_payout_txt,
  pos_cn,
  pos_txt,
  rvmode_s,
  tablecn,
} from "../utils/cn_map.js";
import { twMerge } from "tailwind-merge";
import _, { parseInt } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faLink,
  faSatelliteDish,
  faStar,
  faToggleOff,
  faToggleOn,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  MiniBikeCard,
  MiniStatCard,
  ParentsCard,
  SetBikeName,
  SpliceCoreInfo,
  gen_bikecard_stats_list,
} from "../components/BikeCard.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { BY_Star, PosTag, postxt } from "../utils/raceutils.js";
import { tokdecn, useAppContext } from "../App.js";
import { racecn } from "./Races.js";
import {
  filt_ar_using_filters,
  gen_filters_from_valob,
  gen_valob_from_filters,
} from "../utils/filt.js";
import { InpAutoWrap, set_state_ob } from "../components/input";
import tailwindcss_colors from "tailwindcss/colors";
import { BarChart } from "../components/charts.js";
import { BikesSearch } from "./BikesPage.js";
import { polychainimg } from "../utils/links.js";
import { FiltCard_forLegacy } from "../utils/raceutils2.js";
import { RVImg } from "../components/BikeImg.js";

const BikeContext = createContext();
export const useBikeContext = () => useContext(BikeContext);

let all_cbs = [9, 11, 13, 19, 21, 23, 10, 12, 14, 16, 18, 20, 22, 15, 17];

const AssetMarketSection = ({}) => {
  const bcon = useBikeContext();
  const { h, mybike } = bcon;

  const [qolist, qobidsn] = useQueries([
    q_market_list({ asset_type: "core", token_id: h.hid }),
    q_market_bidsn({ asset_type: "core", token_id: h.hid }),
  ]);

  const list = useMemo(() => {
    return getv(qolist, "data.result", null);
  }, [qolist.dataUpdatedAt]);
  const bids_n = useMemo(() => {
    return getv(qobidsn, "data.result.bids_n", null);
  }, [qobidsn.dataUpdatedAt]);
  // console.log({ list, bids_n });
  const marketlink = `https://market.dnaracing.run/asset/core/${h.hid}`;

  return (
    <div className="resp-p-2 rounded-md">
      <div className="fr-sc resp-gap-2">
        <>
          <div className="fc-ss resp-gap-2">
            {qolist.isLoading ? (
              <Loader01c size="s" />
            ) : qissuccesss(qolist) ? (
              <>
                {!nils(list) && list.valid ? (
                  <>
                    <div className="fc-ss resp-gap-2 rounded-md resp-p-2">
                      <div className="fc-cc resp-gap-1">
                        <Link target="_blank" to={marketlink}>
                          <Tag className="text-center bg-acc0/20 text-white font-digi fr-sc resp-gap-1">
                            <span className="resp-text--2">{"Buy at"}</span>
                            <span className="resp-text--2">
                              {dec(list.amt, 4)}
                            </span>
                            <span className="resp-text--3">{` ${list.token}`}</span>
                          </Tag>
                        </Link>
                      </div>

                      <div className="fr-ss resp-gap-1 text-acc0">
                        <span className="resp-text--3">Expires at</span>
                        <span className="resp-text--3">
                          {iso_format(list.expires_at)}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </>

        {qobidsn.isLoading ? (
          <Loader01c size="s" />
        ) : qissuccesss(qobidsn) ? (
          <>
            {bids_n > 0 ? (
              <span className="resp-text--2 text-purple-300 font-digi">
                {bids_n} Bids
              </span>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <div className="flex-1"></div>
        <Link target="_blank" to={marketlink}>
          <Tag className={"bg-acc0/40 text-white font-digi"}>
            View on Market
          </Tag>
        </Link>
      </div>
    </div>
  );
};

const BikeInfoBase = () => {
  const bcon = useBikeContext();
  const { h, mybike, rvmode } = bcon;

  const statslist = useMemo(() => {
    return gen_bikecard_stats_list(h, rvmode);
  }, [jstr(h), rvmode]);

  const races_n = useMemo(() => {
    // console.log(h);
    let a = getv(h, "hstats_bike.career.races_n", 0);
    let b = getv(h, "hstats_car.career.races_n", 0);
    let c = getv(h, "hstats_horse.career.races_n", 0);
    return a + b + c;
  }, [jstr(h)]);

  return (
    <>
      <div className="grid xs:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="col-span-2">
          <Card
            className={twMerge(
              "border border-acc4 bg-r2dark/30",
              "w-full h-full",
              h.burned === true ? "shadow-md shadow-red-400/50" : "",
            )}
          >
            <p className="resp-text-1 font-digi">#{h.hid}</p>
            <div class="flex flex-row justify-start items-start resp-gap-2">
              <p className="resp-text-2 font-digi">{h.name}</p>
              {h.is_maiden && <p class="resp-text--2 text-acc0">ME</p>}
            </div>
            {(h.name == "Unnamed DNA Splice" || nils(h.name)) && mybike && (
              <SetBikeName h={h} />
            )}

            <Link to={`/vault/${h.vault}`}>
              <p className="text-right resp-text-1 font-digi text-acc0">
                {h.vault_name}
              </p>
            </Link>
            <div className="h-[4rem]"></div>
            <AssetMarketSection />
            {!nils(h?.mint?.tx_hash) && (
              <div className="my-2">
                <Link
                  target="_blank"
                  className="fr-sc resp-gap-1 text-purple-300 resp-text--1"
                  to={polytxnidlink(h.mint.tx_hash)}
                >
                  <div className="xs:w-[0.7rem] lg:w-[1.2rem]">
                    <Img img={polychainimg} />
                  </div>
                  <span>Mint</span>
                </Link>
              </div>
            )}
            {!nils(h?.mint?.splice_payhash) && (
              <div className="my-2">
                <Link
                  target="_blank"
                  className="fr-sc resp-gap-1 text-purple-300 resp-text--1"
                  to={polytxnidlink(h.mint.splice_payhash)}
                >
                  <div className="xs:w-[0.7rem] lg:w-[1.2rem]">
                    <Img img={polychainimg} />
                  </div>
                  <span>Splice Payment</span>
                </Link>
              </div>
            )}
            {h.burned == true && (
              <div className="my-2">
                <Link
                  target="_blank"
                  className="fr-sc resp-gap-1 text-red-300 resp-text--1"
                  to={polytxnidlink(h.burn.hashid)}
                >
                  <div className="xs:w-[0.7rem] lg:w-[1.2rem]">
                    <Img img={polychainimg} />
                  </div>
                  <span>Retired</span>
                  <span>at {iso_format(h.burn.date)}</span>
                </Link>
              </div>
            )}
            {h?.is_trainer === true && (
              <div class="my-2 fr-sc w-full">
                {getv(h, "trainer.expired") == true ? (
                  <span className="text-red-400 italic font-digi resp-text--1">
                    Retired Trainer Bike
                  </span>
                ) : (
                  <>
                    <span className="text-blue-400 italic font-digi resp-text--1">
                      Trainer Bike
                    </span>
                    <div class="flex-1"></div>
                    <span className="resp-text--3 font-digi">
                      {Math.max(0, 50 - races_n)} Races Left
                    </span>
                  </>
                )}
              </div>
            )}
          </Card>
        </div>
        <div className="xs:hidden md:block col-span-1">
          <div className="w-full h-[20rem] rounded-lg overflow-hidden ">
            <video className="w-full h-full" autoPlay loop muted playsInline>
              <source
                src="https://api01.dnaracing.run/fbike/static/animations/fbike.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      <div className="h-[2rem]"></div>
    </>
  );
};

const BikeInfoCards = () => {
  const bcon = useBikeContext();
  const { h, mybike, rvmode } = bcon;

  const statslist = useMemo(() => {
    return gen_bikecard_stats_list(h, rvmode);
  }, [jstr(h), rvmode]);
  return (
    <>
      <div
        className={twMerge(
          "border border-acc4 resp-p-4",
          " grid xs:grid-cols-2 md:grid-cols-4 gap-4",
        )}
      >
        {statslist.map(([head, txt, icon, icn, cn]) => {
          return (
            <MiniStatCard
              {...{
                rvmode,
                icon,
                head,
                txt,
                icn,
                cn,
                wrapcn: [
                  "Color",
                  "Skin",
                  "Splices",
                  "Stamina",
                  "Special Stamina",
                  "Tourney Profits",
                ].includes(head)
                  ? "col-span-2"
                  : "",
              }}
            />
          );
        })}
      </div>
      <div class="h-[2rem]"></div>
      <div className="max-w-[95vw] w-[50rem] mx-auto">
        <Card
          className={"bg-r2lig/20 backdrop-blur-md w-full border border-acc4"}
        >
          <SpliceCoreInfo {...{ h, mybike, contcn: "bg-transparent" }} />
        </Card>
      </div>
    </>
  );
};

const Splices = () => {
  const bcon = useBikeContext();
  const { splices } = bcon;
  return (
    <div>
      <div className="h-[2rem]"></div>
      <p className="text-left resp-text-1 font-digi italic my-2">Splices</p>
      <div className="grid xs:grid-cols-2 lg:grid-cols-4 gap-4">
        {splices.map((hid) => (
          <div className="col-span-2  rounded-md">
            <MiniBikeCard
              {...{
                hid,
                className: "bg-r2lig/20 backdrop-blur-md border border-acc4",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const BikePage = () => {
  const pars = useParams();
  const hid = useMemo(() => {
    return parseInt(pars.hid);
  }, [pars]);

  const [qo_h] = useQueries([q_bikeinfo({ hid })]);

  const h = useMemo(() => {
    if (!qissuccesss(qo_h)) return null;
    let d = getv(qo_h, "data.result");
    return d;
  }, [qo_h.dataUpdatedAt]);

  const { vault } = useAccountContext();

  const mybike = useMemo(() => {
    if (nils(h)) return false;
    return h.vault?.toLowerCase() == vault;
  }, [h]);

  // useEffect(() => {
  //   console.log("bcon", bcon);
  // }, [bcon]);

  const splices = useMemo(() => {
    if (nils(h)) return [];
    let ar = getv(h, "splice_core.life_splices") || [];
    return ar;
  }, [jstr(h)]);

  const appcon = useAppContext();
  const { s_acc_config, g_acc_config } = useAccountContext();
  const { psearch, upd_psearch } = appcon;

  const basefilt = useMemo(() => {
    let f = psearch.f;
    f = base64_to_json(f);
    f = f ?? {};
    if (_.isEmpty(f)) {
      let cac_f = g_acc_config("open_races.basefilt", {});
      f = cac_f;
    }
    return f;
  }, [psearch]);

  const [rvmode, set_rvmode] = useState(psearch.rvmode ?? "bike");

  const [include_quest, set_include_quest] = useState(
    nils(psearch.include_quest)
      ? false
      : psearch.include_quest === "true"
        ? true
        : false,
  );
  const [tab, set_tab] = useState(psearch.tab ?? "info");
  const [subtab, set_subtab] = useState(psearch.subtab ?? "summary");

  const [filt, set_filt] = useState({
    // rvmode: {
    //   type: "options",
    //   path: "rvmode",
    //   cfilter: true,
    //   options: ["car", "bike", "horse"],
    //   vals: !nils(parseInt(basefilt?.rvmode)) ? [basefilt?.rvmode] : [],
    //   txt_fn: (o) => {
    //     return _.upperCase(o);
    //   },
    //   label: "rvmode",
    //   show_label: false,
    //   cont_cn: "justify-center",
    //   inner_cont_cn: "justify-center",
    //   base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
    //   color_fn: (o) => {
    //     return "bg-acc4/50";
    //   },
    //   active_cn: (active, op) => {
    //     if (!active) return "bg-dark";
    //     let shadow = `shadow-lg shadow-${class_cn(op)?.replace("bg-", "")}`;
    //     let transform = "transform -skew-x-12";
    //     return `${transform} ${shadow}`;
    //   },
    // },
    class: {
      type: "options",
      path: "class",
      cfilter: true,
      options: [50, 60, 70, 80, 1, 2, 3, 4],
      vals: !nils(parseInt(basefilt?.class)) ? [parseInt(basefilt?.class)] : [],
      txt_fn: (o) => {
        if (o == "my_races") return `My Races`;
        if (o == "_1v1") return `1v1`;
        return class_text(o, "T");
      },
      label: "Class",
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return class_cn(o, "font-digi bg-opacity-80");
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-${class_cn(op)?.replace("bg-", "")}`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    cb: {
      type: "options",
      path: "cb",
      cfilter: true,
      options: [10, 12, 14, 16, 18, 20, 22],
      filter_exception: [],
      vals: !nils(parseInt(basefilt?.cb)) ? [parseInt(basefilt?.cb)] : [],
      txt_fn: (o) => {
        return `CB${o}00`;
      },
      label: "Distance",
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    payout: {
      type: "options",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        let c = 0;
        for (let p of f) {
          if (r.payout.startsWith(p)) c++;
        }
        return c !== 0;
      },
      cfilter: true,
      label: "Payout",
      show_label: false,
      options: [
        "na",
        "wta",
        "top2",
        "top3",
        "top4",
        "dblup",
        "pity",
        "pitylow",
        "hybrid",
        "revdu",
        "variance",
      ],
      filter_exception: [],
      vals: basefilt?.payout,
      txt_fn: (o) => {
        return get_payout_txt(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return get_payout_cn(o);
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    eventtags: {
      type: "options",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        for (let e of f) {
          if (r.eventtags.includes(e)) return true;
        }
        return false;
      },
      cfilter: true,
      label: "EventTags",
      show_label: false,
      options: [],
      filter_exception: [],
      vals: basefilt?.eventtags,
      txt_fn: (o) => {
        return o;
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-white text-white";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-md shadow-white text-black`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    format: {
      type: "options",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        let c = 0;
        for (let p of f) {
          if (r.format.startsWith(p)) c++;
        }
        return c !== 0;
      },
      cfilter: true,
      label: "Format",
      show_label: false,
      options: [
        "normal",
        "spin_n_go",
        "sit_n_go",
        "reduced",
        "rounds",
        "sub_rounds",
      ],
      filter_exception: [],
      vals: _.isEmpty(basefilt?.format) ? [] : basefilt?.format,
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    rgate: {
      type: "options",
      path: "rgate",
      cfilter: true,
      label: "Race Gates",
      show_label: false,
      options: [2, 3, 4, 5, 6, 7, 8, 10, 12, 14],
      filter_exception: [],
      vals: basefilt?.rgate?.map((e) => parseInt(e)),
      txt_fn: (o) => {
        return `${o} Gates`;
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      color_fn: (o) => {
        return "bg-purple-500/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    fee_mode: {
      label: "Fees",
      cfilter: false,
      type: "inptext",
      vals: basefilt?.fee_mode ?? "ALL",
      txt_fn: (o) => `${o}`,
    },
    fee_usd: {
      type: "range",
      path: (r, f, filters) => {
        let fm = getv(filters, "fee_mode.vals") ?? "ALL";
        if (fm == "ALL") return true;
        else if (fm == "Free") {
          let isfree = r.fee == 0;
          return isfree;
        } else if (fm == "Paid") {
          let inran = _.inRange(
            r.fee_usd,
            nils(f?.mi) ? 0.001 : f.mi,
            f?.mx ?? 1e7,
          );
          return inran;
        }
        return false;
      },
      cfilter: true,
      label: "Fee [in USD]",
      show_label: false,
      options: [2, 6, 12],
      filter_exception: [],
      vals: emp(basefilt?.fee_usd)
        ? { mi: undefined, mx: undefined }
        : basefilt?.fee_usd,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem] font-mon`,
      color_fn: (o) => {
        return "text-white";
      },
      active_cn: (active, op) => {
        if (!active) return "";
        return `text-green-400`;
      },
    },
  });
  const valfilt = useMemo(() => {
    let valfilt = gen_valob_from_filters(filt);
    s_acc_config("open_races.basefilt", valfilt);
    return valfilt;
  }, [filt]);
  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };
  const rem = useMemo(() => {
    let rem = {};
    rem.tab = tab;
    rem.rvmode = rvmode;
    rem.subtab = subtab;
    rem.f = json_to_base64(valfilt);
    upd_psearch(rem);
    return rem;
  }, [jstr(valfilt), tab, subtab, rvmode]);

  const [qo_hstats] = useQueries([
    q_bikehstats({
      hid,

      rvmode: rvmode,

      special:
        tab == "season_0"
          ? "season0"
          : tab == "season_1"
            ? "season1"
            : tab == "season_2"
              ? "season2"
              : tab == "season_3"
                ? "season3"
                : tab == "season_4"
                  ? "season4"
                  : null,
    }),
  ]);
  const hstats = useMemo(() => {
    if (!qissuccesss(qo_hstats)) return null;
    let d = getv(qo_hstats, "data.result");
    return d;
  }, [qo_hstats.dataUpdatedAt]);

  const loading = useMemo(() => {
    return qo_h.isLoading || qo_hstats.isLoading;
  }, [qo_h.dataUpdatedAt, qo_hstats.dataUpdatedAt]);

  const bcon = {
    hid,
    h,
    hstats,
    mybike,
    splices,
    rvmode,
    set_rvmode,
    tab,
    set_tab,
    subtab,
    set_subtab,

    filt,
    set_filt,
    valfilt,
    clear_filt,

    include_quest,
    set_include_quest,
  };

  const c4 = get_cprofile_hex("acc4");
  const dark = get_cprofile_hex("r2dark");

  return (
    <div className="page-wrapper">
      <div className="h-page">
        <div className="max-w-[98vw] w-[70rem] mx-auto">
          <div className="h-[1rem]"></div>
          <BikesSearch />
          <div className="h-[3rem]"></div>
          <BikeContext.Provider value={bcon}>
            {loading && <Loader01c />}
            {qissuccesss(qo_h) && (
              <>
                <BikeInfoBase />

                <div class="fr-cc resp-gap-2 my-[2rem]">
                  {rvmode_s.map((rv) => {
                    let filt = rvmode;
                    let set_filt = set_rvmode;
                    let path = ``;
                    let active = _.includes(getv(filt, path), rv);
                    return (
                      <div
                        onClick={() => {
                          let nrvs = _.cloneDeep(getv(filt, path)) || null;
                          // if (active) nrvs = _.without(nrvs, rvmode);
                          // else nrvs.push(rvmode);
                          if (active) nrvs = null;
                          else nrvs = rv;
                          set_state_ob(filt, set_filt, path, nrvs);
                        }}
                        className={twMerge(
                          "cursor-pointer resp-p-2 h-full rounded-md",
                          active
                            ? "bg-acc4 shadow-acc0 shadow-md"
                            : "bg-r2dark/20",
                        )}
                      >
                        <RVImg
                          {...{ rvmode: rv }}
                          hex_code={!active ? c4 : "#FFFFFF"}
                          className={"xs:w-[4rem] lg:w-[8rem]"}
                        />
                      </div>
                    );
                  })}
                </div>

                <div className="fr-cc resp-gap-2 font-digi resp-text-1 mb-3">
                  {[
                    "info",
                    "all_races",
                    "season_0",
                    "season_1",
                    "season_2",
                    "season_3",
                    "season_4",
                  ].map((_tab) => {
                    let active = tab == _tab;
                    return (
                      <Tag
                        className={twMerge(
                          "trasition-all duration-200 ease-in-out",
                          active
                            ? "bg-acc0/40 shadow-md shadow-acc0 text-white -skew-x-12"
                            : "bg-transparent text-acc0",
                        )}
                        onClick={() => set_tab(_tab)}
                      >
                        {_.chain(_tab)
                          .split("_")
                          .map((e) => _.capitalize(e))
                          .join(" ")
                          .value()}
                      </Tag>
                    );
                  })}
                </div>

                {tab == "info" && (
                  <>
                    <BikeInfoCards {...{ h }} />
                    {h.type !== "genesis" && <ParentsCard {...{ h }} />}
                    {!_.isEmpty(splices) && <Splices />}
                  </>
                )}

                {tab == "all_races" && <BikeRaces />}

                {tab == "season_0" && (
                  <BikeRaces
                    filt_fn={(r) => {
                      return _.inRange(
                        nano(r.start_time),
                        nano("2024-03-05T00:00:00.000Z"),
                        nano("2024-05-06T23:00:00.000Z"),
                      );
                    }}
                  />
                )}
                {tab == "season_1" && (
                  <BikeRaces
                    filt_fn={(r) => {
                      return _.inRange(
                        nano(r.start_time),
                        nano("2024-05-06T23:00:00.000Z"),
                        nano("2024-07-07T23:00:00.000Z"),
                      );
                    }}
                  />
                )}

                {tab == "season_2" && (
                  <BikeRaces
                    filt_fn={(r) => {
                      return _.inRange(
                        nano(r.start_time),
                        nano("2024-07-07T23:00:00.000Z"),
                        nano("2024-08-31T23:00:00.000Z"),
                      );
                    }}
                  />
                )}

                {tab == "season_3" && (
                  <BikeRaces
                    filt_fn={(r) => {
                      return (
                        _.inRange(
                          nano(r.start_time),
                          nano("2024-11-01T00:00:00.000Z"),
                          nano("2024-12-28T00:00:00.000Z"),
                        ) &&
                        r.rvmode == "bike" &&
                        [11, 15, 19, 23].includes(r.cb)
                      );
                    }}
                  />
                )}

                {tab == "season_4" && (
                  <BikeRaces
                    filt_fn={(r) => {
                      return (
                        _.inRange(
                          nano(r.start_time),
                          nano("2025-01-04T00:00:00.000Z"),
                          nano("2025-03-04T00:00:00.000Z"),
                        ) &&
                        r.rvmode == "bike" &&
                        [10, 14, 18, 22].includes(r.cb)
                      );
                    }}
                  />
                )}
              </>
            )}
          </BikeContext.Provider>
          <div className="h-[5rem]"></div>
        </div>
      </div>
    </div>
  );
};

const RMiniCard = ({ title = "", children }) => {
  return (
    <Card className={"resp-p-2 w-full bg-r2dark/80 border border-acc4"}>
      <p className="resp-text--1">{title}</p>
      <div className="fr-sc resp-text-1 resp-gap-1">{children}</div>
    </Card>
  );
};

const HStats = ({ hstats: s, className }) => {
  const { hid } = useBikeContext();

  return (
    <Card
      className={twMerge(
        "bg-r2lig/20 backdrop-blur-md border border-acc4 w-max mx-auto",
        className,
      )}
    >
      <>
        <div className="grid xs:grid-cols-2 lg:grid-cols-4 resp-gap-2 w-full">
          {[
            ["#Races", <span>{`${getv(s, `races_n`, 0)} Races`}</span>],
            [
              "Win Rate",
              <span>{`${dec(getv(s, `win_p`, 0) * 100, 2)}%`}</span>,
            ],
            [
              "Paid Win Rate",
              <span>{`${dec(getv(s, `paid_win_p`, 0) * 100, 2)}%`}</span>,
            ],
            [
              "Fee.WETH",
              <>
                <FontAwesomeIcon icon={faEthereum} />
                <span>{`${dec(getv(s, `fee`, 0), 4)}`}</span>
              </>,
            ],
            [
              "Prize.WETH",
              <>
                <FontAwesomeIcon icon={faEthereum} />
                <span>{`${dec(getv(s, `prize`, 0), 4)}`}</span>
              </>,
            ],
            [
              "Profit.WETH",
              <>
                <FontAwesomeIcon icon={faEthereum} />
                <span>{`${dec(getv(s, `profit`, 0), 4)}`}</span>
              </>,
            ],
            [
              <div className="fr-sc resp-gap-2">
                <FontAwesomeIcon className="text-blue-400" icon={faStar} />
                <span>Blue Star</span>
              </div>,
              <div className="fr-sc resp-gap-2">
                <span>{`${getv(s, `star_blue.n`, 0)}`}</span>
                <spna>/</spna>
                <span>{`${dec(getv(s, `star_blue.per`, 0), 2)}%`}</span>
              </div>,
            ],
            [
              "Fee.DEZ",
              <>
                <FontAwesomeIcon icon={faEthereum} />
                <span>{`${dec(getv(s, `feedez`, 0), 0)}`}</span>
              </>,
            ],
            [
              "Prize.DEZ",
              <>
                <FontAwesomeIcon icon={faEthereum} />
                <span>{`${dec(getv(s, `prizedez`, 0), 0)}`}</span>
              </>,
            ],
            [
              "Profit.DEZ",
              <>
                <FontAwesomeIcon icon={faEthereum} />
                <span>{`${dec(getv(s, `profitdez`, 0), 0)}`}</span>
              </>,
            ],
            [
              <div className="fr-sc resp-gap-2">
                <FontAwesomeIcon className="text-yellow-400" icon={faStar} />
                <span>Yellow Star</span>
              </div>,
              <div className="fr-sc resp-gap-2">
                <span>{`${getv(s, `star_yellow.n`, 0)}`}</span>
                <spna>/</spna>
                <span>{`${dec(getv(s, `star_yellow.per`, 0), 2)}%`}</span>
              </div>,
            ],
          ].map(([title, children]) => (
            <div className={twMerge(title == "#Races" ? "col-span-2" : "")}>
              <RMiniCard title={title}>{children}</RMiniCard>
            </div>
          ))}
        </div>
      </>
    </Card>
  );
};

const FeeFilters = () => {
  const bcon = useBikeContext();
  const { filt, set_filt, hid } = bcon;
  const inpargs = {
    fkey: `bike-races-${hid}`,
    filters: filt,
    set_filters: set_filt,
  };
  const fee_mode = getv(filt, "fee_mode.vals");

  return (
    <>
      <div className="fr-cc w-max bg-r2dark/40 mx-auto rounded-md  mb-2">
        {[
          ["ALL", "bg-acc0/40"],
          ["Free", "bg-yellow-400/70"],
          ["Paid", "bg-green-400/70"],
        ].map(([txt, cn]) => {
          return (
            <Tag
              onClick={() => set_state_ob(filt, set_filt, "fee_mode.vals", txt)}
              className={twMerge(
                "rounded-md resp-px-4 resp-py-2 m-0",
                fee_mode == txt ? cn : "",
              )}
            >
              {txt}
            </Tag>
          );
        })}
      </div>
      {fee_mode == "Paid" && (
        <>
          <div className="mt-4 fr-cs w-max mx-auto resp-gap-2">
            <div className="w-max mx-auto">
              <InpAutoWrap {...{ ...inpargs, idd: "fee_usd" }} />
            </div>
            <Tag
              onClick={() => {
                set_state_ob(filt, set_filt, "fee_usd.vals", {
                  mi: null,
                  mx: null,
                });
              }}
              className="text-red-400 rounded-[100px] border border-red-400 resp-px-2 text-[10px]"
            >
              clear
            </Tag>
          </div>
        </>
      )}
    </>
  );
};

const FiltSection = ({ racing_cbs }) => {
  const bcon = useBikeContext();
  const { hid, filt, set_filt, clear_filt } = bcon;

  // useEffect(() => { console.log("filt", filt); }, [filt]);

  const inpargs = {
    fkey: `hid-races-${hid}`,
    filters: filt,
    set_filters: set_filt,
    order: "cb,rgate,payout,format,fee_mode".split(","),
    custom_jsx: {
      fee_mode: {
        selection_jsx: <></>,
        options_jsx: (
          <>
            <FeeFilters />
          </>
        ),
      },
    },
  };

  return (
    <div className="my-2">
      <FiltCard_forLegacy {...{ ...inpargs }} />
      {/*
      <Card className={"bg-r2lig/20 backdrop-blur-md border border-acc4 w-[98%] resp-px-4"}>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "cb" }} />
        </div>
        <div className="w-max mx-auto fr-cc wrap max-w-full">
          <InpAutoWrap {...{ ...inpargs, idd: "class" }} />
        </div>
        {
          <>
            <div className="grid sm:grid-cols-2 md:grid-cols-4">
              <div className="col-span-2">
                <p className="text-center font-digi text-slate-500">Gates</p>
                <InpAutoWrap {...{ ...inpargs, idd: "rgate" }} />
              </div>
              <div className="col-span-2">
                <p className="text-center font-digi text-slate-500">Payout</p>
                <InpAutoWrap {...{ ...inpargs, idd: "payout" }} />
              </div>
              <div className="col-span-2">
                <p className="text-center font-digi text-slate-500">Formats</p>
                <InpAutoWrap {...{ ...inpargs, idd: "format" }} />
              </div>
              <div className="col-span-2">
                <p className="text-center font-digi text-slate-500">Race Fee</p>
                <FeeFilters />
              </div>
            </div>
          </>
        }
        <div className="fr-sc">
          <Tag
            onClick={clear_filt}
            className="resp-text--3 text-red-400 border border-red-400"
          >
            {"Clear Filters"}
          </Tag>
          <div className="flex-1"></div>
        </div>
      </Card>
      */}
    </div>
  );
};

const PositionChart = ({ pc, className, title = "Final Positions" }) => {
  const vals = _.chain([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14])
    .map((e, idx) => [e, pc[e] ?? 0])
    .value();
  return (
    <Card className={twMerge("resp-p-2 bg-r2dark/40 w-full", className)}>
      <p className="resp-text--1">{title}</p>
      <BarChart
        {...{
          labels: _.map(vals, 0).map((p) => postxt(p)),
          cdata: _.map(vals, 1),
          label: "Position Count",
          backgroundColor: tailwindcss_colors.cyan[400],
          datalabelsColor: "black",
        }}
      />
    </Card>
  );
};

const SpeedChart = ({
  racing_cbs = [],
  spmap,
  className,
  title = "Sp",
  ranksmap = {},
}) => {
  return (
    <>
      <p>{title}</p>
      {_.isEmpty(racing_cbs) ? (
        <p className="text-center p-2 resp-text--2 text-yellow-400">
          {" "}
          Please enter races for speeds to show up
        </p>
      ) : (
        <div className="grid grid-cols-2 gap-1">
          {racing_cbs.map((cb) => {
            let mxsp = spmap[cb];
            let rank = ranksmap[cb];
            return (
              <div className="resp-p-2 rounded-sm bg-r2lig/20 backdrop-blur-md">
                <div className="fr-cc">
                  <p className="text-center resp-text--1 ">CB{cb}00</p>
                  {!nils(rank) && (
                    <Tag className="bg-dark text-r2lig font-digi -skew-x-12">
                      #{rank}
                    </Tag>
                  )}
                </div>
                <p className="text-center resp-text-1 font-digi">
                  {nils(mxsp) ? (
                    <span className="text-slate-500">--</span>
                  ) : (
                    <span className="text-r2lig italic">
                      {dec(mxsp, 2)} m/s
                    </span>
                  )}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const FinishDistro = ({ hstats, cb }) => {
  const s = hstats;
  return (
    <Card className={"bg-r2lig/20 backdrop-blur-md border border-acc4 w-full"}>
      <p className="resp-text--1 text-left italic font-digi">CB{cb}00</p>
      {/* <div className="flex flex-row justify-around"> */}
      <div className="grid grid-cols-3 resp-gap-2">
        <>
          {[
            ["#Races", <span>{`${getv(s, `races_n`, 0)} Races`}</span>],
            [
              "Win Rate",
              <span>{`${dec(getv(s, `win_p`, 0) * 100, 2)}%`}</span>,
            ],
            [
              "Paid Win Rate",
              <span>{`${dec(getv(s, `paid_win_p`, 0) * 100, 2)}%`}</span>,
            ],
            [
              "Profit.WETH",
              <>
                <TokenIcon token={"WETH"} />
                <span>{`${dec(getv(s, `profit`, 0), 4)}`}</span>
              </>,
            ],
            [
              "Profit.DEZ",
              <>
                <TokenIcon token={"DEZ"} />
                <span>{`${dec(getv(s, `profitdez`, 0), 0)}`}</span>
              </>,
            ],
            [
              <div className="fr-sc resp-gap-2">
                <FontAwesomeIcon className="text-blue-400" icon={faStar} />
                <span>Blue Star</span>
              </div>,
              <div className="fr-sc resp-gap-2">
                <span>{`${getv(s, `star_blue.n`, 0)}`}</span>
                <spna>/</spna>
                <span>{`${dec(getv(s, `star_blue.per`, 0), 2)}%`}</span>
              </div>,
            ],
            [
              <div className="fr-sc resp-gap-2">
                <FontAwesomeIcon className="text-yellow-400" icon={faStar} />
                <span>Yellow Star</span>
              </div>,
              <div className="fr-sc resp-gap-2">
                <span>{`${getv(s, `star_yellow.n`, 0)}`}</span>
                <spna>/</spna>
                <span>{`${dec(getv(s, `star_yellow.per`, 0), 2)}%`}</span>
              </div>,
            ],
          ].map(([title, children]) => (
            <div className={twMerge("fc-ss")}>
              <p className="resp-text--3">{title}</p>
              <p className="text-acc0 resp-text--2 fr-sc w-full">{children}</p>
            </div>
          ))}
        </>
      </div>
      <div class="">
        <PositionChart pc={hstats.pc} className={"xs:p-0 lg:p-0"} title="" />
      </div>
    </Card>
  );
};

const BikeRaces = ({ filt_fn = () => true }) => {
  const {
    hid,
    cb,
    set_cb,
    valfilt,
    filt,
    set_filt,
    subtab,
    set_subtab,
    hstats: pre_hstats,
    tab,
    rvmode,
    include_quest,
    set_include_quest,
  } = useBikeContext();

  const { tok_to_usd_val, usd_to_tok_val } = useAppContext();

  const [page, set_page] = useState(1);
  const [limit, set_limit] = useState(25);
  const [qo_hraces] = useQueries([q_hraces({ hid, page: 1, limit: 1e14 })]);

  const calc_stats = (rs, pre_hstats, include_quest) => {
    let use_rs = include_quest
      ? rs
      : rs.filter((r) => !["quest", "sub_quest"].includes(r.format));
    let races_n = use_rs.length;
    let win_n = _.chain(use_rs)
      .filter((r) => r.pos == 1)
      .size()
      .value();
    let paid_win_n = _.chain(use_rs)
      .filter((r) => r.pos == 1 && parseFloat(r.fee) > 0)
      .size()
      .value();
    let win_p = per_n(win_n, races_n);
    let paid_win_p = per_n(paid_win_n, races_n);

    let fee = _.chain(use_rs)
      .filter((r) => r.paytoken == "WETH")
      .map((r) => parseFloat(r.fee))
      .compact()
      .sum()
      .value();
    let prize = _.chain(use_rs)
      .filter((r) => r.paytoken == "WETH")
      .map((r) => parseFloat(r.prize_eth))
      .compact()
      .sum()
      .value();
    let profit = prize - fee;

    let feedez = _.chain(use_rs)
      .filter((r) => r.paytoken == "DEZ")
      .map((r) => parseFloat(r.fee))
      .compact()
      .sum()
      .value();
    let prizedez = _.chain(use_rs)
      .filter((r) => r.paytoken == "DEZ")
      .map((r) => parseFloat(r.prize_eth))
      .compact()
      .sum()
      .value();
    let profitdez = prizedez - feedez;

    let pc = _.countBy(use_rs, "pos");

    let mxspmap = {};
    let mxsprankmap = {};
    let avsprankmap = {};
    for (let cb of all_cbs) {
      let mitime = _.chain(use_rs)
        .filter((r) => r.cb == cb)
        .map("time")
        .compact()
        .min()
        .value();
      if (nils(mitime)) {
        mxspmap[cb] = null;
      } else {
        let sp = (cb * 100) / mitime;
        mxspmap[cb] = sp;
      }

      let mxsprank = getv(pre_hstats, `data.${cb}.speed_mx_rank`, null);
      let avsprank = getv(pre_hstats, `data.${cb}.speed_avg_rank`, null);
      mxsprankmap[cb] = mxsprank;
      avsprankmap[cb] = avsprank;
    }

    let avgspmap = {};
    for (let cb of all_cbs) {
      let avgtime = _.chain(use_rs)
        .filter((r) => r.cb == cb)
        .map("time")
        .compact()
        .mean()
        .value();
      if (nils(avgtime)) {
        avgspmap[cb] = null;
      } else {
        let sp = (cb * 100) / avgtime;
        avgspmap[cb] = sp;
      }
    }

    let star_blue = {
      n: _.chain(use_rs)
        .map("star")
        .filter((e) => e == 2 || e == 5)
        .size()
        .value(),
    };
    star_blue.per = per_n(star_blue.n, races_n) * 100;

    let star_yellow = {
      n: _.chain(use_rs)
        .map("star")
        .filter((e) => e == 3 || e == 5)
        .size()
        .value(),
    };
    star_yellow.per = per_n(star_yellow.n, races_n) * 100;

    return {
      races_n,
      win_n,
      paid_win_n,
      win_p,
      paid_win_p,

      fee,
      prize,
      profit,

      feedez,
      prizedez,
      profitdez,

      pc,
      mxspmap,
      avgspmap,

      mxsprankmap,
      avsprankmap,

      star_blue,
      star_yellow,
    };
  };

  const [races, races_n, tot_races_n] = useMemo(() => {
    if (!qissuccesss(qo_hraces)) return [[], 0, 0];

    let rs = getv(qo_hraces, "data.result");
    if (!nils(rvmode)) rs = _.filter(rs, (r) => r.rvmode == rvmode);

    let tot_n = rs.length;
    rs = rs
      .map((r) => {
        r.fee_mode = parseFloat(r.fee) == 0 ? "Free" : "Paid";
        r.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
        r.prize_usd = tok_to_usd_val(r.fee, r.paytoken);
        return r;
      })
      .filter((r) => {
        return filt_fn(r);
      });
    return [rs, rs.length, tot_n];
  }, [qo_hraces.dataUpdatedAt, tab, rvmode]);

  const [filtraces, filtraces_n] = useMemo(() => {
    let rs = filt_ar_using_filters({ ar: races, filters: filt });
    return [rs, rs.length];
  }, [jstr(valfilt), jstr(races), rvmode]);

  const showraces = useMemo(() => {
    return filtraces.slice((page - 1) * limit, page * limit);
  }, [jstr(filtraces), page, limit]);

  const hstats = useMemo(() => {
    let st = calc_stats(filtraces, pre_hstats, include_quest);
    // console.log(st);
    return st;
  }, [jstr(filtraces), include_quest]);

  const [spmode, set_spmode] = useState("max");

  const racing_cbs = useMemo(() => {
    let cbs = _.chain(races)
      .map("cb")
      .map((e) => parseInt(e))
      .filter((e) => !nils(e))
      .uniq()
      .sortBy((e) => parseInt(e))
      .value();
    // console.log("races", cbs);
    return cbs;
  }, [jstr(races)]);

  useEffect(() => {
    set_state_ob(filt, set_filt, "cb.options", racing_cbs);
  }, [jstr(racing_cbs)]);

  return (
    <>
      <FiltSection {...{ racing_cbs }} />

      <div className="fr-cc">
        {["summary", "races_list", "finish_distro"].map((_subtab) => (
          <Tag
            onClick={() => set_subtab(_subtab)}
            className={twMerge(
              "trasition-all duration-200 ease-in-out",
              subtab == _subtab
                ? "bg-acc0/40 shadow-md shadow-acc0 text-white -skew-x-12"
                : "bg-transparent text-acc0",
            )}
          >
            {_.chain(_subtab).split("_").map(_.capitalize).join(" ").value()}
          </Tag>
        ))}
      </div>

      <div className="fr-cc">
        <div class="flex-1"></div>

        <Tag
          onClick={() => {
            set_include_quest(!include_quest);
          }}
          className="fr-cc resp-gap-2"
        >
          <FontAwesomeIcon
            className="resp-text-1 mx-1"
            icon={include_quest ? faToggleOn : faToggleOff}
          />
          <span className="resp-text--1 font-digi">
            Include Quest Races for Stats
          </span>
        </Tag>
      </div>
      <div class="h-[2rem]"></div>
      {subtab == "summary" && (
        <>
          <HStats hstats={hstats} />
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 resp-gap-2">
            <div class="a">
              <div class="rounded-md bg-r2lig/20 backdrop-blur-md border border-acc4">
                <PositionChart pc={hstats.pc} />
              </div>
            </div>

            <Card className={twMerge("card-dark-bg border border-acc4 w-full")}>
              <div className="fr-sc">
                {[
                  ["max", "MxSpeeds"],
                  ["avg", "AvgSpeeds"],
                ].map(([_sp, _title]) => {
                  return (
                    <Tag
                      onClick={() => set_spmode(_sp)}
                      className={twMerge(
                        _sp == spmode ? "bg-r2lig/40 text-white" : "text-white",
                      )}
                    >
                      <span className="resp-text--1 font-digi">{_title}</span>
                    </Tag>
                  );
                })}
              </div>
              {spmode == "max" ? (
                <SpeedChart
                  title=""
                  spmap={hstats.mxspmap}
                  racing_cbs={racing_cbs}
                  ranksmap={
                    filtraces_n == races_n && include_quest === false
                      ? hstats.mxsprankmap
                      : {}
                  }
                />
              ) : spmode == "avg" ? (
                <SpeedChart
                  title=""
                  racing_cbs={racing_cbs}
                  spmap={hstats.avgspmap}
                  ranksmap={
                    filtraces_n == races_n && include_quest === false
                      ? hstats.avsprankmap
                      : {}
                  }
                />
              ) : (
                <></>
              )}
            </Card>
          </div>
        </>
      )}

      {subtab == "races_list" && (
        <>
          <Card
            className={
              "card-dark-bg rounded-md border border-acc4 w-full overflow-auto"
            }
          >
            <div className="fr-sc my-2 resp-text--1">
              <span>{`${filtraces_n} Filtered / ${races_n} Races`}</span>
              <div className="flex-1"></div>
              <Tag onClick={() => set_page(Math.max(page - 1, 1))}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Tag>
              <span>Page {page}</span>
              <Tag onClick={() => set_page(page + 1)}>
                <FontAwesomeIcon icon={faChevronRight} />
              </Tag>
            </div>

            {qo_hraces.isLoading ? (
              <Loader01c />
            ) : qiserr(qo_hraces) ? (
              <p className="text-red-400 text-center">{qiserr(qo_hraces)}</p>
            ) : qissuccesss(qo_hraces) && _.isEmpty(races) ? (
              <p className="text-yellow-400 text-center">No Races Yet</p>
            ) : qissuccesss(qo_hraces) && !_.isEmpty(races) ? (
              <>
                {_.isEmpty(showraces) ? (
                  <>
                    <p className="text-yellow-400">
                      Current Filter or Page has no races
                    </p>
                  </>
                ) : (
                  <>
                    <table className={twMerge(tablecn.table, "resp-text--2")}>
                      <thead className="thintdrow text-r2lig">
                        <th colSpan={2}>
                          <span className="remp-my-2">Race</span>
                        </th>
                        <th>Race Name</th>
                        <th colSpan={2}>Class - CB</th>
                        <th>Format</th>
                        <th>Payout</th>
                        <th>Gates</th>
                        <th>Star</th>
                        <th>Pos</th>
                        <th>Time</th>
                        <th>Fee</th>
                        <th>Prize</th>
                      </thead>
                      <tbody>
                        {showraces.map((r) => {
                          return (
                            <tr className="thintdrow resp-text--2 ">
                              <td colSpan={2}>
                                <Link
                                  to={`/race/${r.rid}`}
                                  className="block resp-gap-1 xs:min-w-[5rem] lg:min-w-[10rem]"
                                >
                                  <div className="fr-sc my-1 resp-gap-2">
                                    <FontAwesomeIcon icon={faLink} />
                                    <span>{r.rid}</span>
                                  </div>
                                  <span className="resp-text--4 text-slate-300">
                                    @{iso_format(r.start_time)}
                                  </span>
                                </Link>
                              </td>

                              <td>
                                <div className="fc-ss">
                                  <span className="font-digi resp-text--3 italic">
                                    {r.race_name}
                                  </span>
                                  {!_.isEmpty(r.eventtags) && (
                                    <div className="fr-sc resp-gap-1">
                                      {r.eventtags.map((e) => (
                                        <span className="resp-text--4 rounded-md resp-p-1 bg-slate-600 text-white">
                                          {_.capitalize(e)}
                                        </span>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </td>

                              <td colSpan={2}>
                                <div className="fr-sc">
                                  <Tag
                                    className={twMerge(
                                      "xs:resp-text--2 lg:resp-text--2",
                                      `text-c${r.class}`,
                                    )}
                                  >
                                    {class_text(r.class, "t")}
                                  </Tag>
                                  <Tag
                                    className={
                                      " xs:resp-text--2 lg:resp-text--2"
                                    }
                                  >
                                    <span className="resp-text--2 font-digi text-white">
                                      {cb_txt(r.cb, r.rvmode)}
                                    </span>
                                  </Tag>
                                  <div class="xs:w-[1.5rem] lg:w-[3rem]">
                                    <RVImg
                                      rvmode={r.rvmode}
                                      hex_code={"FFFFFF"}
                                    />
                                  </div>
                                </div>
                              </td>

                              <td>
                                <span className="text-acc0">
                                  {_.upperCase(r.format)}
                                </span>
                              </td>
                              <td>
                                <span className="text-acc0">
                                  {_.upperCase(r.payout)}
                                </span>
                              </td>

                              <td>
                                <span>G-{r.rgate}</span>
                              </td>

                              <td>
                                <span>
                                  <BY_Star star={r.star} />
                                </span>
                              </td>

                              <td>
                                <PosTag
                                  pos={r.pos}
                                  className={"resp-text--2"}
                                />
                              </td>
                              <td>
                                <span className="resp-text--1 text-green-400 font-digi">
                                  {dec(r.time, 1)}s
                                </span>
                              </td>
                              <td>
                                <div className="fc-cc resp-gap-2">
                                  {parseFloat(r.fee) == 0 ? (
                                    <>
                                      <span className="text-yellow-400">
                                        FREE
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <div className="fr-sc text-yellow-400">
                                        <TokenIcon
                                          token={r.paytoken}
                                          size="xs"
                                        />
                                        <span className="resp-text--2 font-mon">
                                          {dec(r.fee, tokdecn(r.paytoken))}
                                        </span>
                                      </div>
                                      <div className="fr-sc text-yellow-400">
                                        <FontAwesomeIcon icon={faUsd} />
                                        <span className="resp-text--2 font-mon">
                                          {dec(
                                            tok_to_usd_val(r.fee, r.paytoken),
                                            2,
                                          )}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="fc-cc resp-gap-2">
                                  {parseFloat(r.prize_eth) == 0 ? (
                                    <>
                                      <span className="text-slate-300">--</span>
                                    </>
                                  ) : (
                                    <>
                                      <div className="fr-sc text-green-400">
                                        <TokenIcon
                                          token={r.paytoken}
                                          size="xs"
                                        />
                                        <span className="resp-text--2 font-mon">
                                          {dec(
                                            r.prize_eth,
                                            tokdecn(r.paytoken),
                                          )}
                                        </span>
                                      </div>
                                      <div className="fr-sc text-green-400">
                                        <FontAwesomeIcon icon={faUsd} />
                                        <span className="resp-text--2 font-mon">
                                          {dec(
                                            tok_to_usd_val(
                                              r.prize_eth,
                                              r.paytoken,
                                            ),
                                            2,
                                          )}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Card>
        </>
      )}

      {subtab == "finish_distro" && (
        <div className="">
          {_.isEmpty(racing_cbs) ? (
            <p class="text-center resp-text--2 my-2 text-yellow-400">
              No Races in any Distance
            </p>
          ) : (
            <div className="grid xs:grid-cols-1 lg:grid-cols-2 resp-gap-2">
              {racing_cbs.map((cb) => {
                let cbrs = _.filter(filtraces, (r) => r.cb == cb);
                let hstats = calc_stats(cbrs);
                return <FinishDistro key={cb} {...{ hstats, cb }} />;
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BikePage;
