import React from "react";
import { useState, useEffect, useContext, createContext } from "react";
import _ from "lodash";
import { useAuthContext } from "./AuthWrapper";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { iso, jparse, jstr, nils } from "../utils/utils";
import { Tag } from "../components/utilityComps";
import moment from "moment";
import { q_vault_update_fingerprint } from "../queries/queries";

const FingerPrintContext = createContext({});
export const useFingerPrintContext = () => useContext(FingerPrintContext);

export const FingerPrintWrapper = ({ children, ...props }) => {
  const aucon = useAuthContext();
  const { aumode, auth, vault } = aucon;

  const qofing = useVisitorData({ extendedResult: true }, { immediate: false });

  const update_fingerprint = async (doc, ext = {}) => {
    try {
      // console.log("update_fingerprint:init", doc);
      doc = { ...doc, ...ext };
      let resp = await q_vault_update_fingerprint({
        doc,
        vault: doc.vault,
      }).queryFn();
      // console.log("update_fingerprint:resp", resp);
    } catch (err) {
      // console.log("update_fingerprint:err", err);
    }
  };

  useEffect(() => {
    let redid = `fingerprint`;
    const fn = async () => {
      // console.log("fingerprint:init", { auth, aumode, vault });
      let now = iso();
      let cac = jparse(localStorage.getItem(redid), null);
      // console.log("fingerprint:cac", cac);

      let doupdate = false;
      if (!_.isEmpty(cac) && !nils(cac?.date)) {
        // console.log("case1");
        let date = cac.date;
        let diff = moment(now).diff(moment(date), "hours");
        if (diff > 5) {
          doupdate = true;
        }
      } else {
        doupdate = true;
        // console.log("case2");
      }
      // console.log("fingerprint:doupdate", doupdate);
      if (doupdate) {
        now = iso();
        let resp = await qofing.getData();
        console.log("fingerprint:update", resp.visitorId);
        resp.date = now;
        resp.vault = vault;
        localStorage.setItem(redid, jstr(resp));
      }
    };
    fn();
  }, []);

  useEffect(() => {
    if (auth !== true) return;
    if (nils(aumode)) return;
    let resp = jparse(localStorage.getItem(`fingerprint`));
    update_fingerprint(resp, { vault, aumode, auth });
  }, [jstr({ auth, aumode, vault })]);

  const fcon = {};
  return (
    <FingerPrintContext.Provider value={fcon}>
      {children}
    </FingerPrintContext.Provider>
  );
};
