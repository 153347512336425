import { useMemo, useState, useEffect } from "react";
import { getv, iso, jparse, jstr, nano, nils } from "../utils/utils";
import moment from "moment";
import _ from "lodash";
import { fpost } from "../queries/fetch";
import {
  btbk,
  q_bikeinfo_cacidfn,
  q_bikesinfos,
  qissuccesss,
  useStepQuery,
} from "../queries/queries";

export function useCoresFullData(hids) {
  const k = "q_bikeinfo";
  const api = `${btbk}/fbike/i/info`;
  const cac_ex = 10 * 60;
  const cac_id_fn = q_bikeinfo_cacidfn;
  const chunk_size = 5;

  const get_cac_data = (k, inf) => {
    let cacid = nils(cac_id_fn) ? `cac:${k}:${jstr(inf)}` : cac_id_fn(k, inf);
    let now = iso();
    let exis = localStorage.getItem(cacid);
    if (!nils(exis)) exis = jparse(exis);
    let expired = nils(exis)
      ? true
      : nils(exis?.expires)
        ? true
        : nano(exis?.expires) < nano(now);

    if (!nils(exis)) {
      return { data: exis.resp, expired };
    } else return null;
  };

  const save_cac_data = (k, inf, data) => {
    let cacid = nils(cac_id_fn) ? `cac:${k}:${jstr(inf)}` : cac_id_fn(k, inf);
    let expires = moment().add(cac_ex, "seconds").toISOString();
    let o = { cacid, resp: data, expires };
    localStorage.setItem(cacid, jstr(o));
  };

  const [exismap, nonexis_hids] = useMemo(() => {
    let datamap = {};
    let nonexis_hids = [];
    for (let hid of hids) {
      let cac = get_cac_data(k, { hid });
      // console.log("cac", hid, cac);
      if (!_.isEmpty(cac?.data)) datamap[hid] = cac.data;
      else nonexis_hids.push(hid);
      if (cac?.expired === true) {
        // console.log("cac:expired", hid);
        nonexis_hids.push(hid);
      }
    }
    // console.log("exismap_fn", datamap, nonexis_hids);
    return [datamap, nonexis_hids];
  }, [jstr(hids)]);

  const q_fet_bdocs = useStepQuery({
    q_: q_bikesinfos,
    par_ar: _.chain(nonexis_hids)
      .chunk(chunk_size)
      .map((hids) => [{ hids }])
      .value(),
    lim: 2,
  });
  // useEffect(() => {
  //   console.log("q_fet_bdocs", q_fet_bdocs);
  // }, [jstr(q_fet_bdocs.qs)]);

  const [full_qs, full_bikesob, all_loaded] = useMemo(() => {
    let o = { ...exismap };
    for (let q of q_fet_bdocs.qs) {
      if (!qissuccesss(q)) continue;
      let resp = getv(q, "data.result", []);
      for (let d of resp) {
        o[d.hid] = d;
        save_cac_data(k, { hid: d.hid }, d);
      }
    }
    let all_loaded =
      _.chain(q_fet_bdocs.qs)
        .filter((q) => ["loading", "idle"].includes(q.status))
        .value().length == 0;
    // console.log("useCoresFullData:fulldata", { all_loaded }, o);
    let now = nano();
    let ar = hids.map((hid) => {
      let exis = !_.isEmpty(o[hid]);
      if (exis) {
        return {
          status: "success",
          data: { status: "success", result: o[hid] },
          dataUpdatedAt: now,
        };
      } else return { status: "loading", data: {} };
    });
    return [ar, o, all_loaded];
  }, [jstr(q_fet_bdocs.qs), jstr(hids)]);

  return { qs: full_qs, bikesob: full_bikesob, all_loaded };
}
